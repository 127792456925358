import { AnalyticEventName, AnalyticsApi, AnalyticsScreenName, AnalyticsSummary, EndExperienceAnalyticsEvent, EndExperienceWithRatingAnalyticsEvent, getDefaultAnalyticsSummary, StartExperienceAnalyticsEvent } from "../../API/AnalyticsApi";
import mixpanel from 'mixpanel-browser';
import { EnvId } from "../../Data/constants";
import { getConfiguration } from "../../Util/ConfigHelper";
import { UserActivity } from "../../Data/Models/app";
import { getEmailDomainWithSubdomain } from "../../Util/AuthHelper";
import { LOGGER, LogLevel } from "../../Util/Logger";

const MIXPANEL_TOKEN_DEV = "fcb785481aa0813959be20ccdfc9db2b";
const MIXPANEL_TOKEN_PROD = "7de3255dc6cacc35758563a46ab2f9fa";

export class MixpanelAnalyticsImpl implements AnalyticsApi {

    MIX_PANEL_TOKEN: string;

    constructor(envId: EnvId) {
        if (envId === EnvId.DEV) {
            this.MIX_PANEL_TOKEN = MIXPANEL_TOKEN_DEV;
        } else if (envId === EnvId.PROD) {
            this.MIX_PANEL_TOKEN = MIXPANEL_TOKEN_PROD;
        } else {
            throw new Error("Token not defined.")
        }

        // ZEN-125 - TODO: change to use Proxy server to avoid ad blockers
        mixpanel.init(this.MIX_PANEL_TOKEN, {
            debug: envId === EnvId.DEV
        });
    }

    async identify(
        userId: string
    ): Promise<void> {
        mixpanel.people.set("$distinct_id", userId);
        mixpanel.identify(userId);
    }

    async updateUserProps(
        props: { [key: string]: any; }
    ): Promise<void> {
        mixpanel.people.set(props);
    }

    async trackEvent(eventName: AnalyticEventName, props?: {[key: string]: any;}): Promise<void> {
        mixpanel.track(eventName, props);
    }
    
    async trackScreen(screenName: AnalyticsScreenName): Promise<void> {
        mixpanel.track(screenName);
    }

    async trackStartExperience(event: StartExperienceAnalyticsEvent): Promise<void> {
        mixpanel.track(event.name, event.toPlainObj());
    }

    async trackEndExperience(event: EndExperienceAnalyticsEvent): Promise<void> {
        mixpanel.track(event.name, event.toPlainObj());
    }

    async trackEndExperienceWithRating(event: EndExperienceWithRatingAnalyticsEvent): Promise<void> {
        mixpanel.track(event.name, event.toPlainObj());
    }

    async trackUserActivity(userId: string, activity: UserActivity): Promise<any> {}

    async onUserAuthed(userId: string, email: string): Promise<void> {
        const emailDomains = getEmailDomainWithSubdomain(email);
        LOGGER.log(LogLevel.DEBUG, `MixpanelAnalyticsImpl.onUserAuthed() userId=${userId}, emailDomains=${JSON.stringify(emailDomains)}`);
        const updates: any = {
            emailDomain: emailDomains.domain,
            emailSubDomain: emailDomains.subdomain,
        };
        await this.identify(userId);
        await this.updateUserProps(updates);
    }

    getSessionSummaryJson(): AnalyticsSummary {
        return getDefaultAnalyticsSummary(getConfiguration());
    }

}