import { getPopUpTextFromTouchpadId, getTouchpadTitle } from './Data/constants';
import cross from "../assets/icons/close.svg"
import info from "../assets/icons/info.svg"
import { isSubmitKey, preventDefault, stopPropagation } from './UI/KeyPressHelper';
import { isMobile } from './Util/DeviceUtil';
import { useEffect, useRef, useState } from 'react';
import { handleTabKey } from './Util/ModalAccessibilityHelper';
import { KeyCode } from './UI/KeyEnum';

const TouchpadPopup = (props) => {

    const modalRef = useRef();
    
    // set focus to the close button when the dialog shows
    const [btnStartFocus, setStartFocusButton] = useState(null);
    useEffect(() => {
        if (btnStartFocus != null) {
            btnStartFocus.focus();
        }
    }, [btnStartFocus]);

    useEffect(() => {
        function keyListener(e) {
            const listener = e.keyCode === KeyCode.Tab 
                ? handleTabKey(e, modalRef, 'img.popup-close-icon, div.popup-instruction')
                : e.keyCode === KeyCode.Esc 
                ? closePopup(e)
                : null;
            return listener && listener(e, modalRef, 'img.popup-close-icon, div.popup-instruction');
          }
        document.addEventListener("keydown", keyListener);
        return () => document.removeEventListener("keydown", keyListener);
      },[]);

    const closePopup = (e) =>{
        preventDefault(e);
        props.setShowTouchpadPopup(false);
        props.setIsInfoIconClicked(false);
    }

    // handle return key presses on the close popup icon
    const _handleKeyDownClose = (e) => {
        stopPropagation(e);
        if (isSubmitKey(e)) {
            closePopup(e);
        }
    }

    return (
        <div className="popup-container"
            ref={modalRef}>
            <div className='popup-header'>
            <img 
                src={info} 
                className="popup-info-icon" 
                alt="information icon" />
            <img 
                id="btn-touchpad-info-close"
                src={cross} 
                className="popup-close-icon"
                onClick={(e) => closePopup(e)} 
                alt="Close touchpad info popup"
                aria-label="Close touchpad info popup"
                tabIndex="0" 
                onKeyDown={(e) => _handleKeyDownClose(e)}
            />
            </div>
            <h1 className="popup-heading"
                tabIndex={0}
                ref={(button) => { setStartFocusButton(button); }}
                alt={getTouchpadTitle(props.touchpadId)}
            >
                {getTouchpadTitle(props.touchpadId)}
            </h1>
            <div className='popup-instruction'
                tabIndex={0}
                alt={getPopUpTextFromTouchpadId(props.touchpadId, isMobile())}
            >
                {getPopUpTextFromTouchpadId(props.touchpadId, isMobile())}
            </div>
        </div>
    );
};

export default TouchpadPopup;