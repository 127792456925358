import { AnalyticEventName, AnalyticsApi, AnalyticsScreenName, AnalyticsSummary, EndExperienceAnalyticsEvent, EndExperienceWithRatingAnalyticsEvent, StartExperienceAnalyticsEvent, getDefaultAnalyticsSummary } from "../../API/AnalyticsApi";
import { UserActivity } from "../../Data/Models/app";
import { getConfiguration } from "../../Util/ConfigHelper";


export class ScormAnalyticsImpl implements AnalyticsApi {

    async identify(userId: string): Promise<void> {
    }

    async updateUserProps(props: { [key: string]: any; }): Promise<void> {
    }

    async trackEvent(eventName: AnalyticEventName, props?: {[key: string]: any}): Promise<void> {
    }

    async trackScreen(screenName: AnalyticsScreenName): Promise<void> {
    }
   
    async trackStartExperience(event: StartExperienceAnalyticsEvent): Promise<void> {
    }

    async trackEndExperience(event: EndExperienceAnalyticsEvent): Promise<void> {

    }

    async trackEndExperienceWithRating(event: EndExperienceWithRatingAnalyticsEvent): Promise<void> {
    }

    async trackUserActivity(userId: string, activity: UserActivity): Promise<any> {}

    async onUserAuthed(userId: string, email: string): Promise<void> {}

    getSessionSummaryJson(): AnalyticsSummary {
        return getDefaultAnalyticsSummary(getConfiguration());
    }

}