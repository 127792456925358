import React, { useEffect, useRef, useState } from "react";
import "../styles/experience.css";
import topShadow from "../assets/images/shadowTop@3x.png"
import bottomShadow from "../assets/images/shadowBottom@3x.png"
import { ZensoryModule } from "../ZensoryModule";
import { withScorm } from 'react-scorm-provider';
import { AudioDurationSecs, SenseId } from "../components/Data/constants";
import { AnalyticsScreenName, EndExperienceAnalyticsEvent, StartExperienceAnalyticsEvent } from "../components/API/AnalyticsApi";
import { RouteId } from "../Routes";
import BreathExperience from "./BreathExperience";
import TouchpadExperience from "./TouchpadExperience";
import SoundscapeExperience from "./SoundscapeExperience";
import { LOGGER, LogLevel } from "../components/Util/Logger";
import CloseExperiencePopup from "../components/CloseExperiencePopup";
import { enterFullscreen, exitFullscreen, getScreenOrientation, ScreenWakeLock } from "../components/Util/ScreenUtil";
import { preventDefault } from "../components/UI/KeyPressHelper";
import { DEFAULT_GLOBAL_VOLUME_PERCENT, getAudioObjLevel } from "../components/Util/AudioHelper";
import { AudioType } from "../components/API/AudioPlayerAPI";
import { GlobalAppState } from "../components/AppState";

const ExperiencePage = (props) => {

  var backgroundImgsHolder;
  if (props.zense === SenseId.Sound) {
    backgroundImgsHolder = ZensoryModule.getImageLoader().getNaturescapeBackgroundImage(props.experience, getScreenOrientation());
  } else if (props.zense === SenseId.Breath) {
    backgroundImgsHolder = ZensoryModule.getImageLoader().getBreathExperienceImage(props.mood, props.subZense, getScreenOrientation());
  }

  const isUnlimited = props.timeLimit === AudioDurationSecs.UNLIMITED_LOOP;
  const [isPlaying, setIsPlaying] = useState(true); // set to default TRUE so it auto plays
  const [hasLoaded, setHasLoaded] = useState(false);
  const [hasLoadedVisualBreath, setHasLoadedVisualBreath] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const [isBackNavigation, setIsBackNavigation] = useState(false);
  const [closeExperiencePopup, setCloseExperiencePopup] = useState(false);
  const [showVolumeSetting, setShowVolumeSetting] = useState(false);
  const [volume, setVolume] = useState(DEFAULT_GLOBAL_VOLUME_PERCENT);
  const [binauralVolume, setBinauralVolume] = useState(getAudioObjLevel(props.zense, props.experience, AudioType.Binaural));
  const [naturescapeVolume, setNaturescapeVolume] = useState(getAudioObjLevel(props.zense, props.experience, AudioType.Soundscape));
  const [musicVolume, setMusicVolume] = useState(getAudioObjLevel(props.zense, props.experience, AudioType.Music));
  const [audioBreathVolume, setAudioBreathVolume] = useState(getAudioObjLevel(props.zense, props.experience, AudioType.AudioBreath));
  const [visualBreathTriggerVolume, setVisualBreathTriggerVolume] = useState(getAudioObjLevel(props.zense, props.experience, AudioType.VisualBreathTrigger));
  // height of the address bar on android and iOS
  const [barHeight, setBarHeight] = useState(0);

  // store if we started in full screen so we know whether to auto minimise after the experience or not
  const [isStartedFullScreen] = useState(props.isFullScreen === true);
  const [didFullScreenFromZensory, setDidFullScreenFromZensory] = useState(false);

  const [WAKE_LOCK] = useState(new ScreenWakeLock());

  // check the Experience props
  ZensoryModule.getNavigationHandler().checkExperiencePage(window, RouteId.Experience, props);

  useEffect(() => {
    ZensoryModule.getAnalytics().trackScreen(AnalyticsScreenName.ScreenExperience);
  }, []);

  useEffect(() => {
    // attempt to keep the screen awake
    WAKE_LOCK.lockHandler();
  }, [WAKE_LOCK]);

  const backNavRef = useRef();
  backNavRef.current = isBackNavigation;

  const onBackButtonEvent = (e) => {
    preventDefault(e);
    setIsBackNavigation(true);
    setCloseExperiencePopup(true);
    if (backNavRef.current === true) {
      window.history.pushState(null, null, window.location.pathname);
    }
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);
      setIsBackNavigation(false);
    };
  }, []);

  useEffect(() => {
      let elementHeight;
      // Get the height of the viewport
      const actualHeight = window.innerHeight;
      if(document.querySelector('#control-height')){
          // Get the height of the visible portion of the document
          elementHeight = document.querySelector('#control-height').clientHeight;
          // Calculate the height of the address bar by subtracting the visible document height from the viewport height
          setBarHeight(elementHeight - actualHeight);
      }
  }, []);

  const trackEndExperience = async () => {
    const event = new EndExperienceAnalyticsEvent(
      props.mood,
      props.zense,
      props.timeLimit,
      isUnlimited,
      props.experienceDuration,
      props.beforeRating,
      GlobalAppState.getExperienceAnalyticsHolder(),
      props.subZense,
      props.experience
    );
    LOGGER.log(LogLevel.DEBUG, `trackEndExperience, event=${JSON.stringify(event.toPlainObj())}`);
    await ZensoryModule.getAnalytics().trackEndExperience(event);
  };

  useEffect(() => {
    if (props.expired) {
      if (props.zense === SenseId.Touch) {
        localStorage.removeItem("visited_touchpads");
      }

      // exit full screen if we did not start in full screen, are now in full screen 
      // and the user used our button to go into full screen to ensure the user is not
      // left in full screen without a toggle outside of the Experience UI
      if (!isStartedFullScreen && props.isFullScreen && didFullScreenFromZensory) {
        LOGGER.log(LogLevel.DEBUG, `Exiting Full Screen at end of Experience`);
        toggleFullScreen();
      }

      // release the screen wake lock
      WAKE_LOCK.releaseHandler();

      trackEndExperience();

      ZensoryModule.getNavigationHandler().navigate(window, RouteId.Review, props.onClose, props.setExperience);
    }
  }, [props.expired]);

  const shadowOverlayClicked = (e) => {
    preventDefault(e);
    if (closeExperiencePopup) {
      if (props.isBackNavigation){ 
        window.history.pushState(null, null, window.location.pathname);
      }
      setCloseExperiencePopup(false);
    }
  }

  async function enterFullscreenLocal() {
    LOGGER.log(LogLevel.DEBUG, `enterFullscreenLocal() called`);
    setDidFullScreenFromZensory(true);
    await enterFullscreen();
  }
  
  async function exitFullscreenLocal() {
    LOGGER.log(LogLevel.DEBUG, `exitFullscreenLocal() called`);
    await exitFullscreen();
  }

  const toggleFullScreen = (e) => {
    preventDefault(e);
    const isFullScreen = !props.isFullScreen;
    props.setIsFullScreen(isFullScreen);
    if (!isFullScreen) {
      exitFullscreenLocal();
    } else {
      enterFullscreenLocal();
    }
  }

  if(window.location.pathname==="/experience")
    window.onbeforeunload = confirmExit;
  
    function confirmExit() {
      return "Are you sure you want to end the Experience?";
  }

  /**
   * Track the start experience analytics event
   * 
   * @param {number} loadTimeMillis The time in milliseconds when the Experienced started to load
   */
  const trackStartExperience = async () => {
    const event = new StartExperienceAnalyticsEvent(
      props.mood,
      props.zense,
      props.timeLimit,
      isUnlimited,
      props.beforeRating,
      GlobalAppState.getExperienceAnalyticsHolder(),
      props.subZense,
      props.experience
    );
    LOGGER.log(LogLevel.DEBUG, `trackStartExperience event=${JSON.stringify(event.toPlainObj())}`);
    ZensoryModule.getAnalytics().trackStartExperience(event);
  }
  
  return (
    <div className="experience-container"
      style={
        {
          backgroundImage: props.zense === SenseId.Sound
            ? `url(${backgroundImgsHolder.highRes}),url(${backgroundImgsHolder.lowRes})`
            : null
        }
      }
    >
      <div id="control-height"></div>
      {!isPlaying || showVolumeSetting || closeExperiencePopup
        ? <div
          className="shadow-overlay"
          style={{ zIndex: closeExperiencePopup ? 310 : 300 }}
          onClick={(e) => shadowOverlayClicked(e)} >
        </div>
        : null}
      <img
        className="shadow-top"
        src={topShadow}
        alt=""
      />
      <img
        className="shadow-bottom"
        src={bottomShadow}
        alt=""
      />
      {
        closeExperiencePopup
          ? <CloseExperiencePopup
            setCloseExperiencePopup={setCloseExperiencePopup}
            setIsBackNavigation={setIsBackNavigation}
            setExpired={props.setExpired}
            isBackNavigation={isBackNavigation}
          />
          : null
      }
      {props.zense === SenseId.Sound
        ? <SoundscapeExperience
          mood={props.mood}
          zense={props.zense}
          subZense={props.subZense}
          experience={props.experience}
          timeLimit={props.timeLimit}
          expired={props.expired}
          isBackNavigation={isBackNavigation}
          isPlaying={isPlaying}
          volume={volume}
          binauralVolume={binauralVolume}
          musicVolume={musicVolume}
          naturescapeVolume={naturescapeVolume}
          audioBreathVolume={audioBreathVolume}
          experienceDuration={props.experienceDuration}
          isFullScreen={props.isFullScreen}
          toggleFullScreen={toggleFullScreen}
          showVolumeSetting={showVolumeSetting}
          closeExperiencePopup={closeExperiencePopup}
          barHeight={barHeight}
          setShowVolumeSetting={setShowVolumeSetting}
          setIsPlaying={setIsPlaying}
          setHasStarted={setHasStarted}
          hasStarted={hasStarted}
          setExpired={props.setExpired}
          setExperienceDuration={props.setExperienceDuration}
          setVolume={setVolume}
          setBinauralVolume={setBinauralVolume}
          setAudioBreathVolume={setAudioBreathVolume}
          setMusicVolume={setMusicVolume}
          setNaturescapeVolume={setNaturescapeVolume}
          setCloseExperiencePopup={setCloseExperiencePopup}
          hasLoaded={hasLoaded}
          setHasLoaded={setHasLoaded}
          trackStartExperience={trackStartExperience}
        />
        : props.zense === SenseId.Breath
          ? <BreathExperience
            mood={props.mood}
            zense={props.zense}
            subZense={props.subZense}
            experience={props.experience}
            timeLimit={props.timeLimit}
            expired={props.expired}
            isPlaying={isPlaying}
            volume={volume}
            binauralVolume={binauralVolume}
            musicVolume={musicVolume}
            naturescapeVolume={naturescapeVolume}
            audioBreathVolume={audioBreathVolume}
            visualBreathTriggerVolume={visualBreathTriggerVolume}
            setVisualBreathTriggerVolume={setVisualBreathTriggerVolume}
            isFullScreen={props.isFullScreen}
            toggleFullScreen={toggleFullScreen}
            showVolumeSetting={showVolumeSetting}
            closeExperiencePopup={closeExperiencePopup}
            barHeight={barHeight}
            setShowVolumeSetting={setShowVolumeSetting}
            setIsPlaying={setIsPlaying}
            setHasStarted={setHasStarted}
            hasStarted={hasStarted}
            setExpired={props.setExpired}
            experienceDuration={props.experienceDuration}
            setExperienceDuration={props.setExperienceDuration}
            setVolume={setVolume}
            setBinauralVolume={setBinauralVolume}
            setMusicVolume={setMusicVolume}
            setNaturescapeVolume={setNaturescapeVolume}
            setAudioBreathVolume={setAudioBreathVolume}
            restart={props.restart}
            setCloseExperiencePopup={setCloseExperiencePopup}
            hasLoaded={hasLoaded}
            setHasLoaded={setHasLoaded}
            hasLoadedVisualBreath={hasLoadedVisualBreath}
            setHasLoadedVisualBreath={setHasLoadedVisualBreath}
            trackStartExperience={trackStartExperience}
          />
          : <TouchpadExperience
            mood={props.mood}
            zense={props.zense}
            subZense={props.subZense}
            experience={props.experience}
            timeLimit={props.timeLimit}
            expired={props.expired}
            isPlaying={isPlaying}
            volume={volume}
            binauralVolume={binauralVolume}
            musicVolume={musicVolume}
            naturescapeVolume={naturescapeVolume}
            audioBreathVolume={audioBreathVolume}
            experienceDuration={props.experienceDuration}
            isFullScreen={props.isFullScreen}
            toggleFullScreen={toggleFullScreen}
            showVolumeSetting={showVolumeSetting}
            closeExperiencePopup={closeExperiencePopup}
            barHeight={barHeight}
            setShowVolumeSetting={setShowVolumeSetting}
            setVolume={setVolume}
            setBinauralVolume={setBinauralVolume}
            setAudioBreathVolume={setAudioBreathVolume}
            setMusicVolume={setMusicVolume}
            setNaturescapeVolume={setNaturescapeVolume}
            setIsPlaying={setIsPlaying}
            setHasStarted={setHasStarted}
            hasStarted={hasStarted}
            setExpired={props.setExpired}
            setExperienceDuration={props.setExperienceDuration}
            setCloseExperiencePopup={setCloseExperiencePopup}
            hasLoaded={hasLoaded}
            setHasLoaded={setHasLoaded}
            trackStartExperience={trackStartExperience}
          />
      }
    </div>
  );
};

const Experience = withScorm()(ExperiencePage);

export default Experience;