import "../styles/boxBreathing2.css"
import { ZensoryModule } from "../ZensoryModule";
import { getScreenOrientation } from "./Util/ScreenUtil";

const AudioGuidedBreathwork = (props) => {

    const imgs = ZensoryModule.getImageLoader().getBreathExperienceImage(props.mood, props.subZense, getScreenOrientation());

    return (
        <div
            style={{
                background: `url(${imgs.highRes}), url(${imgs.lowRes}) no-repeat center center /cover`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "100vh",
                width: "100vw",
                fontFamily: "Helvetica, sans-serif",
                color: "#fff",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
        </div>
    )
};

export default AudioGuidedBreathwork;