
export enum RouteId {
    Root = "",
    Login = "login",
    Subscribe = "subscribe",
    SignUp = "signup",
    ForgotPassword = "forgot-password",
    Experience = "experience",
    ChooseZense = "choose-zense",
    ChooseNaturescape = "choose-naturescape",
    ChooseBreathwork = "choose-breathwork",
    ChooseTouchpad = "choose-touchpad",
    ExperienceSummary = "experience-summary",
    Review = "review",
    AfterExperience = "after-experience",
    NotAuthorized = "not-authorized",
    Profile = "profile",
    ManageSubscription = "manage"


}