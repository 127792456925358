import React, { useEffect } from "react";
import { withScorm } from "react-scorm-provider";

// Component
const LoadingFullScreenComponent = () => {
  useEffect(() => {
    // Dynamically insert keyframes for spinner animation
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = `
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `;
    document.head.appendChild(styleSheet);

    // Clean up the style element when the component is unmounted
    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          padding: "40px",
          borderRadius: "10px", // Rounded corners
          display: "flex",
          flexDirection: "column", // Make items stack vertically
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "120px",
            height: "120px",
            border: "4px solid rgba(0, 0, 0, 0.1)", // Light border
            borderTop: "4px solid black", // Black top border for animation
            borderRadius: "50%",
            animation: "spin 1s linear infinite",
            marginBottom: "20px", // Space between spinner and text
          }}
        ></div>
        <h1 className="overlay-heading"
          id="div-loading-popup"
          role="alert"
          aria-busy="true"
          aria-label="Loading Experience"
          aria-live="assertive"
          tabIndex={0}
        >
          Loading...
        </h1>
      </div>
    </div>
  );
};

const LoadingFullScreen = withScorm()(LoadingFullScreenComponent);
export default LoadingFullScreen;
