import { RouteId } from "../../../Routes";
import { NavigationHandlerApi } from "../../API/NavigationHandlerApi";
import { GlobalAppState, setAppStateToStart } from "../../AppState";
import { isNullOrEmpty } from "../../Data/Util/util";
import { getConfiguration } from "../../Util/ConfigHelper";
import { LOGGER, LogLevel } from "../../Util/Logger";

export class WebNavigationHandler implements NavigationHandlerApi {

    navigate(window: Window & typeof globalThis, 
            location: RouteId,
            previousPageStateHandler?: any, 
            currentPageStateHandler?: any): void {
        
        if (previousPageStateHandler) {
            previousPageStateHandler(true);
        }
        if (currentPageStateHandler) {
            currentPageStateHandler(false);
        }

        window.history.pushState({}, "", `/${location}`);
                
        // communicate to Routes that URL has changed
        const navEvent = new PopStateEvent('popstate');
        window.dispatchEvent(navEvent);
    }

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    back(window: Window & typeof globalThis,
        previousPageStateHandler: any, 
        currentPageStateHandler: any,
        isChooseZense: boolean,
        isDeepLink: boolean): void {
        
        // if the user came from a deep link, send them back to Home when they click Home 
        // and clear the app state
        if (isDeepLink) {
            this.navigate(window, RouteId.Root);
            setAppStateToStart(GlobalAppState);
        } else {
            // perform native browser back action
            window.history.back();
        }
    }

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    exit(window: Window & typeof globalThis, scorm: any): void {
    }

    checkExperiencePage(
        window: Window & typeof globalThis, 
        location: RouteId,
        props: any
    ): void {
        if (isNullOrEmpty(props.mood)
            && isNullOrEmpty(props.zense)
            && isNullOrEmpty(props.subZense)
            && isNullOrEmpty(props.timeLimit)
            && (GlobalAppState.getIsDeepLink() 
                && (isNullOrEmpty(props.autoPlay)
                    || (RouteId.Experience === location && props.autoPlay !== "true")
                    )
                )
            )
        {
            LOGGER.log(LogLevel.DEBUG, `Missing required params for Experience page for: location=${location}, props=${JSON.stringify(props)}`);
            if (getConfiguration().isExperienceDeepLinkOnly) {
                this.navigate(window, RouteId.NotAuthorized);
            } else {
                this.navigate(window, RouteId.Root);
            }
        }
    }

}