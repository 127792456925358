
export function isNullOrEmpty(obj?: any | null | undefined) {
  return obj === null || typeof obj === 'undefined' || !obj;
}

export function isNullOrEmptyTrimmed(obj?: any | null | undefined) {
  return obj === null || typeof obj === 'undefined' || !obj || obj.trim() === "";
}

export function returnSafely(str?: string | null | undefined): string {
  if (isNullOrEmpty(str)) return "";
  else return str!!;
}

export function containsNullOrEmpty(list: any[]): Boolean {
  for (const element of list) {
    if (isNullOrEmpty(element)) return true;
  }

  return false;
}

export function listContains<T>(value: T, list: T[]) {
  return list.indexOf(value) !== -1;
}

// Return if the text contains the substring
export function stringContains(
  str?: string | null | undefined,
  val?: string | null | undefined): boolean {
  if (isNullOrEmpty(str) || isNullOrEmpty(val)) return false;

  return str!!.indexOf(val!!) !== -1;
}

// Return if the text contains any of the substrings
export function stringContainsValues(
  str?: string | null | undefined,
  values?: string[] | null | undefined): boolean {

  if (isNullOrEmpty(str) || isNullOrEmpty(values)) return false;

  for (let i = 0; i <= values!!.length; i++) {
    const substring = values!![i];
    if (str!!.indexOf(substring) !== -1) {
      return true;
    }
  }
  return false;
}

export function replaceAll(str: string, textReplacements: { [token: string]: string }): string {
  for (var token in textReplacements) {
    str = str.replaceAll(token, textReplacements[token]);
  }
  return str;
}

// Get a list of all the values that are in the text
export function getAllInstancesInString(
  str?: string | null | undefined,
  values?: string[] | null | undefined
): string[] {

  if (isNullOrEmpty(str) || isNullOrEmpty(values) || values!!.length === 0) {
    return [];
  }

  const subset: string[] = [];

  values!!.forEach(function (val: string | null | undefined) {
    if (stringContains(str, val)) {
      subset.push(val!!);
    }
  });

  return subset;
}

// get a random subset from an array
export function getRandomSubSet(arr: any[], n: number) {
  let len = arr.length;
  const result = new Array(n);
  const taken = new Array(len);
  let count = n;

  if (n > len) {
    throw new RangeError('getRandom: more elements taken than available');
  }
  while (count--) {
    const x = Math.floor(Math.random() * len);
    result[count] = arr[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
}

export function getRandomInt(max: number, not: number = -1): number {
  let rand: number | null = null;
  while (rand === null || rand === not) {
    rand = Math.floor(Math.random() * Math.floor(max));
  }
  return rand;
}

export function getRandomBoolean(): boolean {
  return Math.random() < 0.5;
}

// Returns an integer random number between min (included) and max (included):
export function randomInteger(minIncluded: number, maxIncluded: number): number {
  return Math.floor(Math.random() * (maxIncluded - minIncluded + 1)) + minIncluded;
}

/* Randomize array in-place using Durstenfeld shuffle algorithm */
export function shuffleArray(array: any[]) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

export function getCurrentMillis(): number {
  return Date.now();
}

export function deleteValues(obj: any, valueNames: string[]): any {
  valueNames.forEach(function (name) {
    if ((Object.prototype.hasOwnProperty.call(obj, name))) {
      delete obj[name];
    }
  });
  return obj;
}

export function chunkArray(arr: any[], perChunk: number): any[][] {
  return arr.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
}

export function objectEquals(x: any, y: any) {
  if (x === y) return true;
  // if both x and y are null or undefined and exactly the same

  if (!(x instanceof Object) || !(y instanceof Object)) return false;
  // if they are not strictly equal, they both need to be Objects

  if (x.constructor !== y.constructor) return false;
  // they must have the exact same prototype chain, the closest we can do is
  // test there constructor.

  for (const p in x) {
    if (!Object.prototype.hasOwnProperty.call(x, p)) continue;
    // other properties were tested using x.constructor === y.constructor

    if (!Object.prototype.hasOwnProperty.call(y, p)) return false;
    // allows to compare x[ p ] and y[ p ] when set to undefined

    if (x[p] === y[p]) continue;
    // if they have the same strict value or identity then they are equal

    if (typeof (x[p]) !== "object") return false;
    // Numbers, Strings, Functions, Booleans must be strictly equal

    if (!objectEquals(x[p], y[p])) return false;
    // Objects and Arrays must be tested recursively
  }

  for (const p in y) {
    if (Object.prototype.hasOwnProperty.call(y, p) && !Object.prototype.hasOwnProperty.call(x, p)) {
      return false;
    }
    // allows x[ p ] to be set to undefined
  }

  return true;
}

export const removeDuplicates = <T>(arr: T[]) => {
  return arr.filter(function (elem, pos) {
    return arr.indexOf(elem) === pos;
  });
};

export function getPercentageInt(x: number, y: number): number {
  return Math.round(100 * (x / y));
}

export function getMap(key: any, value: any): any {
  const obj: any = {};
  obj[key] = value;
  return obj;
}

export function getEnumValues<T>(anEnum: T, isNumeric = false): T[keyof T][] {
  const enumValues = (Object.values(anEnum as any) as unknown) as T[keyof T][];
  return enumValues.filter((item) => {
    return !isNumeric || !isNaN(Number(item));
  });
}

export function isValidEnumValue<T>(anEnum: T, value: any): boolean {
  return getEnumValues(anEnum).includes(value);
}

export function randomEnum<T>(anEnum: T): T[keyof T] {
  const enumValues = (Object.values(anEnum as any) as unknown) as T[keyof T][];
  const randomIndex = Math.floor(Math.random() * enumValues.length);
  return enumValues[randomIndex];
}

export function randomEnumValueNumeric<T>(anEnum: T): T[keyof T] {
  const enumValues = Object.keys(anEnum as any)
    .map(n => Number.parseInt(n))
    .filter(n => !Number.isNaN(n)) as unknown as T[keyof T][]
  const randomIndex = Math.floor(Math.random() * enumValues.length)
  const randomEnumValue = enumValues[randomIndex]
  return randomEnumValue;
}

export function getFirstName(fullName: string | null | undefined): string | null {
  if (isNullOrEmpty(fullName)) {
    return null;
  }

  const arr = fullName!!.split(' ');
  if (arr.length === 0) {
    return null;
  } else {
    return arr[0];
  }
}

export function getLastNames(fullName: string | null | undefined): string | null {
  if (isNullOrEmpty(fullName)) {
    return null;
  }

  const arr = fullName!!.split(' ');
  if (arr.length <= 1) {
    return null;
  } else {
    return arr.slice(1).join(' ');
  }
}

export function countProperties(obj: any) {
  var count = 0;
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      ++count;
    }
  }
  return count;
}
export function countInList<T>(obj: T, list: T[] | null | undefined): number {
  if (isNullOrEmpty(list)) {
    return 0;
  }

  var count = 0;
  for (var i = 0; i < list!!.length; i++) {
    if (list!![i] === obj) {
      count++;
    }
  }
  return count;
}

export function pad(num: number, size: number): string {
  let s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
}

export function removeListItem<T>(list: T[], deleteCheck: Function): T[] {
  for (var i = 0; i < list.length; i++) {
    if (deleteCheck(list[i])) {
      list.splice(i, 1); // 2nd parameter means remove one item only
      i--;
    }
  }
  return list;
}

export function getLastListItem<T>(list: T[]): T {
  if (list.length === 0) {
    throw new Error(`List is empty`);
  }
  return list[list.length - 1];
}

export function getBiggestListItem<T>(list: T[]): T {
  if (list.length === 0) {
    throw new Error(`List is empty`);
  }
  var biggest: T = list[0];
  for (var i = 1; i < list.length; i++) {
    if (list[i] > biggest) {
      biggest = list[i];
    }
  }
  return biggest;
}

// https://stackoverflow.com/a/51005583
export function equalsIgnoringCase(text: string, other: string) {
  return text.localeCompare(other, undefined, { sensitivity: 'base' }) === 0;
}

/**
 * Checks whether the email input is valid
 *
 * @param  email the email input string
 * @return TRUE: if the email regex is valid and email input is not empty, FALSE: otherwise
 */
export function checkEmailFormat(email: string): Boolean {
  // check the email regex, return FALSE it is not valid
  const reg = /^[\w(/+)\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  if (isNullOrEmpty(email) || email.trim() === "" || reg.test(email) === false) {
    return false;
  }
  return true;
}

/**
 * Checks whether the password input is valid
 *
 * @param  password the password input string
 * @return TRUE: if password input is not empty, FALSE: otherwise
 */
export function checkPasswordFormat(password: string): Boolean {
  // check the password format, return FALSE it is not valid
  if (isNullOrEmpty(password) || password.trim() === "") {
    return false;
  }
  return true;
}

/**
 * Checks whether the name format is valid
 *
 * @param  name the name input string
 * @return TRUE: if name input is not empty, FALSE: otherwise
 */
export function checkNameFormat(name: string): Boolean {
  // check the password format, return FALSE it is not valid
  if (isNullOrEmpty(name) || name.trim() === "") {
    return false;
  }
  return true;
}

/**
 * Checks whether the new password input is valid
 *
 * @param  password the new password input string
 * @return TRUE: if the password regex is valid and email input is not empty, FALSE: otherwise
 */

export function checkNewPasswordFormat(password: string): Boolean {
  // check the password format, return FALSE it is not valid
  // minimum 8 characters, 1 lowercase letter, 1 uppercase letter, 1 number, 1 special character
  const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,300}$/;
  if (isNullOrEmpty(password) || password.trim() === "" || reg.test(password) === false) {
    return false;
  }
  return true;
}

/**
 * @returns TRUE: if the current page is running locally (ie. hostname is localhost or 127.0.0.1
 */
export function isLocalHost(): boolean {
  return window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
}