
// List of common personal email domains
const PERSONAL_EMAIL_DOMAINS: string[] = [
    "gmail.com",
    "yahoo.com",
    "hotmail.com",
    "outlook.com",
    "live.com",
    "aol.com",
    "icloud.com",
    "protonmail.com",
    "mail.com",
    "zoho.com"
];

export function getEmailDomain(email: string): string {
    return email.split('@')[1];
}

export function getEmailDomainWithSubdomain(email: string): { subdomain?: string, domain: string } {
    // split the email address into the subdomain and domain
    const parts = email.split('@')[1].split('.');
    if (parts.length < 2) {
        return { subdomain: '', domain: parts[0] };
    } else if (parts.length === 2) {
        return { subdomain: '', domain: parts.join('.') };
    } else {
        return { 
            // domain is the last two parts
            domain: parts.slice(-2).join('.'),
            // subdomain is everything before the last two parts
            subdomain: parts.slice(0, -2).join('.')
         };
    }
}

export function isWorkEmail(email: string): boolean {
    // Extract the domain from the email address
    const emailDomain = getEmailDomain(email);

    // Check if the domain is in the list of personal email domains
    if (!emailDomain) {
        return false; // Invalid email format
    }

    return !PERSONAL_EMAIL_DOMAINS.includes(emailDomain.toLowerCase());
}
