import Lavalamp from "../containers/Lavalamp";
import LavalampRed from "../containers/RedBubbles"
import LavalampBlue from "../containers/BlueBubbles"
import { ColourId } from "./Data/constants";
const LavaLamp = (props) => {
  return (
    <>
    {
      props.color === ColourId.Red ?
      <LavalampRed/> :
      props.color === ColourId.Blue ?
      <LavalampBlue /> :
      <Lavalamp/>
    }
   </>
)};

export default LavaLamp;