import { AnalyticsApi } from "../API/AnalyticsApi";
import { DataApi } from "../API/DataApi";
import { AllAnalyticsImpl } from "../Impl/Analytics/AllAnalyticsImpl";
import { FirebaseAnalyticsImpl } from "../Impl/Analytics/FirebaseAnalyticsImpl";
import { MixpanelAnalyticsImpl } from "../Impl/Analytics/MixpanelAnalyticsImpl";
import { ScormAnalyticsImpl } from "../Impl/Scorm/ScormAnalyticsImpl";
import { getConfiguration } from "../Util/ConfigHelper";
import { ProviderApi } from "./ProviderApi";
import { EnvId } from "../Data/constants";
import { AuthApi } from "../API/AuthApi";
import { ImageLoaderApi } from "../API/ImageLoaderApi";
import { SessionHandlerApi } from "../API/SessionHandlerApi";
import { AudioLoaderApi } from "../API/AudioLoaderApi";
import { LocalAudioLoader } from "../Impl/LocalAudioLoader";
import { AuthProvider } from "../Data/Models/configuration";
import { NoAuthImpl } from "../Impl/Auth/NoAuthImpl";
import { FirebaseAuthImpl } from "../Impl/Auth/FirebaseAuthImpl";
import { NavigationHandlerApi } from "../API/NavigationHandlerApi";
import { LocalDataImpl } from "../Impl/Data/LocalDataImpl";
import { FeatureUiApi } from "../API/FeatureUiApi";
import { ExperienceConfigApi } from "../API/ExperienceConfigApi";
import { ExperienceConfigFileImpl } from "../Impl/Data/ExperienceConfigImpl";
import { FirebaseDataImpl } from "../Impl/Data/FirebaseDataImpl";
import { ErrorHandlerApi } from "../API/ErrorHandlerApi";
import { ZensoryErrorHandlerImpl } from "../Impl/Error/ZensoryErrorHandlerImpl";
import { LicenseApi } from "../API/LicenseApi";
import { NoLicensesImpl } from "../Impl/Licenses/NoLicensesImpl";
import { FirebaseLicensesImpl } from "../Impl/Licenses/FirebaseLicensesImpl";

export abstract class BaseProvider implements ProviderApi {
    
    envId: EnvId;
    audioLoaderApi: AudioLoaderApi | null = null;
    dataApi: DataApi | null = null;
    analyticsApi: AnalyticsApi | null = null;
    authApi: AuthApi | null = null;
    imageLoaderApi: ImageLoaderApi | null = null;
    sessionHandlerApi: SessionHandlerApi | null = null;
    navigationHandlerApi: NavigationHandlerApi | null = null;
    featureUiApi: FeatureUiApi | null = null;
    experienceConfigApi: ExperienceConfigApi | null = null;
    errorHandlerApi: ErrorHandlerApi | null = null;
    licensesApi: LicenseApi | null = null;

    constructor(envId: EnvId) {
        this.envId = envId;
    }

    abstract createImageLoader(): ImageLoaderApi;
    abstract createSessionHandler(): SessionHandlerApi;
    abstract createNavigationHandler(): NavigationHandlerApi;
    abstract createFeatureUi(): FeatureUiApi;
    
    provideAuth(): AuthApi {
        if (this.authApi === null) {
            const config = getConfiguration();
            if (config.authProvider === AuthProvider.None) {
                this.authApi = new NoAuthImpl();
            } else if (config.authProvider === AuthProvider.Firebase) {
                this.authApi = new FirebaseAuthImpl(this.provideAnalytics());
            } else {
                throw new Error(`Invalid config authProvider=${config.authProvider}`);
            }
        }
        return this.authApi;
    }

    provideAudioLoader(): AudioLoaderApi {
        if (this.audioLoaderApi === null) {
            this.audioLoaderApi = new LocalAudioLoader();
        }
        return this.audioLoaderApi;
    }

    provideFeatureUi(): FeatureUiApi {
        if (this.featureUiApi === null) {
            this.featureUiApi = this.createFeatureUi();
        }
        return this.featureUiApi;
    }

    provideLicenses(): LicenseApi {
        if (this.licensesApi === null) {
            const config = getConfiguration();
            if (config.authProvider === AuthProvider.None) {
                this.licensesApi = new NoLicensesImpl();
            } else if (config.authProvider === AuthProvider.Firebase) {
                this.licensesApi = new FirebaseLicensesImpl();
            } else {
                throw new Error(`Invalid config authProvider=${config.authProvider}`);
            }
        }
        return this.licensesApi;
    }

    provideAnalytics(): AnalyticsApi {
        if (this.analyticsApi === null) {
            const apis: AnalyticsApi[] = [];
            
            const config = getConfiguration();
            if (config.analyticMethods.scorm) {
                apis.push(new ScormAnalyticsImpl());
            }
            if (config.analyticMethods.firebase) {
                apis.push(new FirebaseAnalyticsImpl());
            }
            if (config.analyticMethods.mixpanel) {
                apis.push(new MixpanelAnalyticsImpl(this.envId));
            }

            this.analyticsApi = new AllAnalyticsImpl(apis);
        }
        return this.analyticsApi;
    }

    provideImageLoader(): ImageLoaderApi {
        if (this.imageLoaderApi === null) {
            this.imageLoaderApi = this.createImageLoader();
        }
        return this.imageLoaderApi;
    }

    provideSessionHandler(): SessionHandlerApi {
        if (this.sessionHandlerApi === null) {
            this.sessionHandlerApi = this.createSessionHandler();
        }
        return this.sessionHandlerApi;
    }

    provideNavigationHandler(): NavigationHandlerApi {
        if (this.navigationHandlerApi === null) {
            this.navigationHandlerApi = this.createNavigationHandler();
        }
        return this.navigationHandlerApi;
    }

    provideExperienceConfig(): ExperienceConfigApi {
        if (this.experienceConfigApi === null) {
            this.experienceConfigApi = new ExperienceConfigFileImpl();
        }
        return this.experienceConfigApi;
    }

    provideErrorHandler(): ErrorHandlerApi {
        if (this.errorHandlerApi === null) {
            this.errorHandlerApi = new ZensoryErrorHandlerImpl(this.envId, this.provideAuth());
        }
        return this.errorHandlerApi;
    }

    provideData(): DataApi {
        if (this.dataApi === null) {
            const config = getConfiguration();
            if (config.authProvider === AuthProvider.None) {
                this.dataApi = new LocalDataImpl(this.envId, this.provideAuth());
            } else if (config.authProvider === AuthProvider.Firebase) {
                this.dataApi = new FirebaseDataImpl(this.envId, this.provideAuth());
            } else {
                throw new Error(`Invalid config authProvider=${config.authProvider}`);
            }
        }
        return this.dataApi;
    }

}