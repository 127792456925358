import React, { useEffect } from "react";
import "../styles/rainColor.css"

const RainColor = (props) => {
  const url = `rainColor.js`;
  //const url2 = `https://cdnjs.cloudflare.com/ajax/libs/sketch.js/1.0/sketch.min.js`;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url]);

  return (
    <canvas class="sketch" id="sketch-0" height="794" width="1731"></canvas>
  );
}

export default RainColor;