import React, { useEffect, useState } from "react";
import "../../styles/choose-experience.css";
import arrow_back from "../../assets/icons/arrow_back.svg";
import TouchpadCard from "../TouchpadCard";
import logo from "../../assets/icons/zensory_logo.svg";
import cross from "../../assets/icons/close.svg"
import { AudioDurationSecs, getAudioDurationTitle, getTouchAudioDurationSecsForMood, SenseId } from "../Data/constants";
import { ZensoryModule } from "../../ZensoryModule";
import { withScorm } from 'react-scorm-provider';
import { AnalyticsScreenName } from "../API/AnalyticsApi";
import { RouteId } from "../../Routes";
import { getText, TextId } from "../Util/TextHelper";
import { isSubmitKey, preventDefault, stopPropagation } from "../UI/KeyPressHelper";
import { Interweave } from "interweave";
import CloseAndExitPopup from "../Close&ExitPopup";
import { GlobalAppState } from "../AppState";

const ChooseTouchpadPage = (props) => {

  ZensoryModule.getAnalytics().trackScreen(AnalyticsScreenName.ScreenCustomise)

  const isShowExitButton = ZensoryModule.getFeatureUi().isShowExitButton();
  const isShowBackButton = ZensoryModule.getFeatureUi().isShowBackButton();
  const [activeTimeLength, setActiveTimeLength] = useState(props.timeLimit);
  const [showCloseAndExitPopup, setShowCloseAndExitPopup] = useState(false);

  useEffect(() => {

    if (document.getElementById("choose-touchpad-container")) {
      document.getElementById("choose-touchpad-container").focus();
    };

    // if the current time selected is not one of the Touch durations, set it to a default
    if (getTouchAudioDurationSecsForMood(props.mood).indexOf(props.timeLimit) === -1) {
      setActiveTimeLength(AudioDurationSecs.THREE_3_MINS)
    }
  }, []);

  const startExperienceSummary = (e) => {
    preventDefault(e);
    props.setExperience(SenseId.Touch);
    ZensoryModule.getNavigationHandler().navigate(window, RouteId.ExperienceSummary, props.onexperienceSummary, props.onChooseTouchpad);
  }

  const back = (e) => {
    preventDefault(e);
    ZensoryModule.getNavigationHandler().back(window, props.onClose, props.onChooseTouchpad, false, GlobalAppState.getIsDeepLink());
  }

  const close = (e) => {
    preventDefault(e);
    setShowCloseAndExitPopup(true);
    ZensoryModule.getNavigationHandler().exit(window, props.sco);
  }

  const onClickButton = (e, audioDurationSecs) => {
    preventDefault(e);
    props.setTimeLimit(audioDurationSecs);
    setActiveTimeLength(audioDurationSecs);
  }

  // handle return key presses on the back button
  const _handleKeyDownBack = (e) => {
    stopPropagation(e);
    if (isSubmitKey(e)) {
      back(e);
    }
  }

  // handle return key presses on the close and exit button
  const _handleKeyDownCloseExit = (e) => {
    stopPropagation(e);
    if (isSubmitKey(e)) {
      close(e);
    }
  }

  const getTimeSelectClassName = (audioDurationSecs) => {
    return activeTimeLength === audioDurationSecs
      ? "time-btn-click" 
      : "time-btn";
  }

  return (
    <div style={{
      height: "100vh",
      width: "100vw",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center"
    }}>
      { showCloseAndExitPopup 
        ? <CloseAndExitPopup setShowCloseAndExitPopup={setShowCloseAndExitPopup} />
        : null
      }
      <div className="header-row-choose">
      { isShowBackButton
        ? <div className="back" 
          id="btn-choose-touchpad-back"
          onClick={(e) => back(e)}
          tabIndex="0"
          onKeyDown={(e) => _handleKeyDownBack(e)}
          role="button"
          alt={getText(
            TextId.backButtonAlt,
            { GENERIC: "to Choose a Zense"})}
          aria-label={getText(
            TextId.backButtonAlt,
            { GENERIC: "to Choose a Zense"})}
        >
          <img 
            src={arrow_back}
            style={{ marginTop: "2px" }}
            alt="Back to Choose a Zense"
          />
          <span>
            { GlobalAppState.getIsDeepLink()
              ? "Home"
              : "Zenses"
            } 
          </span>
        </div>
        : <div className="placeholder"> </div> }
        {isShowExitButton
          ? <div className="close"
            id="btn-choose-touchpad-close"
            onClick={(e) => close(e)}
            tabIndex="0"
            onKeyDown={(e) => _handleKeyDownCloseExit(e)}
            aria-label={getText(TextId.mainCloseAndExitAriaLabel)}
            role="button" >
            <img
              src={cross}
              style={{ marginTop: "2px" }}
              alt="Close icon"
            />
            <span>{getText(TextId.mainCloseAndExitText)}</span>
          </div>
          : null
        }
        <div className="logo-container">
          <img
            className="logo"
            src={logo}
            alt="Zensory Logo"
          />
        </div>
      </div>
      <div
        className="choose-experience-container"
        tabIndex="-1"
        id="choose-touchpad-container">
          
        <h1 className="major-heading-experience">
          <Interweave
            content={getText(TextId.customizeExperienceTouchTitle)}
          />
        </h1>
        <div className="touchpad-card"
            alt="Touchpads display tile"
            aria-label="Touchpads display tile"
            tabIndex="0">
          <TouchpadCard setExperience={props.setExperience} />
        </div>
        <div className="time-box">
          <div className="choose-time-heading">
            <Interweave
              content={getText(TextId.customizeExperienceTimeLengthText)}
            />
          </div>
          <div className="time-row">
            {getTouchAudioDurationSecsForMood(props.mood).map((durationSecs, index) => {
              return (
                <button
                  key={index}
                  className={getTimeSelectClassName(durationSecs)}
                  id={durationSecs}
                  onClick={(e) => onClickButton(e, durationSecs)}
                  tabIndex="0"
                  alt={getAudioDurationTitle(durationSecs)}
                  aria-label={getAudioDurationTitle(durationSecs)}
                >
                  {getAudioDurationTitle(durationSecs)}
                </button>
              )})
            }
          </div>
        </div>
        <div className="learn-more">
          <Interweave
            content={getText(TextId.customizeExperienceTouchWarningText)}
          />
        </div>
        <div className="experience-btn-container">
          <button
            id="btn-choose-touchpad-next"
            className="next-button"
            onClick={(e) => startExperienceSummary(e)}>
            Next
          </button>
        </div>
      </div>

    </div>
  );
};

const ChooseTouchpad = withScorm()(ChooseTouchpadPage);

export default ChooseTouchpad;
