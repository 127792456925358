// Import the functions you need from the SDKs you need
import { FirebaseApp, FirebaseOptions, initializeApp } from "firebase/app";
import { Analytics, getAnalytics } from "firebase/analytics";
import { Functions, getFunctions } from "firebase/functions";
import { Firestore, getFirestore } from "firebase/firestore";
import { getConfiguration } from "./ConfigHelper";
import { EnvId } from "../Data/constants";
import { Auth, getAuth } from "firebase/auth";
import { LOGGER, LogLevel } from "./Logger";
import { apiPost } from "../Services/apiCalls";
import { ZensoryCloudFunctionNames } from "../Data/Models/zensory_api";
import { isLocalHost } from "../Data/Util/util";

var FIREBASE_HELPER: FirebaseHelper | null = null;

// Set to TRUE to use the local Cloud Functions emulator.
// Note: Only allowed with DEV environment when running locally.
const USE_LOCAL_CLOUD_FUNCTIONS = false && isLocalHost();
const CLOUD_FUNCTIONS_URL_LOCAL = "http://127.0.0.1:5001/thezensory/europe-west1/";
const CLOUD_FUNCTIONS_URL_DEV   = "https://europe-west1-thezensory.cloudfunctions.net/";
const CLOUD_FUNCTIONS_URL_PROD  = "https://europe-west1-thezensoryapp.cloudfunctions.net/";

export function getFirebaseHelper(): FirebaseHelper {
  if (FIREBASE_HELPER === null) {
    const config = getConfiguration()
    FIREBASE_HELPER = new FirebaseHelper(config.envId);
  }
  return FIREBASE_HELPER;
}

class FirebaseHelper {

  private firebaseConfig: {[key: string]: any;}
  private firebaseApp: FirebaseApp;
  private firebaseAuth: Auth;
  private firebaseAnalytics: Analytics;
  private firebaseFunctions: Functions;
  private db: Firestore;
  private cloudFunctionUrl;

  constructor(envId: EnvId) {
    const zensoryFirebaseOptions = getFirebaseConfig(envId);
    this.cloudFunctionUrl = zensoryFirebaseOptions.cloudFunctionUrl;
    this.firebaseConfig = zensoryFirebaseOptions;

    this.firebaseApp = initializeApp(this.firebaseConfig);
    this.firebaseAnalytics = getAnalytics(this.firebaseApp);
    this.firebaseAuth = getAuth(this.firebaseApp);
    this.firebaseFunctions = getFunctions(this.firebaseApp);
    this.db = getFirestore(this.firebaseApp);
  }

  getApp(): FirebaseApp {
    return this.firebaseApp;
  }

  getAuth(): Auth {
    return this.firebaseAuth;
  }

  getAnalytics(): Analytics {
    return this.firebaseAnalytics;
  }

  getFunctions(): Functions {
    return this.firebaseFunctions;
  }

  getFirestore(): Firestore {
    return this.db;
  }

  /**
   * 
   * @param functionName Name of the Cloud Function
   * @param {C} payload payload object to send to the Cloud Function
   * @returns response from the Cloud Function
   */
  async callCloudFunction <C, T> (functionName: ZensoryCloudFunctionNames, payload: C): Promise<T> {
    LOGGER.log(LogLevel.DEBUG, `callCloudFunction() name=${functionName}, payload=${JSON.stringify(payload)}`);
  
    try {
      const response = await apiPost(
        this.cloudFunctionUrl,
        functionName,
        payload
      );
      const responseData = await response.json();
      LOGGER.log(LogLevel.DEBUG, `callCloudFunction() name=${functionName} response=${JSON.stringify(responseData)}`);
      if ((response as any).error) {
        throw responseData;
      }
      return responseData as T;

    } catch (error) {
      LOGGER.log(LogLevel.WARN, `callCloudFunction() name=${functionName} error=${error}`);
      throw error;
    }
  }

}

interface ZensoryFirebaseOptions extends FirebaseOptions {
  cloudFunctionUrl: string
};

/**
 * @param envId the EnvId to get the values for
 * @returns the FirebaseConfig for the environment
 */
function getFirebaseConfig(envId: EnvId): ZensoryFirebaseOptions {
  if (USE_LOCAL_CLOUD_FUNCTIONS && (envId !== EnvId.DEV || !isLocalHost())) {
    throw new Error(`USE_LOCAL_CLOUD_FUNCTIONS can only be used with EnvId.DEV when running locally.`);
  }
  
  switch (envId) {
    case EnvId.DEV:
      var cloudFunctionUrl;
      if (USE_LOCAL_CLOUD_FUNCTIONS) {
        cloudFunctionUrl = CLOUD_FUNCTIONS_URL_LOCAL;
      } else {
        cloudFunctionUrl = CLOUD_FUNCTIONS_URL_DEV;
      }

      return {
        cloudFunctionUrl: cloudFunctionUrl,
        apiKey: "AIzaSyC-7f9reLQznVTw7W443I_aiB1aJuyVz1g",
        authDomain: "thezensory.firebaseapp.com",
        databaseURL: "https://thezensory.firebaseio.com",
        projectId: "thezensory",
        storageBucket: "thezensory.appspot.com",
        messagingSenderId: "963062658656",
        appId: "1:963062658656:web:326ef485fd18bb1510f505",
        measurementId: "G-XGD15DGMNS"
      };

    case EnvId.PROD:
      return {
        cloudFunctionUrl: CLOUD_FUNCTIONS_URL_PROD,
        apiKey: "AIzaSyCFmPHXTEVlBgU7R1jrUvLDMNasetNLdH4",
        authDomain: "thezensoryapp.firebaseapp.com",
        databaseURL: "https://thezensoryapp.firebaseio.com",
        projectId: "thezensoryapp",
        storageBucket: "thezensoryapp.appspot.com",
        messagingSenderId: "615024349711",
        appId: "1:615024349711:web:976085bdd4e94fe35f497a",
        measurementId: "G-FLJPJWFHBN"
      };

    default:
      throw new Error(`EnvId=${envId} not supported`);
  }
}
