import React, { useState } from "react";
import "../../styles/afterExperience.css"
import cross from "../../assets/icons/close.svg"
import logo from "../../assets/icons/zensory_logo.svg"
import app_store from "../../assets/icons/app_store.png"
import play_store from "../../assets/icons/play_store.png"
import { ZensoryModule } from "../../ZensoryModule";
import { withScorm } from 'react-scorm-provider';
import { GlobalAppState, restartExperience } from "../AppState";
import { LOGGER, LogLevel } from "../Util/Logger";
import { RouteId } from "../../Routes";
import { getText, TextId } from "../Util/TextHelper";
import { Interweave } from "interweave";
import { isSubmitKey, preventDefault, stopPropagation } from "../UI/KeyPressHelper";
import CloseAndExitPopup from "../Close&ExitPopup";
import { EndExperienceType, SurveyPlatform } from "../Data/Models/configuration"
import { getEndExperienceConfigSurvey } from "../Util/ConfigHelper";

const AfterExperiencePage = (props) => {

    const endExperienceConfig = ZensoryModule.getExperienceConfig().getEndExperienceConfig();
    const isShowAppDownloadConfig = ZensoryModule.getFeatureUi().isShowAppDownloadConfig();
    const isShowExitButton = ZensoryModule.getFeatureUi().isShowExitButton();
    const isShowRestartButton = ZensoryModule.getFeatureUi().isShowRestartButton();
    const isShowFinishButton = ZensoryModule.getFeatureUi().isShowFinishButton();
    const isExperienceCompleted = ZensoryModule.getSessionHandler().isCompleted();
    const [showCloseAndExitPopup, setShowCloseAndExitPopup] = useState(false);

    const finish = (e) => {
        preventDefault(e);

        LOGGER.log(LogLevel.DEBUG, `Review finish() Review experienceDuration=${props.experienceDuration}`);

        ZensoryModule.getNavigationHandler().navigate(window, RouteId.Root);
    }

    const restart = (e) => {
        preventDefault(e);
        restartExperience(GlobalAppState);
        ZensoryModule.getNavigationHandler().navigate(window, RouteId.Experience);
    }

    const close = (e) => {
        preventDefault(e);
        setShowCloseAndExitPopup(true);
        ZensoryModule.getNavigationHandler().exit(window, props.sco);
    }

    // handle return key presses on the close and exit button
    const _handleKeyDownCloseExit = (e) => {
        stopPropagation(e);
        if (isSubmitKey(e)) {
            close();
        }
    }

    // when the title text is clicked trigger a problem report
    const onTitleClicked = async (e) => {
        preventDefault(e);
        await ZensoryModule.getErrorHandler().sendProblemReport();
    }

    return (
        <div className="review-container">
            <div className="header-row-review">
                {isShowExitButton
                    ? <div className="close"
                        id="btn-after-exp-close"
                        onClick={(e) => close(e)}
                        tabIndex="0"
                        onKeyDown={(e) => _handleKeyDownCloseExit(e)}>
                        <img
                            src={cross}
                            style={{ marginTop: "2px" }}
                            alt="Close and exit"
                        />
                        <span>Close & Exit</span>
                    </div>
                    : <div style={{ height: "80px" }}></div>
                }
                <div className="logo-container">
                    <img
                        className="logo"
                        src={logo}
                        alt="Zensory logo" />
                </div>
            </div>
            <div className="after-experience-content-container">
                {showCloseAndExitPopup 
                    ? <CloseAndExitPopup
                        setShowCloseAndExitPopup={setShowCloseAndExitPopup}
                    />
                    : null
                }
                <h1 className="major-heading-after-experience"
                    id="h1-after-exp"
                    onClick={(e) => onTitleClicked(e)}
                >
                    <Interweave
                        content={
                            endExperienceConfig.pageTitleText
                                ? endExperienceConfig.pageTitleText
                                : getText(TextId.reviewTitleComplete) //for scorm version
                        }
                    />
                </h1>
                <div className="after-experience-section-container">
                    <div className="after-experience-section-one">
                        <div className="after-experience-section-one-heading">
                            {
                                endExperienceConfig.titleText
                                    ? endExperienceConfig.titleText
                                    : null
                            }
                        </div>
                        <div className="after-experience-section-one-text">
                            {
                                endExperienceConfig.subTitleText
                                    ? endExperienceConfig.subTitleText
                                    : getText(TextId.reviewSubtitle2Complete) //for scorm version
                            }
                        </div>
                        {
                            endExperienceConfig.type === EndExperienceType.ContactForm
                                ? <a
                                    id="btn-after-exp-contact"
                                    href={endExperienceConfig.buttonClickUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ textDecoration: "none" }}
                                >
                                    <button className="next-button-contact">
                                        {endExperienceConfig.buttonText}
                                    </button>
                                </a>
                                : endExperienceConfig.type === EndExperienceType.Survey
                                    ? endExperienceConfig.surveyPlatform === SurveyPlatform.GoogleForm
                                        ? <iframe
                                            src={getEndExperienceConfigSurvey(endExperienceConfig)}
                                            title="googleForm"
                                            className="form-iframe"
                                            frameborder="0" marginheight="0"
                                            marginwidth="0">
                                            Loading…
                                        </iframe>
                                        : endExperienceConfig.surveyPlatform === SurveyPlatform.TypeForm
                                            ? <>
                                                <iframe
                                                    title={endExperienceConfig.surveyTitle}
                                                    src={getEndExperienceConfigSurvey(endExperienceConfig)}
                                                    data-tf-widget={endExperienceConfig.surveyId}
                                                    data-tf-opacity="100"
                                                    data-tf-iframe-props={`title=${endExperienceConfig.surveyTitle}`}
                                                    data-tf-transitive-search-params
                                                    data-tf-medium="snippet"
                                                    style={{ width: "100%", height: "500px" }}>
                                                </iframe>
                                            </>
                                            : null
                                    : null
                        }
                    </div>
                    {isShowAppDownloadConfig
                        ? <div className="after-experience-section-two">
                            {
                                endExperienceConfig.appDownloadConfig.title
                                    ? endExperienceConfig.appDownloadConfig.title
                                    : null
                            }
                            <div className="app-download-icon-container">
                                <a
                                    id="btn-after-exp-android"
                                    href={endExperienceConfig.appDownloadConfig.androidButtonUrl}
                                    target="_blank"
                                    rel="noreferrer">

                                    <img
                                        src={play_store}
                                        className="app-download-icon"
                                        alt="play store"
                                    />
                                </a>
                                <a
                                    id="btn-after-exp-ios"
                                    href={endExperienceConfig.appDownloadConfig.iosButtonUrl}
                                    target="_blank"
                                    rel="noreferrer">

                                    <img
                                        src={app_store}
                                        className="app-download-icon"
                                        alt="app store"
                                    />
                                </a>
                            </div>
                        </div>
                        : null
                    }
                </div>
                {isShowFinishButton || isShowRestartButton
                    ? <div className="review-button-container">
                        {isShowRestartButton
                            ? <button
                                id="btn-after-exp-restart"
                                disabled={!isExperienceCompleted}
                                className="restart-button"
                                onClick={(e) => restart(e)}>
                                {getText(isExperienceCompleted
                                    ? TextId.reviewRestartCompleteButtonText
                                    : TextId.reviewRestartIncompleteButtonText
                                )}
                            </button>
                            : null
                        }
                        {isShowFinishButton
                            ? <button
                                id="btn-after-exp-finish"
                                disabled={false}
                                className="next-button-review"
                                onClick={(e) => finish(e)}>
                                {getText(isExperienceCompleted
                                    ? TextId.reviewFinishCompleteButtonText
                                    : TextId.reviewFinishIncompleteButtonText
                                )}
                            </button>
                            : null
                        }
                     </div>
                    : null
                }
            </div>
        </div>
    );
};

const AfterExperience = withScorm()(AfterExperiencePage);

export default AfterExperience;
