/*
    Declare constants (eg. Cloud Function names) and interfaces used to communicate with the Zensory Backend
*/

import { Experience, UserActivity, UserProfileStats } from "./app";

export enum ZensoryCloudFunctionNames {
  GetUser = "getUser",
  TrackUserActivity = "trackUserActivityWeb",
  SendProblemReportEmail = "sendProblemReportEmailWeb",
  GetUserProfileStats = "getUserProfileStats",
  GetUserExperienceHistory = "getUserExperienceHistoryWeb",
  OnUserSignedUp = "onUserSignedUp",
  GetUserLicensesWeb = "getUserLicensesWeb",
  GetAvailableLicences = "getAvailableLicensesWeb",
  CompleteSubscription = "doStripeSubscriptionWeb",
  OnUserSubscribed = "onUserSubscribedWeb",
  CancelSubscription = "cancelStripeSubscriptionWeb",
  OnUserCancelledSubscription = "onUserCancelSubscriptionWeb",
  GetStripeCoupon = "getStripeCouponWeb",
  RedeemCode = "redeemCodeWeb"
}

export interface TrackUserActivityRequest {
  userId: string;
  activity: UserActivity;
}

export interface SendProblemReportRequest {
  subject: string;
  plainText: string;
  password: string;
}

export interface SendProblemReportResponse {
  result: {
    success: boolean;
    message: string;
  };
}

export interface GetUserStatsRequest {
  userId: string;
}

export interface GetUserStatsResponse {
  result: {
    success: boolean;
    message: string;
    stats: UserProfileStats;
  };
}

export interface GetUserExperienceHistoryResponse {
  result: {
    history: Experience[];
  };
}
