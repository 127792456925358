import { AudioObj, AudioPlayer, AudioPlayerApi, AudioType, LOAD_TIME_MINIMUM_MILLIS } from "../API/AudioPlayerAPI";
import { getCurrentMillis, isNullOrEmpty } from "../Data/Util/util";
import { setExperienceLoadTimeGlobal } from "../Util/ExperienceAnalyticsHelper";
import { LOGGER, LogLevel } from "../Util/Logger";


export abstract class BaseAudioPlayer implements AudioPlayerApi {

    id: AudioPlayer;
    loadStartAt = 0;
    audios: AudioObj[];
    isDestroyed = false;

    private onLoaded: Function;
    private onError: Function;
    private audioLoadCount = 0;
    
    constructor(
        id: AudioPlayer,
        audios: AudioObj[],
        onLoaded: Function,
        onError: Function
    ) {
        this.id = id;
        this.audios = audios;
        this.onLoaded = onLoaded;
        this.onError = onError;

        for (const audio of audios) {
            if (isNullOrEmpty(audio)) {
                throw new Error(`Empty audio object in audios=${JSON.stringify(audios)}`);
            } else if (isNullOrEmpty(audio.uri)) {
                throw new Error(`Missing audio URI in ${JSON.stringify(audio)}`);
            } else if (isNullOrEmpty(audio.id)) {
                throw new Error(`Missing audio ID in ${JSON.stringify(audio)}`);
            }
        }
    }

    abstract getAudioObj(audioObj: AudioObj): Promise<any>;

    abstract playAudioObj(audioObj: AudioObj): void;
    
    abstract setVolume(volume: number): void;

    async load(): Promise<void> {
        if (this.audios.length === 0) {
            LOGGER.log(LogLevel.ERROR, `${this.id} load() Called with no audios to load`);
            return;
        }
        
        this.loadStartAt = getCurrentMillis();
        LOGGER.log(LogLevel.DEBUG, `${this.id} load() loadStartAt=${this.loadStartAt}`);
        const promises: Promise<void>[] = [];
        for (const audioObj of this.audios) {
            LOGGER.log(LogLevel.DEBUG, `${this.id} load() add Promise for getAudioObj() type=${audioObj.type}, uri=${audioObj.uri}`);
            promises.push(this.getAudioObj(audioObj));
        }
        const allSettled = await Promise.allSettled(promises)
        LOGGER.log(LogLevel.DEBUG, `${this.id} load() allSettled=${allSettled}`);
        return;
    }

    onAudioLoaded(audioObj: AudioObj) {
        this.audioLoadCount++;
        LOGGER.log(LogLevel.DEBUG, `${this.id} onAudioLoaded() audioLoadCount=${this.audioLoadCount}`);
        if (this.audioLoadCount === this.audios.length) {
            const loadTimeTakenMs = getCurrentMillis() - this.loadStartAt;
            const loadTimeTakenSecs = Math.round(loadTimeTakenMs / 1000)
            LOGGER.log(LogLevel.DEBUG, `${this.id} onAudioLoaded() On All Audio Loaded, loadTimeMs=${loadTimeTakenMs}ms [loadTimeSecs=${loadTimeTakenSecs}secs]`);
            
            // set the experience load time in seconds
            setExperienceLoadTimeGlobal(loadTimeTakenSecs);

            // Hold the loading for minimum 1 second
            const loadTimeRemainingMs = Math.max(LOAD_TIME_MINIMUM_MILLIS - loadTimeTakenMs, 0);
            LOGGER.log(LogLevel.DEBUG, `${this.id} onAudioLoaded() calling this.onLoaded() in ${loadTimeRemainingMs}ms`);
            setTimeout(() => {
                this.onLoaded();
            }, loadTimeRemainingMs);
        }
    }

    onAudioError(error: any) {
        LOGGER.log(LogLevel.DEBUG, `${this.id} onAudioError() calling this.onError() with error=${error}`);
        
        // pass to the error handler function
        this.onError(error);
    }

    play(): void {   
        LOGGER.log(LogLevel.DEBUG, `${this.id} play() audios=${this.audios}`);
        for (const audioObj of this.audios) {
            this.playAudioObj(audioObj);
        }
    }

    pause(): void {
        LOGGER.log(LogLevel.DEBUG, `${this.id} pause() audios=${this.audios}`);
        for (const audioObj of this.audios) {
            this.pauseAudioObj(audioObj);
        }
    }

    setVolumeForObj(audioObj: AudioObj, volume: number): void {
        const volumeToSet = audioObj.volume * (volume / 100); // apply as % of the original volume
        audioObj.audio.volume = volumeToSet;
    }

    setBinauralVolume(volume: number): void {
        for (const audioObj of this.audios) {
            if (audioObj.type === AudioType.Binaural) {
                this.setVolumeForObj(audioObj, volume);
            }
        }
    }

    setMusicVolume(volume: number): void {
        for (const audioObj of this.audios) {
            if (audioObj.type === AudioType.Music) {
                this.setVolumeForObj(audioObj, volume);
            }
        }
    }

    setAudioBreathVolume(volume: number): void {
        for (const audioObj of this.audios) {
            if (audioObj.type === AudioType.AudioBreath){
                this.setVolumeForObj(audioObj, volume);
            }
        }
    }

    setNaturescapeVolume(volume: number): void {
        for (const audioObj of this.audios) {
            if (audioObj.type === AudioType.Soundscape) {
                this.setVolumeForObj(audioObj, volume);
            }
        }
    }

    setVisualBreathTriggerVolume(volume: number): void {
        for (const audioObj of this.audios) {
            if (audioObj.type === AudioType.VisualBreathTrigger) {
                this.setVolumeForObj(audioObj, volume);
            }
        }
    }
    
    pauseAudioObj(audioObj: AudioObj): void {
        audioObj.audio?.pause();
    }

    destroy(): void {
        LOGGER.log(LogLevel.DEBUG, `destroy() called`);
        this.isDestroyed = true;
        this.audios = [];
    }
}