import React, { useEffect, useState } from "react";
import "../../styles/layout.css";
import logo from "../../assets/icons/zensory_logo.svg";
import info from "../../assets/icons/info.svg";
import rotate from "../../assets/icons/rotate.svg";
import cross from "../../assets/icons/close.svg";
import relax_vector from "../../assets/icons/relax-vector.svg";
import focus_swirl from "../../assets/icons/focus-swirl.svg";
import check from "../../assets/icons/check-white.svg";
import { withScorm } from "react-scorm-provider";
import { ZensoryModule } from "../../ZensoryModule";
import { AnalyticsScreenName } from "../API/AnalyticsApi";
import { getMoodIdTitle, MoodId } from "../Data/constants";
import { RouteId } from "../../Routes";
import { getText, TextId } from "../Util/TextHelper";
import { Interweave } from "interweave";
import {
  isSubmitKey,
  preventDefault,
  stopPropagation,
} from "../UI/KeyPressHelper";
import { getConfiguration } from "../Util/ConfigHelper";
import CloseAndExitPopup from "../Close&ExitPopup";
import { isMacOS } from "../Util/DeviceUtil";
import { listContains } from "../Data/Util/util";
import { LOGGER, LogLevel } from "../Util/Logger";
import profileIcon from "../../assets/icons/profileprofileIcon.png";
import { CustomerId, isHideProfileButton } from "../Data/Models/configuration";

const WelcomePage = (props) => {
  const MOOD_IDS = ZensoryModule.getExperienceConfig().getMoods();

  ZensoryModule.getAnalytics().trackScreen(
    AnalyticsScreenName.ScreenChooseMood
  );

  useEffect(() => {
    if (document.getElementById("welcome-box-row")) {
      document.getElementById("welcome-box-row").focus();
    }
  }, []);

  const [focusFlip, setFocusFlip] = useState(false);
  const [relaxFlip, setRelaxFlip] = useState(false);
  const [showCloseAndExitPopup, setShowCloseAndExitPopup] = useState(false);
  const isShowExitButton = ZensoryModule.getFeatureUi().isShowExitButton();
  const isExperienceCompleted = ZensoryModule.getSessionHandler().isCompleted() && getConfiguration().customerId !== CustomerId.Brigantia;

  // Return whether to show the Mood based on the configuration
  const isShowMood = (moodId) => {
    const show = listContains(moodId, MOOD_IDS);
    LOGGER.log(
      LogLevel.DEBUG,
      `isShowMood() MOOD_IDS=${MOOD_IDS}, moodId=${moodId}, show=${show}`
    );
    return show;
  };

  const startExperience = (e, moodId) => {
    preventDefault(e);
    props.onMood(moodId);
    ZensoryModule.getNavigationHandler().navigate(
      window,
      RouteId.ChooseZense,
      props.onChooseZense
    );
  };

  const onFlip = (e, mood) => {
    stopPropagation(e);
    mood === MoodId.Focused
      ? setFocusFlip(!focusFlip)
      : setRelaxFlip(!relaxFlip);
  };

  const close = (e) => {
    preventDefault(e);
    setShowCloseAndExitPopup(true);
    ZensoryModule.getNavigationHandler().exit(window, props.sco);
  };

  // handle return key presses on the Tiles
  const _handleKeyDownTile = (e, moodId) => {
    stopPropagation(e);
    if (isSubmitKey(e)) {
      startExperience(e, moodId);
    }
  };

  // handle return key presses on the Info icon on the Tiles
  const _handleKeyDownTileInfo = (e, moodId) => {
    stopPropagation(e);
    if (isSubmitKey(e)) {
      onFlip(e, moodId);
    }
  };

  const _handleKeyDownProfile = (e) => {
    stopPropagation(e);
    if (isSubmitKey(e)) {
      onProfileClicked(e);
    }
  };

  // handle return key presses on the close and exit button
  const _handleKeyDownCloseExit = (e) => {
    stopPropagation(e);
    if (isSubmitKey(e)) {
      close(e);
    }
  };

  // when the version text is clicked
  const onVersionClicked = async (e) => {
    preventDefault(e);
    stopPropagation(e);
    await ZensoryModule.getErrorHandler().sendProblemReport();
  };

  const onProfileClicked = async (e) => {
    preventDefault(e);
    ZensoryModule.getNavigationHandler().navigate(window, RouteId.Profile);
  };

  return (
    <>
      {showCloseAndExitPopup ? (
        <CloseAndExitPopup
          setShowCloseAndExitPopup={setShowCloseAndExitPopup}
        />
      ) : null}

      <div className="choose-experience-container">
        <div
          className="header-row-welcome"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            paddingTop: '2pc',
            position: 'relative' // Ensure the positioning context for z-index
          }}
        >
          <img
            className="logoNew"
            src={logo}
            alt="Zensory logo"
            style={{
              textAlign: 'center', // Center the logo text
              maxWidth: '200px'    // Limit the logo's size to avoid taking too much space
            }}
          />
          <div
            className="rightButtons"
            style={{
              display: 'flex',
              alignItems: 'center',
              zIndex: 1 // Ensure buttons are on top of other elements
            }}
          >
            {isHideProfileButton() ? null : (
              <div
                onClick={(e) => onProfileClicked(e)}
                className="profileButtonContainer"
                tabIndex="0"
                onKeyDown={(e) => _handleKeyDownProfile(e)}
                aria-label={"Profile"}
                role="button"
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <img src={profileIcon} height={20} alt="Profile" />
                <div className="btn-menu">Profile</div>
              </div>
            )}
            {isShowExitButton 
              ? isHideProfileButton() ? (
                <div
                  id="btn-welcome-close"
                  className="close"
                  onClick={(e) => close(e)}
                  tabIndex="0"
                  onKeyDown={(e) => _handleKeyDownCloseExit(e)}
                  aria-label={getText(TextId.mainCloseAndExitAriaLabel)}
                  alt={getText(TextId.mainCloseAndExitAriaLabel)}
                  role="button"
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <img src={cross} style={{ marginTop: '2px' }} alt="Close icon" />
                  <span>{getText(TextId.mainCloseAndExitText)}</span>
                </div>
              )
              : (
                <div
                  onClick={(e) => close(e)}
                  className="profileButtonContainer"
                  tabIndex="0"
                  onKeyDown={(e) => _handleKeyDownCloseExit(e)}
                  aria-label={getText(TextId.mainCloseAndExitAriaLabel)}
                  role="button"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer'
                  }}
                >
                  <img src={cross} height={20} alt="Close and Exit" />
                  <div className="btn-menu">Close & Exit</div>
                </div>
              ) 
              : null
            }
          </div>
        </div>

        <h1
          className={
            isExperienceCompleted
              ? "welcome-heading-complete"
              : "welcome-heading"
          }
        >
          <Interweave
            content={getText(
              isExperienceCompleted && isShowExitButton
                ? TextId.homeWelcomeTitleComplete
                : TextId.homeWelcomeTitleIncomplete
            )}
          />
          {isExperienceCompleted && isShowExitButton ? (
            <span>
              <img src={check} className="checkmark" alt="Check icon" />
            </span>
          ) : null}
        </h1>
        <div className="welcome-subheading">
          <Interweave
            content={getText(
              isExperienceCompleted
                ? TextId.homeWelcomeExperiencePromptComplete
                : TextId.homeWelcomeExperiencePromptIncomplete
            )}
          />
        </div>
        <div className="learn-more">
          <Interweave content={getText(TextId.homeWelcomeInfoText)} />
        </div>
        <div className="welcome-subtitle">
          {getText(TextId.homeWelcomeSubtitle)}
        </div>
        <div className="box-row" tabIndex="-1" id="welcome-box-row">
          {isShowMood(MoodId.Focused) ? (
            <div
              onClick={(e) => startExperience(e, MoodId.Focused)}
              id="tile-focus"
              className={`focus-box ${focusFlip ? "active" : ""}`}
              tabIndex="0"
              onKeyDown={(e) => _handleKeyDownTile(e, MoodId.Focused)}
              aria-label="Focus Mood selection tile"
              alt="Focus Mood selection tile"
            >
              <img
                id="btn-welcome-focus-info"
                src={focusFlip ? rotate : info}
                role="button"
                alt={getText(
                  focusFlip
                    ? TextId.hideInformationAlt
                    : TextId.showInformationAlt,
                  { GENERIC: getMoodIdTitle(MoodId.Focused) }
                )}
                aria-label={getText(
                  focusFlip
                    ? TextId.hideInformationAlt
                    : TextId.showInformationAlt,
                  { GENERIC: getMoodIdTitle(MoodId.Focused) }
                )}
                className="info-icon"
                onClick={(e) => onFlip(e, MoodId.Focused)}
                tabIndex="0"
                onKeyDown={(e) => _handleKeyDownTileInfo(e, MoodId.Focused)}
              />
              <div className="focus-box-inner">
                <div className="focus-box-front">
                  <img
                    className="focus-box-vector"
                    src={focus_swirl}
                    alt="focus vector"
                  />
                  <div
                    className="focus-box-front-headings"
                    role={isMacOS() ? "textbox" : null}
                    tabIndex={focusFlip ? "-1" : "0"}
                  >
                    <div>
                      <Interweave
                        content={getText(
                          TextId.homeWelcomeFocusBoxFrontHeading
                        )}
                      />
                    </div>
                    <div className="focus-box-subheading">
                      <Interweave
                        content={getText(
                          TextId.homeWelcomeFocusBoxFrontSubHeading
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="focus-box-back"
                  role={isMacOS() ? "textbox" : null}
                  tabIndex={focusFlip ? "0" : "-1"}
                >
                  {/* <div className="focus-box-back-heading">
                  <Interweave
                    content={getText(TextId.homeWelcomeFocusBoxFrontHeading)}
                  />
                </div> */}
                  <Interweave
                    className="focus-box-back-body"
                    content={getText(TextId.homeWelcomeFocusBoxBackText)}
                  />
                </div>
              </div>
            </div>
          ) : null}
          <div
            onClick={(e) => startExperience(e, MoodId.Relax)}
            id="tile-relax"
            className={`relax-box ${relaxFlip ? "active" : ""}`}
            tabIndex="0"
            onKeyDown={(e) => _handleKeyDownTile(e, MoodId.Relax)}
            alt="Relax Mood selection tile"
            aria-label="Relax Mood selection tile"
          >
            <img
              id="btn-welcome-relax-info"
              src={relaxFlip ? rotate : info}
              role="button"
              alt={getText(
                relaxFlip
                  ? TextId.hideInformationAlt
                  : TextId.showInformationAlt,
                { GENERIC: getMoodIdTitle(MoodId.Relax) }
              )}
              aria-label={getText(
                relaxFlip
                  ? TextId.hideInformationAlt
                  : TextId.showInformationAlt,
                { GENERIC: getMoodIdTitle(MoodId.Relax) }
              )}
              className="info-icon"
              onClick={(e) => onFlip(e, MoodId.Relax)}
              tabIndex="0"
              onKeyDown={(e) => _handleKeyDownTileInfo(e, MoodId.Relax)}
            />
            <div className="focus-box-inner">
              <div className="focus-box-front">
                <img
                  className="focus-box-vector"
                  src={relax_vector}
                  alt="relax vector"
                />
                <div
                  className="focus-box-front-headings"
                  role={isMacOS() ? "textbox" : null}
                  tabIndex={relaxFlip ? "-1" : "0"}
                >
                  <div>{getText(TextId.homeWelcomeRelaxBoxFrontHeading)}</div>
                  <div className="focus-box-subheading">
                    {getText(TextId.homeWelcomeRelaxBoxFrontSubHeading)}
                  </div>
                </div>
              </div>
              <div
                className="focus-box-back"
                role={isMacOS() ? "textbox" : null}
                tabIndex={relaxFlip ? "0" : "-1"}
              >
                {/* <div className="focus-box-back-heading">
                {getText(TextId.homeWelcomeRelaxBoxFrontHeading)}
              </div> */}
                <Interweave
                  className="focus-box-back-body"
                  content={getText(TextId.homeWelcomeRelaxBoxBackText)}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="web-version"
          id="version-welcome"
          onClick={(e) => onVersionClicked(e)}
        >
          <Interweave content={`Version: ${getConfiguration().version}`} />
        </div>
      </div>
    </>
  );
};

const Welcome = withScorm()(WelcomePage);

export default Welcome;
