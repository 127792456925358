import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import "../styles/experience.css"
import pause_icon from "../assets/icons/pause@3x.svg"
import CountDown from './CountDown';
import { AudioDurationSecs } from './Data/constants';
import { useEffect, useRef } from 'react';
import { handleTabKey } from './Util/ModalAccessibilityHelper';
import { KeyCode } from './UI/KeyEnum';

const PauseOverlay = (props) => {

    const modalRef = useRef();
    const btnResume = useRef();

    // set focus to the Resume button when the dialog shows
    useEffect(() => {
        if (btnResume != null && btnResume.current !== null) {
            btnResume.current.focus();
        }
    }, [btnResume]);

    const getPercentageDuration = () => {
        return ((props.experienceDuration / props.timeLimit) * 100)
    }

    useEffect(() => {
        function keyListener(e) {
            const listener = e.keyCode === KeyCode.Tab 
                ? handleTabKey(e, modalRef, 'div.overlay-instruction, div.timer')
                : e.keyCode === KeyCode.Esc 
                    ? props.toggleAudio()
                    : null;
            return listener && listener(e, modalRef,'div.overlay-instruction');
        }
        document.addEventListener("keydown", keyListener);
        return () => document.removeEventListener("keydown", keyListener);
    }, []);

    return (
        <div className="overlay-container" 
            id="div-pause-overlay"
            ref={modalRef}
            onClick={props.toggleAudio}
        >
            <h1 className="overlay-heading">Paused</h1>
            <div className="progress-bar-container">
                <CircularProgressbarWithChildren 
                    value={getPercentageDuration()}
                    strokeWidth={5}
                    styles={
                        buildStyles({
                            pathColor:  "#000000",
                            stroke:     "#000000"
                        })
                    }
                >
                <img 
                    className="play_icon" 
                    src={pause_icon} 
                    alt="Tap to resume experience button"
                />
                </CircularProgressbarWithChildren>
            </div>
            <CountDown
                timeLimit={props.timeLimit}
                countDown={
                    props.timeLimit === AudioDurationSecs.UNLIMITED_LOOP
                        ?  props.experienceDuration
                        :  props.timeLimit - props.experienceDuration
                }
            />
            <div className="overlay-instruction"
                ref={btnResume}
                tabIndex={0}
                alt="Experience Paused. Click or use Space or Return key to Resume Experience"
                aria-label="Experience Paused. Click or use Space or Return key to Resume Experience"
            >
                Tap to resume
            </div>
        </div>
    )
};

export default PauseOverlay;