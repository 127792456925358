import * as Sentry from "@sentry/react";
import { AuthApi } from "../../API/AuthApi";
import { ErrorHandlerApi } from "../../API/ErrorHandlerApi";
import { getCurrentMillis, isNullOrEmpty } from "../../Data/Util/util";
import { EnvId } from "../../Data/constants";
import { LOGGER, LogLevel, getLogs } from "../../Util/Logger";
import { getConfiguration, isDebug } from "../../Util/ConfigHelper";
import { getDeviceDetails } from "../../Util/DeviceUtil";
import { getYYY_MM_DD_hh_mm_ForTimestamp } from "../../Data/Util/date_utils";
import { SendProblemReportRequest, SendProblemReportResponse, ZensoryCloudFunctionNames } from "../../Data/Models/zensory_api";
import { getFirebaseHelper } from "../../Util/FirebaseHelper";

export class ZensoryErrorHandlerImpl implements ErrorHandlerApi {

    envId: EnvId;
    authApi: AuthApi;

    private isSentryInitialized: boolean = false;

    constructor(envId: EnvId, authApi: AuthApi) {
        this.envId = envId;
        this.authApi = authApi;
    }

    private initSentry() {
        if (this.isSentryInitialized) {
            return;
        }
        this.isSentryInitialized = true;
    
        const SENTRY_SAMPLE_RATE = this.envId === EnvId.DEV ? 1.0 : 0.1;

        const config = getConfiguration(false);
        var hosts = [...config.authorisedDomains];
        if (this.envId === EnvId.DEV) {
            hosts.push("localhost");
        }

        Sentry.init({
          dsn: "https://cd254bde44c947a88229703323e77ca4@o1163912.ingest.sentry.io/6599023",
          integrations: [new Sentry.BrowserTracing({tracePropagationTargets: hosts}), new Sentry.Replay()],
          // Performance Monitoring
          tracesSampleRate: SENTRY_SAMPLE_RATE, // Capture % of the transactions
          // Session Replay
          replaysSessionSampleRate: SENTRY_SAMPLE_RATE,
          replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
          environment: this.envId === EnvId.PROD ? "production" : "development"
        });
    }
    
    init(userId?: string) {
        this.initSentry();
    
        var key: string;
        if (isNullOrEmpty(userId)) {
            key = "unknownUserId";
        } else {
            key = userId!!;
        }
        Sentry.setUser({key: key});
    }
    
    destroy() {
        this.initSentry();
        Sentry.setUser(null);
    }
    
    handleError(e: any) {
        this.initSentry();
        Sentry.captureException(e);
    }
    
    logMessage(
        logLevel: LogLevel,
        msg: any,
        ...optionalParams: any[]
    ) {
        if (logLevel === LogLevel.ERROR) {
            this.initSentry();
            Sentry.captureMessage(msg, this.getSentryLogLevel(logLevel));
        }
    }
    
    getSentryLogLevel(logLevel: LogLevel): Sentry.SeverityLevel {
        switch (logLevel) {
            case LogLevel.ERROR:
                return "error";
                case LogLevel.WARN:
                case LogLevel.DEBUG:
                    return "debug";
                case LogLevel.INFO:
                case LogLevel.TRACE:
                    return "info";
                default:
                    throw new Error(`Unsupported logLevel=${logLevel}`);
        }
    }
    
    async sendProblemReport(): Promise<boolean> {
        // only run for debug mode
        if (!isDebug()) {
            LOGGER.log(LogLevel.WARN, `Click version, not debug mode`);
            return true;
        }
    
        const config = getConfiguration();
        var adminPassword = config.devSettings.sendEmailPassword;
        // if there is no password in the config, prompt the user for the password
        if (isNullOrEmpty(adminPassword)) {
            var adminPasswordFromUser = window.prompt("Enter admin password");
            if (!isNullOrEmpty(adminPasswordFromUser)) {
                adminPassword = adminPasswordFromUser!!;
            } else {
                window.alert("Password is empty");
                return false;
            }
        }
      
        // else show a browser dialog for the user to confirm 
        if (window.confirm("Do you want to send a Problem Report?")) {
            const result = await this.sendProblemReportAux(adminPassword);
            if (result) {
                window.alert("Problem Report sent successfully");
            } else {
                window.alert("Error sending Problem Report");
            }
            return result;
        } else {
            return false;
        }
    }
    
    async sendProblemReportAux(adminPassword: string): Promise<boolean> {
        var description = window.prompt("Please explain the issue you are reporting? List screen and Experience details.");

        const config = getConfiguration();
        const logs = getLogs();
        const deviceInfo = getDeviceDetails();
        var subject = `Problem Report [version=${config.configId} ${config.version}]`;
        var body = `Problem Report [version=${config.configId} ${config.version}]`;
        
        // add user if logged in
        const user = this.authApi.getCurrentUser();
        if (user && user !== "none") {
            subject += ` UserId=${user}`;
            body += `<br><br>UserId: ${user}`;        
        }
        // add device details
        subject += ` ${deviceInfo?.os?.name} ${deviceInfo?.browser?.name}`;
        // add timestamp
        subject += ` ${getYYY_MM_DD_hh_mm_ForTimestamp(getCurrentMillis())}`;
        
        body += `<br><br>Description: ${description}`; 
        body += `<br><br>Device: ${JSON.stringify(deviceInfo)}`;
        body += `<br><br>Logs:<br><br>${logs.join('<br>')}`;
    
        const req: SendProblemReportRequest = {
            subject: subject,
            plainText: body,
            password: adminPassword
        };
        
        // send email with Cloud Functions
        const response: SendProblemReportResponse = await getFirebaseHelper().callCloudFunction(
            ZensoryCloudFunctionNames.SendProblemReportEmail,
            req
        );
        return response.result.success;
    }
}