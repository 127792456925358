import React, { useEffect } from "react";
import "../styles/racingStripes.css"

const RacingStripes = () => {

    const url = "racingStripes.js"

    useEffect(() => {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
  
      document.body.appendChild(script);
      const startDiv = document.getElementById('startRacingStripes');
      startDiv.click();
      const stopDiv = document.getElementById('stopRacingStripes');
      
      return () => {
        stopDiv.click();
        document.body.removeChild(script);
      }
    }, [url]);

    return (
      <>
        <div id="stopRacingStripes"></div>
        <div id="startRacingStripes"></div>
        <canvas id="stripes"></canvas>
      </>
    );
}

export default RacingStripes;