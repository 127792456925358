import { AudioLoaderApi } from "../API/AudioLoaderApi";
import { MoodId, AudioDurationSecs, SoundscapeId, AudioTriggerId } from "../Data/constants";
import { beach10, beach25, beach3, fireplace10, fireplace25, fireplace3, focusaudioguidedbreathing5, focusbinaural10, 
    focusbinaural20, focusbinaural3, focusmusic10, focusmusic20, focusmusic3, forest10, forest25, forest3, gardenAmbience10,
    gardenAmbience25, gardenAmbience3, meadow10, meadow25, meadow3, rain10, rain25, rain3, rainforest10, rainforest25, rainforest3,
    relaxaudioguidedbreathing4, relaxbinaural10, relaxbinaural20, relaxbinaural3, relaxmusic10, relaxmusic20, relaxmusic3,
    tropicalBeach10, tropicalBeach25, tropicalBeach3, whitenoise3, whitenoise10, whitenoise25, breathTriggerHold1, breathTriggerIn1, breathTriggerOut1, breathTriggerNum02, breathTriggerNum03, breathTriggerNum04, breathTriggerNum05, breathTriggerNum06, breathTriggerNum07, focusmusic25, focusbinaural25, brownnoise3, brownnoise10, brownnoise25, babblingBrook3, babblingBrook10, babblingBrook25, desert3, desert10, desert25, lakeAtNight3, lakeAtNight10, lakeAtNight25, mountain3, mountain10, mountain25, ocean3, ocean10, ocean25, train3, train10, train25 } from "./AudioFileAssetImports";

export class LocalAudioLoader implements AudioLoaderApi {
   
    getBinuaralAudio(moodId: MoodId.Focused | MoodId.Relax, audioDurationSecs: AudioDurationSecs): string {
        return BINUARAL_AUDIO[moodId][audioDurationSecs];
    }
   
    getMusicAudio(moodId: MoodId.Focused | MoodId.Relax, audioDurationSecs: AudioDurationSecs): string {
        return MUSIC_AUDIO[moodId][audioDurationSecs];
    }
   
    getNaturescapeAudio(soundscapeId: SoundscapeId, audioDurationSecs: AudioDurationSecs): string {
        return NATURESCAPE_AUDIO[soundscapeId][audioDurationSecs];
    }

    getAudioGuidedBreathworkAudio(moodId: MoodId.Focused | MoodId.Relax, audioDurationSecs: AudioDurationSecs): string {
        return AUDIOGUIDED_BREATHWORK_AUDIO[moodId][audioDurationSecs];
    }

    getBreathAudioTriggerMap(): {[audioTriggerId in AudioTriggerId]: string} {
        return {
            hold1: this.getBreathAudioTrigger(AudioTriggerId.Hold1),
            in1: this.getBreathAudioTrigger(AudioTriggerId.In1),
            out1: this.getBreathAudioTrigger(AudioTriggerId.Out1),
            num2: this.getBreathAudioTrigger(AudioTriggerId.Num2),
            num3: this.getBreathAudioTrigger(AudioTriggerId.Num3),
            num4: this.getBreathAudioTrigger(AudioTriggerId.Num4),
            num5: this.getBreathAudioTrigger(AudioTriggerId.Num5),
            num6: this.getBreathAudioTrigger(AudioTriggerId.Num6),
            num7: this.getBreathAudioTrigger(AudioTriggerId.Num7),
        }
    }

    getBreathAudioTrigger(audioTriggerId: AudioTriggerId): string {
        switch (audioTriggerId) {
            case AudioTriggerId.Hold1: 
                return breathTriggerHold1;
            case AudioTriggerId.In1: 
                return breathTriggerIn1;
            case AudioTriggerId.Out1: 
                return breathTriggerOut1;

            case AudioTriggerId.Num2: 
                return breathTriggerNum02;
            case AudioTriggerId.Num3: 
                return breathTriggerNum03;
            case AudioTriggerId.Num4: 
                return breathTriggerNum04;
            case AudioTriggerId.Num5: 
                return breathTriggerNum05;
            case AudioTriggerId.Num6: 
                return breathTriggerNum06;
            case AudioTriggerId.Num7: 
                return breathTriggerNum07;

            default:
                throw new Error(`Invalid AudioTriggerId=${audioTriggerId}`);
        }
    }

}

const MUSIC_AUDIO: {[moodId in MoodId.Relax | MoodId.Focused]: {[audioDurationSecs in AudioDurationSecs]: string}} = {
    focused: {
        180: focusmusic3,
        240: "",
        300: "",
        600: focusmusic10,
        1200: focusmusic20,
        1500: focusmusic25,
        "-1": focusmusic25,
    },
    relax: {
        180: relaxmusic3,
        240: "",
        300: "",
        600: relaxmusic10,
        1200: relaxmusic20,
        1500: "",
        "-1": relaxmusic20,
    }
};

const BINUARAL_AUDIO: {[moodId in MoodId.Relax | MoodId.Focused]: {[audioDurationSecs in AudioDurationSecs]: string}} = {
    focused: {
        180: focusbinaural3,
        240: "",
        300: "",
        600: focusbinaural10,
        1200: focusbinaural20,
        1500: focusbinaural25,
        "-1": focusbinaural25,
    },
    relax: {
        180: relaxbinaural3,
        240: "",
        300: "",
        600: relaxbinaural10,
        1200: relaxbinaural20,
        1500: "",
        "-1": relaxbinaural20,
    }
};

// 20 min duration should use 25 min duration audio files
const NATURESCAPE_AUDIO: {[soundscapeId in SoundscapeId]: {[audioDurationSecs in AudioDurationSecs]: string}} = {
    none: {
        180: "",
        240: "",
        300: "",
        600: "",
        1200: "",
        1500: "",
        "-1": "",
    },
    noNature: {
        180: "",
        240: "",
        300: "",
        600: "",
        1200: "",
        1500: "",
        "-1": "",
    },
    beach: {
        180: beach3,
        240: "",
        300: "",
        600: beach10,
        1200: beach25,
        1500: beach25,
        "-1": beach25,
    },
    tropicalBeach: {
        180: tropicalBeach3,
        240: "",
        300: "",
        600: tropicalBeach10,
        1200: tropicalBeach25,
        1500: tropicalBeach25,
        "-1": tropicalBeach25,
    },
    fireplace: {
        180: fireplace3,
        240: "",
        300: "",
        600: fireplace10,
        1200: fireplace25,
        1500: fireplace25,
        "-1": fireplace25,
    },
    forest: {
        180: forest3,
        240: "",
        300: "",
        600: forest10,
        1200: forest25,
        1500: forest25,
        "-1": forest25,
    },
    meadow: {
        180: meadow3,
        240: "",
        300: "",
        600: meadow10,
        1200: meadow25,
        1500: meadow25,
        "-1": meadow25,
    },
    rainforest: {
        180: rainforest3,
        240: "",
        300: "",
        600: rainforest10,
        1200: rainforest25,
        1500: rainforest25,
        "-1": rainforest25,
    },
    garden: {
        180: gardenAmbience3,
        240: "",
        300: "",
        600: gardenAmbience10,
        1200: gardenAmbience25,
        1500: gardenAmbience25,
        "-1": gardenAmbience25,
    },
    rain: {
        180: rain3,
        240: "",
        300: "",
        600: rain10,
        1200: rain25,
        1500: rain25,
        "-1": rain25,
    },
    whiteNoise: {
        180: whitenoise3,
        240: "",
        300: "",
        600: whitenoise10,
        1200: whitenoise25,
        1500: whitenoise25,
        "-1": whitenoise25,
    },
    brownNoise: {
        180: brownnoise3,
        240: "",
        300: "",
        600: brownnoise10,
        1200: brownnoise25,
        1500: brownnoise25,
        "-1": brownnoise25,
    },
    babblingBrook: {
        180: babblingBrook3,
        240: "",
        300: "",
        600: babblingBrook10,
        1200: babblingBrook25,
        1500: babblingBrook25,
        "-1": babblingBrook25,
    },
    desert: {
        180: desert3,
        240: "",
        300: "",
        600: desert10,
        1200: desert25,
        1500: desert25,
        "-1": desert25,
    },
    lakeAtNight: {
        180: lakeAtNight3,
        240: "",
        300: "",
        600: lakeAtNight10,
        1200: lakeAtNight25,
        1500: lakeAtNight25,
        "-1": lakeAtNight25,
    },
    mountain: {
        180: mountain3,
        240: "",
        300: "",
        600: mountain10,
        1200: mountain25,
        1500: mountain25,
        "-1": mountain25,
    },
    ocean: {
        180: ocean3,
        240: "",
        300: "",
        600: ocean10,
        1200: ocean25,
        1500: ocean25,
        "-1": ocean25,
    },
    train: {
        180: train3,
        240: "",
        300: "",
        600: train10,
        1200: train25,
        1500: train25,
        "-1": train25,
    },
};

const AUDIOGUIDED_BREATHWORK_AUDIO: {[moodId in MoodId.Relax | MoodId.Focused]: {[audioDurationSecs in AudioDurationSecs]: string}} = {
    focused: {
        180: "",
        240: "",
        300: focusaudioguidedbreathing5,
        600: "",
        1200: "",
        1500: "",
        "-1": "",
    },
    relax: {
        180: "",
        240: relaxaudioguidedbreathing4,
        300: "",
        600: "",
        1200: "",
        1500: "",
        "-1": "",
    }
};