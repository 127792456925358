import { ZensoryModule } from "../../ZensoryModule";
import { Configuration } from "../Data/Models/configuration";
import { getCurrentMillis, isNullOrEmpty } from "../Data/Util/util";
import { getConfiguration, isDebug } from "./ConfigHelper";
import { getYYY_MM_DD_HH_mm_SSForTimestamp } from "./TimeUtil";

// store all the logs that get posted in memory (used when sending a Problem Report)
const LOGS: string[] = [];

var hasSetup = false;

/**
 * Setup to listen to console log messages and add them to our LOGS store
 */
function setupLogger() {
    if (!hasSetup) {
        hasSetup = true;
        const logNormal = console.log;
        const logTrace = console.trace;
        const logInfo = console.info;
        const logDebug = console.debug;
        const logWarn = console.warn;
        const logError = console.error;

        const addToLogs = (logMsg: string, ...optionalParams: any[]) => {
            // add the log message to the in-memory store of log messages
            if (isDebug()) {
                if (!isNullOrEmpty(optionalParams) && optionalParams.length > 0) {
                    for (const param of optionalParams) {
                        logMsg += ` ${param}`;
                    }
                }

                LOGS.push(logMsg);
            }
        };

        console.log = function(message?: any, ...optionalParams: any[]) {
            logNormal(message);
            addToLogs(message, optionalParams);
        }

        console.trace = function(message?: any, ...optionalParams: any[]){
            logTrace(message);
            addToLogs(message, optionalParams);
        }

        console.info = function(message?: any, ...optionalParams: any[]){
            logInfo(message);
            addToLogs(message, optionalParams);
        }

        console.debug = function(message?: any, ...optionalParams: any[]){
            logDebug(message);
            addToLogs(message, optionalParams);
        }

        console.warn = function(message?: any, ...optionalParams: any[]){
            logWarn(message);
            addToLogs(message, optionalParams);
        }

        console.error = function(message?: any, ...optionalParams: any[]){
            logError(message);
            addToLogs(message, optionalParams);
        }
    }
}

export enum LogLevel {
    TRACE="TRACE",
    INFO="INFO",
    DEBUG="DEBUG",
    WARN="WARN",
    ERROR="ERROR"
}

interface LoggerApi {
    
    log(logLevel: LogLevel, msg: string, ...optionalParams: any[]): void;

}

class Logger implements LoggerApi {

    constructor() {
        setupLogger();
    }
    
    log(logLevel: LogLevel, msg: string, ...optionalParams: any[]): void {
        const config: Configuration = getConfiguration(false);

        // do not log if it is disabled
        if (!config.logging.isEnabled) {
            return;
        }

        // log with the error handler
        try {
            ZensoryModule.getErrorHandler().logMessage(logLevel, msg, optionalParams);
        } catch (ignore: any) {
            // catch ZensoryModule not configured yet
        }

        // make sure the logLevel meets the min requirement of the configuration logLevel
        if (getLogLevelOrder(logLevel) < getLogLevelOrder(config.logging.logLevel)) {
            return;
        }

        var logMsg = `${getYYY_MM_DD_HH_mm_SSForTimestamp(getCurrentMillis())} ${logLevel} ${msg}`;
        if (!isNullOrEmpty(optionalParams)) {
            for (const param of optionalParams) {
                if (Object.keys(param)) {
                    logMsg += ` ${JSON.stringify(param)}`;    
                } else {
                    logMsg += ` ${param}`;
                }
            }
        }

        switch (logLevel) {
            case LogLevel.TRACE:
                console.trace(logMsg, optionalParams);
                break;

            case LogLevel.INFO:
                console.info(logMsg, optionalParams);
                break;

            case LogLevel.DEBUG:
                console.debug(logMsg, optionalParams);
                break;

            case LogLevel.WARN:
                console.warn(logMsg, optionalParams);
                break;

            case LogLevel.ERROR:
                console.error(logMsg, optionalParams);
                break;
        }
    }

}

export const LOGGER: LoggerApi = new Logger();

/**
 * @param logLevel The LogLevel to get the numeric order value for
 * @returns the numeric order of the logLevel in increasing value, ie. TRACE=0 (lowest), ERROR=4 (highest)
 */
function getLogLevelOrder(logLevel: LogLevel): number {
    switch (logLevel) {
        case LogLevel.TRACE:
            return 0;

        case LogLevel.INFO:
            return 1;

        case LogLevel.DEBUG:
            return 2;

        case LogLevel.WARN:
            return 3;

        case LogLevel.ERROR:
            return 4; 
    }
}

export function isLogLevelDebug(logLevel: LogLevel): boolean {
    return getLogLevelOrder(logLevel) <= getLogLevelOrder(LogLevel.DEBUG);
}

export function getLogs(): string[] {
    return LOGS;
}
