import "../styles/spinnerLoader.scss"

const SpinnerLoader = (props) => {
  return (
    <div className="loader">
        <svg className="circular">
            <circle className="path" cx="20" cy="20" r="15" fill="none" stroke-width="3" stroke-miterlimit="12"></circle>
        </svg>
    </div>
)};

export default SpinnerLoader;