import { useEffect, useState } from "react";
import { PopupTimeoutValue, SenseId, TouchpadId } from "../components/Data/constants";
import expand_fullscreen from "../assets/icons/expand_full_screen.svg"
import reduce_fullscreen from "../assets/icons/reduce_full_screen.svg"
import cross from "../assets/icons/close.svg"
import randomise from "../assets/icons/randomise.svg"
import levels from "../assets/icons/levels@3x.png"
import info from "../assets/icons/info-new.svg"
import MultiTrackPlayer from "../components/MultiTrackPlayer";
import TouchpadPopup from "../components/TouchpadPopup";
import { TouchpadLoader } from "../components/Loaders/TouchpadLoader";
import VolumeSetting from "../components/VolumeSetting";
import { isKeyIn, isSubmitKey, preventDefault, stopPropagation } from "../components/UI/KeyPressHelper";
import { getText, TextId } from "../components/Util/TextHelper";
import { isIOS, isMobile } from "../components/Util/DeviceUtil";
import { getExperienceButtonTabIndex, isShowExperienceButton, isShowExperienceButtonAux, showStartExperiencePopup } from "./ExperienceUIHelper";
import { KeyId } from "../components/UI/KeyEnum";
import { incrementNumTouchpadRandomiseGlobal } from "../components/Util/ExperienceAnalyticsHelper";

// Main list of Touchpads to use
const TOUCHPADS = [
  TouchpadId.ColorMix,
  TouchpadId.SpinningDots,
  TouchpadId.RacingStripes,
  TouchpadId.Orbit,
  TouchpadId.Bubbles,
  TouchpadId.Cones,
  TouchpadId.Smoke,
  TouchpadId.Squiggles
  // TouchpadId.ExplosiveAttraction, // to CPU intense right now
  // TouchpadId.GreenFluid, // not working on iOS
  // TouchpadId.LittleBubbles, // Not good enough quality
  // TouchpadId.RainColour // Not good enough quality
];

// Touchpads only to be run on Mobile
const TOUCHPADS_MOBILE_ONLY = [];

// Touchpads only to be run on Desktop
const TOUCHPADS_DESKTOP_ONLY = [TouchpadId.MoonDust];

// get the list of Touchpads to use
function getTouchpads() {
  if (!isMobile()) {
    return TOUCHPADS.concat(TOUCHPADS_DESKTOP_ONLY);
  } else {
    return TOUCHPADS.concat(TOUCHPADS_MOBILE_ONLY);
  }
}

const TouchpadExperience = (props) => {

  const [touchpad, setTouchpad] = useState(TouchpadId.Smoke);
  const touchpads = getTouchpads();

  const [showTouchpadPopup, setShowTouchpadPopup] = useState(false);
  const [isInfoIconClicked, setIsInfoIconClicked] = useState(false);
  const [visitedTouchpads, setVisitedTouchpads] = useState([]);

  localStorage.setItem("visited_touchpads", visitedTouchpads);
  let touchpadVisited = localStorage.getItem("visited_touchpads").includes(touchpad);

  useEffect(() => {
    if (isInfoIconClicked ||
      ((!showStartExperiencePopup() || props.hasStarted)
        && props.hasLoaded
        && props.zense === SenseId.Touch
        && !touchpadVisited)) {

      setShowTouchpadPopup(true);

      if (!isInfoIconClicked) {
        setVisitedTouchpads(visitedTouchpads => [...visitedTouchpads, touchpad]);
        localStorage.setItem("visited_touchpads", visitedTouchpads);
      }

      setTimeout(() => {
        setShowTouchpadPopup(false);
        setIsInfoIconClicked(false);
      }, PopupTimeoutValue.Touchpad);
    }
  }, [touchpad, isInfoIconClicked]);

  useEffect(() => {
    if (showStartExperiencePopup() && props.hasStarted) {
      setIsInfoIconClicked(true);
    }
  }, [props.hasStarted]);

  useEffect(() => {
    if (props.hasLoaded && !(showStartExperiencePopup() && !props.hasStarted)) {
      setIsInfoIconClicked(true);
    }
  }, [props.hasLoaded]);

  const getRandomTouchpad = (e) => {
    stopPropagation(e);
    incrementNumTouchpadRandomiseGlobal();
    let index = touchpads.indexOf(touchpad);
    if (index !== -1) {
      touchpads.splice(index, 1);
    }
    const random = touchpads[Math.floor(Math.random() * touchpads.length)];
    touchpads.push(touchpad);
    setTouchpad(random);
  }

  const close = (e) => {
    preventDefault(e);
    props.setCloseExperiencePopup(true);
  }

  // handle return key presses on the close and exit button
  const _handleKeyDownCloseExit = (e) => {
    stopPropagation(e);
    if (isSubmitKey(e)) {
      close(e);
    }
  }

  // handle return key presses on the Info icon
  const _handleKeyDownInfo = (e) => {
    stopPropagation(e);
    if (isSubmitKey(e)) {
      preventDefault(e);
      setIsInfoIconClicked(true);
    }
  }

  // handle return key presses on the Volume button
  const _handleKeyDownVolume = (e) => {
    stopPropagation(e);
    if (isSubmitKey(e)) {
      preventDefault(e);
      props.setShowVolumeSetting(true);
    }
  }

  // handle return key presses on the Randomise button
  const _handleKeyDownRandomise = (e) => {
    stopPropagation(e);
    if (isSubmitKey(e)) {
      preventDefault(e);
      getRandomTouchpad();
    }
  }

  // handle return key presses on fullscreen icon
  const _handleKeyDownFullScreen = (e) => {
    stopPropagation(e);
    if (isKeyIn(e, [KeyId.Enter, KeyId.SpaceBar])) {
      props.toggleFullScreen(e);
    }
  }

  return (
    <>
      <div className="icon-header">
        {props.isPlaying && props.hasStarted
          ? <div className={isIOS() ? "get-info-mobile" : "get-info"}
            id="btn-touchpad-exp-info"
            onClick={() => setIsInfoIconClicked(true)}
            tabIndex={getExperienceButtonTabIndex(props)}
            onKeyDown={(e) => _handleKeyDownInfo(e)}>
            <img
              src={info}
              //style={{ marginTop: "2px" }}
              alt={isInfoIconClicked ? "hide more info" : "show more info"}
              aria-label={isInfoIconClicked ? "hide more info" : "show more info"}
              role="button"
            />
          </div>
          : null
        }
        {!props.closeExperiencePopup
          ? <div className="close"
            id="btn-touchpad-exp-close"
            onClick={(e) => close(e)}
            tabIndex={getExperienceButtonTabIndex(props)}
            onKeyDown={(e) => _handleKeyDownCloseExit(e)}
          >
            <img
              src={cross}
              style={{ marginTop: "2px" }}
              alt={getText(TextId.endExperienceAriaLabel)}
              aria-label={getText(TextId.endExperienceAriaLabel)}
              role="button"
            />
            {!isMobile()
              ? <span>{getText(TextId.endExperienceText)}</span>
              : null
            }
          </div>
          : null
        }
      </div>
      <div style={{
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}>
        {showTouchpadPopup
          ? <TouchpadPopup
            touchpadId={touchpad}
            setShowTouchpadPopup={setShowTouchpadPopup}
            setIsInfoIconClicked={setIsInfoIconClicked}
          />
          : null
        }
        {props.showVolumeSetting
          ? <VolumeSetting
            setShowVolumeSetting={props.setShowVolumeSetting}
            volume={props.volume}
            zense={props.zense}
            subZense={props.subZense}
            binauralVolume={props.binauralVolume}
            musicVolume={props.musicVolume}
            naturescapeVolume={props.naturescapeVolume}
            audioBreathVolume={props.audioBreathVolume}
            setVolume={props.setVolume}
            setBinauralVolume={props.setBinauralVolume}
            setAudioBreathVolume={props.setAudioBreathVolume}
            setMusicVolume={props.setMusicVolume}
            setNaturescapeVolume={props.setNaturescapeVolume}
          />
          : null
        }
        <div className={props.isFullScreen || props.barHeight <= 0
          ? "trackplayer-fullscreen"
          : "trackplayer"}
        >
          <div className={isMobile() || !isShowExperienceButton(props)
            ? "track-controls-not-visible"
            : "track-controls-randomize"}
          >
            <img
              id="btn-touchpad-exp-volume"
              src={levels}
              height={60}
              width={60}
              className="play"
              onClick={() => props.setShowVolumeSetting(true)}
              tabIndex={getExperienceButtonTabIndex(props)}
              onKeyDown={(e) => _handleKeyDownVolume(e)}
              alt="volume"
              aria-label="volume"
              role="button"
            />
            <div className="timer">
              Volume
            </div>
          </div>
          <div className={!isShowExperienceButton(props)
            ? "track-controls-not-visible"
            : "track-controls-randomize"}>
            <img
              id="btn-touchpad-exp-randomize"
              src={randomise}
              height={60}
              width={60}
              className="play"
              onClick={(e) => getRandomTouchpad(e)}
              tabIndex={getExperienceButtonTabIndex(props)}
              onKeyDown={(e) => _handleKeyDownRandomise(e)}
              alt="Next random touchpad"
              aria-label="Next random touchpad"
              role="button"
            />
            <div className="timer">
              Shuffle
            </div>
          </div>
          <MultiTrackPlayer
            mood={props.mood}
            zense={props.zense}
            subZense={props.subZense}
            experience={props.experience}
            timeLimit={props.timeLimit}
            expired={props.expired}
            isPlaying={props.isPlaying}
            hasStarted={props.hasStarted}
            setHasStarted={props.setHasStarted}
            experienceDuration={props.experienceDuration}
            volume={props.volume}
            binauralVolume={props.binauralVolume}
            musicVolume={props.musicVolume}
            naturescapeVolume={props.naturescapeVolume}
            audioBreathVolume={props.audioBreathVolume}
            setBinauralVolume={props.setBinauralVolume}
            setAudioBreathVolume={props.setAudioBreathVolume}
            setMusicVolume={props.setMusicVolume}
            setNaturescapeVolume={props.setNaturescapeVolume}
            closeExperiencePopup={props.closeExperiencePopup}
            showVolumeSetting={props.showVolumeSetting}
            setVolume={props.setVolume}
            setIsPlaying={props.setIsPlaying}
            setExpired={props.setExpired}
            setExperienceDuration={props.setExperienceDuration}
            hasLoaded={props.hasLoaded}
            setHasLoaded={props.setHasLoaded}
            trackStartExperience={props.trackStartExperience}
          />
          <div className={!isShowExperienceButtonAux(props.isPlaying, props.hasLoaded, props.hasStarted, props.showVolumeSetting, props.closeExperiencePopup) || isIOS()
            ? "track-controls-not-visible"
            : "track-controls-randomize"}
          >
            <img
              id="btn-touchpad-exp-fullscreen"
              className="play"
              tabIndex={0}
              onClick={(e) => props.toggleFullScreen(e)}
              onKeyDown={(e) => _handleKeyDownFullScreen(e)}
              src={props.isFullScreen
                ? reduce_fullscreen
                : expand_fullscreen
              }
              alt={props.isFullScreen ? "Minimise full screen" : "Expand to full screen"}
            />
            <div className="timer">
              {props.isFullScreen
                ? "Minimize"
                : "Full Screen"
              }
            </div>
          </div>
        </div>
        {
          TouchpadLoader({
            ...props,
            touchpadId: touchpad
          })
        }
      </div>
    </>
  )
}

export default TouchpadExperience;