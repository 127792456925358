import React, { useEffect } from "react";
import "../styles/spinningDots.css";

const SpinningDots = () => {

    const url = "spinningDots.js"

    useEffect(() => {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
  
      document.body.appendChild(script);
      const startDiv = document.getElementById('startSpinningDots');
      startDiv.click();
      const stopDiv = document.getElementById('stopSpinningDots');
      
      return () => {
        stopDiv.click();
        document.body.removeChild(script);
      }
    }, [url]);
    
    return (
      <>
        <div id="stopSpinningDots"></div>
        <div id="startSpinningDots"></div>
        <canvas id="spinning-dots"></canvas>
      </>
    );
}

export default SpinningDots;