import { AudioObj, AudioPlayer } from "../API/AudioPlayerAPI";
import { getCurrentMillis, isNullOrEmpty } from "../Data/Util/util";
import { LOGGER, LogLevel } from "../Util/Logger";
import { BaseAudioPlayer } from "./BaseAudioPlayer";


export class HtmlAudioPlayer extends BaseAudioPlayer {

    constructor(
        audios: AudioObj[],
        onLoaded: Function,
        onError: Function
    ) {
        super(AudioPlayer.StandardHtml, audios, onLoaded, onError);
    }

    async getAudioObj(audioObj: AudioObj): Promise<any> {
        LOGGER.log(LogLevel.DEBUG, `${this.id} getAudioObj() type=${audioObj.type}. Adding loadeddata eventListener. uri=${audioObj.uri}`);
        
        const audio = new Audio(audioObj.uri);
        audioObj.audio = audio;
        audio.id = audioObj.type; // Note: set the ID to the type so we can get the correct volume 
        audio.loop = audioObj.isUnlimited;
        audio.addEventListener("loadeddata", () => {
            LOGGER.log(LogLevel.DEBUG, `${this.id} getAudioObj() loadeddata audioObj.type=${audioObj.type}, uri=${audioObj.uri}, loadTime=${getCurrentMillis() - this.loadStartAt}ms`);
            this.onAudioLoaded(audioObj);
        }, {
            once: true
        });

        return audioObj;
    }

    setVolume(volume: number): void {
        for (const audioObj of this.audios) {
            this.setVolumeForObj(audioObj, volume);
        }
    }

    setVolumeForObj(audioObj: AudioObj, volume: number): void {
        audioObj.audio.volume = (audioObj.volume * (volume / 100) ) / 100;
    }

    onAutoPlayStartedSuccess(audioObj: AudioObj) {
        if (audioObj.isLogSuccess) {
            LOGGER.log(LogLevel.DEBUG, `${this.id} onAutoPlayStartedSuccess() audioObj.type=${audioObj.type}`);
        }
    }

    onAutoPlayStartedPrevent(audioObj: AudioObj, error: any) {
        // try to set muted to make the audio play
        LOGGER.log(LogLevel.DEBUG, `${this.id} onAutoPlayStartedPrevent() Muting, volume and play audioObj.type=${audioObj.type}, error=${error}`);
        audioObj.audio.muted = true;
        const result = audioObj.audio.play();
        if (!isNullOrEmpty(result)) {
            result.then(() => {
                LOGGER.log(LogLevel.DEBUG, `${this.id} onAutoPlayStartedPrevent() 2nd play result.then() audioObj.type=${audioObj.type}`);
            }).catch((error: any) => {
                LOGGER.log(LogLevel.DEBUG, `${this.id} onAutoPlayStartedPrevent() 2nd play result.catch() audioObj.type=${audioObj.type}, error=${JSON.stringify(error)}`);
            });
        } else {
            LOGGER.log(LogLevel.WARN, `${this.id} onAutoPlayStartedPrevent() 2nd play result null/empty audioObj.type=${audioObj.type}, result=${result}`);
        }
    }

    playAudioObj(audioObj: AudioObj): void {
        if (this.isDestroyed) {
            return;
        }
        
        if (isNullOrEmpty(audioObj.audio)) {
            LOGGER.log(LogLevel.ERROR, `${this.id} playAudio() Null/empty audio in audioObj.type=${audioObj.type}`);
            return;
        }
        
        const promise = audioObj.audio.play();
        if (!isNullOrEmpty(promise)) {
            promise.then(() => {
                this.onAutoPlayStartedSuccess(audioObj);
            }).catch((error: any) => {
                // Autoplay was prevented.
                this.onAutoPlayStartedPrevent(audioObj, error);
            });
        } else {
            LOGGER.log(LogLevel.WARN, `${this.id} playAudio() Not calling promise.then() audioObj.type=${audioObj.type}, promise=${JSON.stringify(promise)}`);
        }
    }
    
}