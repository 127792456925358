import Cones from "../../containers/Cones";
import NeonBubbles from "../../containers/NeonBubbles";
import ParticleShapes from "../../containers/ParticleShapes";
import FluidAnimations from "../../containers/FluidAnimations"
import WigglySquiggly from "../../containers/Wiggly-Squiggly";
import { TouchpadId } from "../Data/constants";
import GreenFluid from "../../containers/GreenFluid";
import ColorMix from "../../containers/ColorMix";
import MoonDust from "../../containers/MoonDust";
import ExplosiveAttraction from "../../containers/ExplosiveAttraction";
import Orbit from "../../containers/Orbit";
import RacingStripes from "../../containers/RacingStripes";
import RainColor from "../../containers/RainColor";
import SpinningDots from "../../containers/SpinningDots";
import Muscular from "../../containers/Muscular";

// TODO: change loading to be in an API
export const TouchpadLoader = (props) => {

    return (
        props.touchpadId === TouchpadId.Smoke ? <FluidAnimations/>:
        props.touchpadId === TouchpadId.Cones ? <Cones props={props}></Cones>:
        props.touchpadId === TouchpadId.Bubbles ? <NeonBubbles/>:
        props.touchpadId === TouchpadId.LittleBubbles ? <ParticleShapes/>:
        props.touchpadId === TouchpadId.Squiggles ? <WigglySquiggly/>:
        props.touchpadId === TouchpadId.GreenFluid ? <GreenFluid/>:
        props.touchpadId === TouchpadId.ColorMix ? <ColorMix/>:
        props.touchpadId === TouchpadId.ExplosiveAttraction ? <ExplosiveAttraction/>:
        props.touchpadId === TouchpadId.MoonDust ? <MoonDust/>:
        props.touchpadId === TouchpadId.Orbit ? <Orbit/>:
        props.touchpadId === TouchpadId.RacingStripes ? <RacingStripes/>:
        props.touchpadId === TouchpadId.RainColor ? <RainColor/>:
        props.touchpadId === TouchpadId.SpinningDots ? <SpinningDots/>:
        props.touchpadId === TouchpadId.Muscular ? <Muscular/>:
        
        // TODO: add suppport for more touchpads
        // props === TouchpadId.PaintSpill ? <PaintSpill/>:
        // props === TouchpadId.Snakes ? <Snakes/>:
        <></>
    )

};