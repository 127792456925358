import React, { useEffect, useState } from "react";
import "../../styles/login.css";
import logo from "../../assets/icons/zensory_logo.svg";
import checkCircle from "../../assets/icons/check-circle.svg";
import { withScorm } from "react-scorm-provider";
import { ZensoryModule } from "../../ZensoryModule";
import { AnalyticsScreenName } from "../API/AnalyticsApi";
import { RouteId } from "../../Routes";
import { getText, TextId } from "../Util/TextHelper";
import { Interweave } from "interweave";
import { preventDefault, stopPropagation } from "../UI/KeyPressHelper";
import { getConfiguration } from "../Util/ConfigHelper";
import { getGlobalAppState } from "../AppState";
import moment from "moment";
import { Platform } from "../Data/constants";
import CancelSuccessPopup from "../CancelSuccessPopup";
import AuthErrorPopup from "../AuthErrorPopup";
import ConfirmCancelPopup from "../ConfirmCancelPopup";
import back from "../../assets/icons/arrow_back.svg"

const ManageSubscriptionPage = (props) => {
  const [licenses, setLicenses] = useState();
  const [cancelled, setCancelled] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  useEffect(() => {
    ZensoryModule.getAnalytics().trackScreen(
      AnalyticsScreenName.ScreenManageSubscription
    );
  }, []);

  useEffect(() => {
    async function userLicences() {
      try {
        const result = await ZensoryModule.getLicenses().getUserLicense(
          getGlobalAppState().getUser().userId
        );
        setLicenses(result);
      } catch (error) {
        ZensoryModule.getErrorHandler().handleError(error);
      }
    }

    userLicences();
  }, []);

  useEffect(() => {
    if (props.isLoggedIn) {
      ZensoryModule.getNavigationHandler().navigate(window, RouteId.Root);
    }
  }, [props.isLoggedIn]);

  // when the version text is clicked
  const onVersionClicked = async (e) => {
    preventDefault(e);
    stopPropagation(e);
    await ZensoryModule.getErrorHandler().sendProblemReport();
  };

  const backNagivate = async (e) => {
    preventDefault(e);
    ZensoryModule.getNavigationHandler().navigate(window, RouteId.Profile);
  };

  const confirmCancellation = ()=>{
    setShowConfirmationPopup(true);
  }

  const processCancel = async (license) => {
    if (license.platform !== Platform.Web) {
      setShowConfirmationPopup(false);
      setShowErrorPopup(true);
      setErrorMessage("Please cancel on relevant platform");
      
      if (license.platform === Platform.Android) {
        window.open("https://play.google.com/store/account/subscriptions", '_blank', 'noreferrer');
      }
      if (license.platform === Platform.Ios) {
        window.open("https://apps.apple.com/account/subscriptions", '_blank', 'noreferrer');
      }

      return;
    }

    let response;
    try {
      response = await ZensoryModule.getLicenses().cancelSubscription(
        getGlobalAppState().getUser().userId,
        license.stripeSubscriptionId
      );

    } catch (error) {
      setShowConfirmationPopup(false);
      ZensoryModule.getErrorHandler().handleError(error);
      setErrorMessage("Subscription already cancelled");
      setShowErrorPopup(true);
    }

    if (response) {
      ZensoryModule.getLicenses().onUserCancelled(
        getGlobalAppState().getUser().userId,
        Platform.Web,
        license.licenseId
      ).then((licensesCancelled) => {
        if (licensesCancelled) {
          setShowConfirmationPopup(false);
          setCancelled(true);
        }
      }).catch((error) => {
        ZensoryModule.getErrorHandler().handleError(error);
      });
    }
  };

  if (!licenses) return <h3 style={{ color: "white", fontFamily: "Poppins" }}>
  {getText(TextId.loadingText)}
  </h3>;

  return (
    <>
         {showConfirmationPopup 
      ? (
        <ConfirmCancelPopup
          license={licenses[0]}
          setShowAuthErrorPopup={setShowConfirmationPopup}
          processCancel={processCancel}
        />
        ) 
      : null}
      {showErrorPopup 
      ? (
        <AuthErrorPopup
          setShowAuthErrorPopup={setShowErrorPopup}
          errorMessage={errorMessage}
        />
        ) 
      : null}
      {cancelled 
      ? (
        <CancelSuccessPopup
          setShowAuthErrorPopup={setCancelled}
          successMessage={"Subscription cancelled. You will need to subscribe again to access The Zensory web app"}
        />
        ) 
      : null}
      <div className="header-row-profile">
      <div className="btn-back-profile" onClick={(e) => backNagivate(e)}>
        <img src={back} alt="Back button" height={22} />
          {getText(TextId.profileBackText)}
        </div>
      </div>
      <div className="subscribe-container">
        <div className="header-row-subscribe">
          <div className="logo-container-subscribe">
            <img className="subscribelogo" src={logo} alt="Zensory logo" />
          </div>
          <h1 className="subscribe-heading">
            <Interweave content={getText(TextId.manageTitle)} />
          </h1>
        </div>

        <div className="manage-content-box">
          <div className="manage-heading">
            <Interweave content={getText(TextId.activeSubscription)} />
          </div>

          <div className="ad-container">
            <div className="unlock-tag">
              <Interweave content={getText(TextId.premiumTag)} />
            </div>
            <div className="features-list">
              {licenses[0].license.featuresList.map((feature) => (
                <div className="feature-line">
                  <img src={checkCircle} alt="tick"></img>
                  <div className="feature">{feature}</div>
                </div>
              ))}
            </div>  
          </div>
          <div className="cancel-box">
            <div className="cancel-left">
            
              <h3>{getText(TextId.zensoryPremium)}</h3>
              {licenses[0]?.cancelledAt ?
                <h3 className="subscribe-tag">{getText(TextId.cancelledAtTag,{EXPIRESON: moment(licenses[0].expiresAt).format("DD MMM, YYYY")})}</h3>
              : <h3 className="subscribe-tag">{getText(TextId.subscribeOnTag,{SUBSCRIBEDON: moment(licenses[0].createdAt).format("DD MMM, YYYY")})}</h3>
              }
              <div onClick={()=>{confirmCancellation()}} className="cancel-tag">{getText(TextId.cancelLink)}</div>
            </div>
            <div className="cancel-right"></div>
          </div>

        </div>

        <div className="help-section">
          <Interweave content={getText(TextId.subscribeIssue)} />
        </div>

        <div
          className="web-version"
          id="version-login"
          onClick={(e) => onVersionClicked(e)}
        >
          <Interweave content={`Version: ${getConfiguration().version}`} />
        </div>
      </div>
    </>
  );
};

const ManageSubcription = withScorm()(ManageSubscriptionPage);
export default ManageSubcription;
