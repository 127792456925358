import { SenseId } from "./Data/constants";
import ExperienceTile from "./ExperienceTile";
import { TextId, getText } from "./Util/TextHelper";

export const PastExperiences = ({ exp }) => {
  let temp = [];

  //filter experiences from list if they are missing properties
  exp.forEach((item) => {
    const title =
      item?.experience?.experience?.data?.selectedHomeCard?.contentTitle;

    //select background image
    const backgroundImageUrl =
      item?.experience?.experience?.soundscape !== SenseId.NoNature &&
      item?.experience?.experience?.data?.senseData?.senseId !== SenseId.Breath
        ? item.experience?.experience.soundscape.backgroundImageUrl
        : item.experience?.experience.data.senseData &&
          item.experience?.experience.data.senseData.senseId === SenseId.Breath
        ? item?.experience?.experience.data.zenseData.breathingBackgroundImg
        : item?.experience?.experience?.data?.HomeCardBackground
        ? item.experience?.experience.data.HomeCardBackground
        : null;

    if (title && backgroundImageUrl) temp.push(item);
  });

  return (
    <>
      <h1 className="profile-box-heading">
        {getText(TextId.pastExperiencesTitle)}
      </h1>

      <div className="expTilesContainer">
        {temp.length > 0 //null check on filtered array
          ? temp.map((x) => <ExperienceTile item={x.experience} />)
          : //if no experiences are available
            getText(TextId.noExperiencesMessage)}
      </div>
    </>
  );
};
