import { AudioDurationSecs, ExperienceExpertId, ExperienceSource, MoodId, QuickStartExperienceId, SenseId, SoundscapeId, SubSenseId } from "../Data/constants";
import { UserActivity } from "../Data/Models/app";
import { Configuration } from "../Data/Models/configuration";
import { getCurrentMillis } from "../Data/Util/util";
import { getConfiguration } from "../Util/ConfigHelper";
import { ExperienceAnalyticsHolder } from "../Util/ExperienceAnalyticsHelper";

/**
 * This interface defines how to track Analytics
 */
export interface AnalyticsApi {

    identify(userId: string): Promise<void>;
    
    updateUserProps(props: { [key: string]: any }): Promise<void>;
    
    trackEvent(eventName: AnalyticEventName, props: { [key: string]: any }): Promise<void>;

    trackScreen(screenName: AnalyticsScreenName): Promise<void>;

    trackStartExperience(event: StartExperienceAnalyticsEvent): Promise<void>;

    trackEndExperience(event: EndExperienceAnalyticsEvent): Promise<void>;

    trackEndExperienceWithRating(event: EndExperienceWithRatingAnalyticsEvent): Promise<void>;

    trackUserActivity(userId: string, activity: UserActivity): Promise<any>;

    getSessionSummaryJson(): AnalyticsSummary;

    onUserAuthed(userId: string, email: string): Promise<void>;

}

export type AnalyticEventName = AnalyticMainEventName | AnalyticsScreenName;

export enum AnalyticMainEventName {
    StartExperience = "startExperience",
    EndExperience = "endExperience",
    EndExperienceWithRating = "endExperienceWithRating",
    UserStartSubscription = "userStartSubscription",
    OnAutoRedeemCode = "onAutoRedeemCode",
}

export enum AnalyticsScreenName {
    Login = "screenLogin",
    ScreenSignUp = "screenSignUp",
    ScreenChooseMood = "screenChooseMood",
    ScreenChooseSense = "screenChooseSense",
    ScreenCustomise = "screenCustomise",
    ScreenExperienceSummary = "screenExperienceSummary",
    ScreenExperience = "screenExperience",
    ScreenAfterRating = "screenAfterRating",
    ScreenProfile = "screenProfile",
    ForgotPasswordScreen = "screenForgotPassword",
    ScreenSubscribe = "screenSubscribe",
    ScreenManageSubscription = "screenManageSubscription",
}

export interface BaseAnalyticsEvent {
    name: AnalyticEventName;
    timestampMillis: number;
    props?: { [key: string]: any };
}

interface StartExperienceAnalyticsEventApi extends BaseAnalyticsEvent {
    moodId: MoodId;
    senseId: SenseId;
    audioDurationSecs: AudioDurationSecs;
    didLoop: boolean;
    beforeRating: number;
    breathExperience?: SubSenseId;
    source: ExperienceSource;

    goal: MoodId;
    soundscape?: SoundscapeId;
    durationSecs: AudioDurationSecs;
    audioLoadTimeSecs: number;
    quickStartExperienceId?: QuickStartExperienceId;
    experienceSenseId: SenseId;
    experienceExpertId?: ExperienceExpertId;
    hasIntention: boolean;
    affirmationsOn: boolean;
    fromNotification: boolean;
    notifId: string | null;
    isFocusSession: boolean;
    isWhitenoise: boolean;
    isOffline: boolean;
}

interface EndExperienceAnalyticsEventApi extends StartExperienceAnalyticsEventApi {
    moodId: MoodId;
    senseId: SenseId;
    audioDurationSecs: AudioDurationSecs;
    timeSpentSecs: number;
    timeSpentPercent: number;
    didLoop: boolean;
    beforeRating: number;
    breathExperience?: SubSenseId;
    naturescapeId?: SoundscapeId;
    source: ExperienceSource;

    goal: MoodId;
    soundscape?: SoundscapeId;
    durationSecs: AudioDurationSecs;
    audioLoadTimeSecs: number;
    quickStartExperienceId?: QuickStartExperienceId;
    experienceSenseId: SenseId;
    experienceExpertId?: ExperienceExpertId;
    hasIntention: boolean;
    affirmationsOn: boolean;
    fromNotification: boolean;
    notifId: string | null;
    isFocusSession: boolean;
    isWhitenoise: boolean;
    isOffline: boolean;

    numScreenTouches: number;
    didComplete: boolean;
    percentageTimeCompleted: number;
    didMuteAudio: boolean;
    changedBinauarlAudioLevel: boolean;
    changedMusicAudioLevel: boolean;
    changedSoundscapeAudioLevel: boolean;
    tookNotes: boolean;
    numTouchpadRandomise: number;
}

interface EndExperienceWithRatingAnalyticsEventApi extends EndExperienceAnalyticsEventApi {
    afterRating: number;
    ratingChange: number;
}

export class StartExperienceAnalyticsEvent implements StartExperienceAnalyticsEventApi {

    name: AnalyticMainEventName = AnalyticMainEventName.StartExperience;
    timestampMillis: number;
    moodId: MoodId;
    senseId: SenseId;
    audioDurationSecs: AudioDurationSecs;
    didLoop: boolean;
    beforeRating: number;
    breathExperience?: SubSenseId;
    naturescapeId?: SoundscapeId;
    source: ExperienceSource;
    props: { [key: string]: any }

    // all mobile app params
    goal: MoodId;
    soundscape?: SoundscapeId;
    durationSecs: AudioDurationSecs;
    audioLoadTimeSecs: number;
    quickStartExperienceId?: QuickStartExperienceId;
    experienceSenseId: SenseId;
    experienceExpertId?: ExperienceExpertId = undefined;
    hasIntention = false;
    affirmationsOn = false;
    fromNotification = false;
    notifId = null;
    isFocusSession = false;
    isWhitenoise = false;
    isOffline = false;

    zensoryDeployment: string;

    constructor(
        moodId: MoodId,
        senseId: SenseId,
        audioDurationSecs: AudioDurationSecs,
        didLoop: boolean,
        beforeRating: number,
        experienceAnalyticsHolder: ExperienceAnalyticsHolder,
        breathExperience?: SubSenseId,
        naturescapeId?: SoundscapeId) {
        this.timestampMillis = getCurrentMillis();
        this.moodId = moodId;
        this.senseId = senseId;
        this.audioDurationSecs = audioDurationSecs;
        this.didLoop = didLoop;
        this.beforeRating = beforeRating;
        this.breathExperience = breathExperience;
        this.naturescapeId = naturescapeId;
        this.source = experienceAnalyticsHolder.source;

        this.goal = moodId;
        this.soundscape = naturescapeId;
        this.durationSecs = audioDurationSecs;
        this.experienceSenseId = senseId;
        this.audioLoadTimeSecs = experienceAnalyticsHolder.audioLoadTimeSecs;

        this.zensoryDeployment = getConfiguration().deploymentType;

        this.props = this.toPlainObj();
    }

    /**
     * @returns this object as a plain JSON object (without the 'props' property)
     */
    toPlainObj(): { [key: string]: any } {
        const obj: { [key: string]: any } = Object.assign({}, this);
        delete obj.props;
        return obj;
    }
}

export class EndExperienceAnalyticsEvent implements EndExperienceAnalyticsEventApi {
    name: AnalyticMainEventName = AnalyticMainEventName.EndExperience;
    timestampMillis: number;
    moodId: MoodId;
    senseId: SenseId;
    audioDurationSecs: AudioDurationSecs;
    didLoop: boolean;
    beforeRating: number;
    breathExperience?: SubSenseId;
    naturescapeId?: SoundscapeId;
    source: ExperienceSource;

    // all mobile app params
    goal: MoodId;
    soundscape?: SoundscapeId;
    durationSecs: AudioDurationSecs;
    audioLoadTimeSecs: number;
    quickStartExperienceId?: QuickStartExperienceId;
    experienceSenseId: SenseId;
    experienceExpertId?: ExperienceExpertId = undefined;
    hasIntention = false;
    affirmationsOn = false;
    fromNotification = false;
    notifId = null;
    isFocusSession = false;
    isWhitenoise = false;
    isOffline = false;

    // additional from the Start Experience
    timeSpentSecs: number;
    timeSpentPercent: number;

    numScreenTouches: number;
    didComplete: boolean;
    percentageTimeCompleted: number;
    didMuteAudio: boolean;
    changedBinauarlAudioLevel: boolean;
    changedMusicAudioLevel: boolean;
    changedSoundscapeAudioLevel: boolean;
    tookNotes: boolean;
    numTouchpadRandomise: number;

    zensoryDeployment: string;

    props: { [key: string]: any };

    constructor(
        moodId: MoodId,
        senseId: SenseId,
        audioDurationSecs: AudioDurationSecs,
        didLoop: boolean,
        timeSpentSecs: number,
        beforeRating: number,
        experienceAnalyticsHolder: ExperienceAnalyticsHolder,
        breathExperience?: SubSenseId,
        naturescapeId?: SoundscapeId) {
        this.timestampMillis = getCurrentMillis();
        this.moodId = moodId;
        this.senseId = senseId;
        this.audioDurationSecs = audioDurationSecs;
        this.didLoop = didLoop;
        this.timeSpentSecs = timeSpentSecs;

        if (didLoop) {
            this.timeSpentPercent = Math.min(Math.round(100.0 * (timeSpentSecs / AudioDurationSecs.TWENTY_20_MINS)), 100);
        } else {
            this.timeSpentPercent = Math.round(100.0 * (timeSpentSecs / audioDurationSecs));
        }
        this.beforeRating = beforeRating;
        this.breathExperience = breathExperience;
        this.naturescapeId = naturescapeId;
        this.source = experienceAnalyticsHolder.source;

        this.goal = moodId;
        this.soundscape = naturescapeId;
        this.durationSecs = audioDurationSecs;
        this.experienceSenseId = senseId;
        this.audioLoadTimeSecs = experienceAnalyticsHolder.audioLoadTimeSecs;

        this.numScreenTouches = 0;
        this.percentageTimeCompleted = this.timeSpentPercent;
        this.didComplete = this.timeSpentPercent >= 100;

        this.didMuteAudio = experienceAnalyticsHolder.didMuteAudio;
        this.tookNotes = experienceAnalyticsHolder.tookNotes;
        this.changedBinauarlAudioLevel = experienceAnalyticsHolder.changedBinauarlAudioLevel;
        this.changedMusicAudioLevel = experienceAnalyticsHolder.changedMusicAudioLevel;
        this.changedSoundscapeAudioLevel = experienceAnalyticsHolder.changedSoundscapeAudioLevel;
        this.numTouchpadRandomise = experienceAnalyticsHolder.numTouchpadRandomise;

        this.zensoryDeployment = getConfiguration().deploymentType;
        
        this.props = this.toPlainObj();
    }

    /**
     * @returns this object as a plain JSON object (without the 'props' property)
     */
    toPlainObj(): { [key: string]: any } {
        const obj: { [key: string]: any } = Object.assign({}, this);
        delete obj.props;
        return obj;
    }
}

export class EndExperienceWithRatingAnalyticsEvent extends EndExperienceAnalyticsEvent implements EndExperienceWithRatingAnalyticsEventApi {
    name: AnalyticMainEventName = AnalyticMainEventName.EndExperienceWithRating;
    afterRating: number;
    ratingChange: number; // = afterRating - beforeRating

    rating: number;

    constructor(
        moodId: MoodId,
        senseId: SenseId,
        audioDurationSecs: AudioDurationSecs,
        didLoop: boolean,
        timeSpentSecs: number,
        beforeRating: number,
        endRating: number,
        experienceAnalyticsHolder: ExperienceAnalyticsHolder,
        breathExperience?: SubSenseId,
        naturescapeId?: SoundscapeId) {
        super(moodId, senseId, audioDurationSecs, didLoop, timeSpentSecs, beforeRating, experienceAnalyticsHolder, breathExperience, naturescapeId);
        this.afterRating = endRating;
        this.rating = endRating;
        this.ratingChange = endRating - beforeRating;

        this.props = this.toPlainObj();
    }

    /**
     * @returns this object as a plain JSON object (without the 'props' property)
     */
    toPlainObj(): { [key: string]: any } {
        const obj: { [key: string]: any } = Object.assign({}, this);
        obj['afterRating'] = this.afterRating;
        obj['ratingChange'] = this.ratingChange;
        delete obj.props;
        return obj;
    }
}

export interface AnalyticsSummary {
    customerId: string;
    version: string;
    timestampMillis: number;
    score: number;
    events: BaseAnalyticsEvent[];
}

export function getDefaultAnalyticsSummary(config: Configuration): AnalyticsSummary {
    return {
        customerId: config.customerId,
        version: config.version,
        timestampMillis: getCurrentMillis(),
        score: 0,
        events: []
    }
};

export function containsAnalyticsEvent(eventName: AnalyticEventName, events: BaseAnalyticsEvent[]): boolean {
    for (var event of events) {
        if (eventName === event.name) {
            return true;
        }
    }
    return false;
}

export function getAnalyticsEvent(eventName: AnalyticEventName, events: BaseAnalyticsEvent[]): BaseAnalyticsEvent | null {
    for (var event of events) {
        if (eventName === event.name) {
            return event;
        }
    }
    return null;
}
