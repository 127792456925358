import { FeatureUiApi } from "../../API/FeatureUiApi";
import { AuthProvider, CustomerId } from "../../Data/Models/configuration";
import { getConfiguration } from "../../Util/ConfigHelper";

export abstract class BaseFeatureUi implements FeatureUiApi {

    abstract isShowExitButton(): boolean;

    isShowAppDownloadConfig(): boolean {
        const endExperienceConfig = getConfiguration().endExperienceConfig;
        return endExperienceConfig.appDownloadConfig.isShow;
    }

    isShowRestartButton(): boolean {
        const config = getConfiguration();
        
        // hide restart button for Brigantia
        if (config.customerId === CustomerId.Brigantia) {
            return false;
        }

        const endExperienceConfig = config.endExperienceConfig;
        return !endExperienceConfig.isSingleExperience; //don't show for single experiences
    }

    isShowFinishButton(): boolean {
        const endExperienceConfig = getConfiguration().endExperienceConfig;
        return !endExperienceConfig.isSingleExperience; //don't show for single experiences
    }

    isShowLogoutButton(): boolean {
        return getConfiguration().authProvider === AuthProvider.Firebase;
    }
    
    isShowBackButton() : boolean {
        return !getConfiguration().isExperienceDeepLinkOnly;
    }

    isShowManageSubscription(): boolean {
        return !getConfiguration().subscriptionConfig?.isHideManageSubscription;
    }
}