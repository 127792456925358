import { AuthApi } from "../../API/AuthApi";

const USER_ID_NONE = "none";

export class NoAuthImpl implements AuthApi {

    getCurrentUser(): string | null {
        return USER_ID_NONE;
    }

    async getCurrentUserWithObserver(): Promise<string | null> {
        return USER_ID_NONE;
    }

    async hasUser(): Promise<boolean> {
        return true;
    }

    async login(): Promise<boolean> {
        return true;
    }

    async signup(): Promise<boolean> {
        return true;
    }

    async forgotPassword(): Promise<boolean> {
        return true;
    }

    async logout(): Promise<void> { }

    async signInWithCustomToken(token: string): Promise<boolean> {
        return true;
    }

    async onUserSignedUp(): Promise<boolean> {
        return true;
    }


}