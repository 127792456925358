import React, { useEffect, useState } from "react";
import "../../styles/login.css";
import logo from "../../assets/icons/zensory_logo.svg";
import eye from "../../assets/icons/eye-regular.svg";
import eye_slash from "../../assets/icons/eye-slash-regular.svg";
import { withScorm } from "react-scorm-provider";
import { ZensoryModule } from "../../ZensoryModule";
import { AnalyticsScreenName } from "../API/AnalyticsApi";
import { RouteId } from "../../Routes";
import { getText, TextId } from "../Util/TextHelper";
import { Interweave } from "interweave";
import { isKeyIn, preventDefault, stopPropagation } from "../UI/KeyPressHelper";
import { getConfiguration } from "../Util/ConfigHelper";
import {
  checkEmailFormat,
  checkPasswordFormat,
  isNullOrEmpty,
} from "../Data/Util/util";
import SpinnerLoader from "../SpinnerLoader";
import { KeyId } from "../UI/KeyEnum";
import AuthErrorPopup from "../AuthErrorPopup";
import { getConfigLink, isLinkId } from "../Data/constants";
import { isWorkEmail } from "../Util/AuthHelper";
import { LOGGER, LogLevel } from "../Util/Logger";

const LoginPage = (props) => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [emailErrorDialog, showEmailErrorDialog] = useState(false);
  const [passwordErrorDialog, showPasswordErrorDialog] = useState(false);
  const [emailErrorDescription, setEmailErrorDescription] = useState();
  const [passwordErrorDescription, setPasswordErrorDescription] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState();
  const [loading, setLoading] = useState(false);
  const config = getConfiguration();

  useEffect(() => {
    ZensoryModule.getAnalytics().trackScreen(AnalyticsScreenName.Login);
  }, []);

  useEffect(() => {
    if (props.isLoggedIn) {
      ZensoryModule.getNavigationHandler().navigate(window, RouteId.Root);
    }
  }, [props.isLoggedIn]);

  const onEmailChange = (text) => {
    if (!checkEmailFormat(text)) {
      showEmailErrorDialog(true);
      setEmailErrorDescription(getText(TextId.emailErrorDescription));
    } else {
      LOGGER.log(LogLevel.DEBUG, `config.isWorkEmailOnly=${config.isWorkEmailOnly}, isWorkEmailOnly=${config.isWorkEmailOnly}`);
      if (config.isWorkEmailOnly && !isWorkEmail(text)) {
        showEmailErrorDialog(true);
        setEmailErrorDescription(getText(TextId.emailNotWorkErrorDescription));
      } else {
        showEmailErrorDialog(false);
      }
    }
    setEmail(text);
  };

  const onPasswordChange = (text) => {
    if (!checkPasswordFormat(text)) {
      showPasswordErrorDialog(true);
      setPasswordErrorDescription(
        getText(TextId.requiredFieldErrorDescription)
      );
      setPassword("");
    } else {
      setPassword(text);
      showPasswordErrorDialog(false);
    }
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    preventDefault(e);
    const isLogin = await ZensoryModule.getAuth()
      .login(email, password)
      .catch((error) => {
        setLoginErrorMessage(error.message);
        setShowLoginPopup(true);
      });
    if (isLogin) {
      props.setIsLoggedIn(true);
    }
    setLoading(false);
  };

  const handleRouteLinkClick = (routeId) => {
    ZensoryModule.getNavigationHandler().navigate(window, routeId);
  };

  const handleLinkClick = (id) => {
    const configLink = getConfigLink(id, RouteId.SignUp);
    
    if (configLink === RouteId.SignUp) {
      handleRouteLinkClick(RouteId.SignUp);
      return;
    }

    window.open(configLink, isLinkId(id) ? "_blank" : null);
  };

  const transformText = (node, children) => {
    if (node.tagName.toLowerCase() === "a") {
      return (
        <a
          style={{
            textDecoration: "underline",
            cursor: "pointer",
            color: "white",
          }}
          onClick={(e) => {
            preventDefault(e);
            handleLinkClick(node.id);
          }}
          rel="noreferrer"
        >
          {children}
        </a>
      );
    }
  };

  // handle return key presses on the password visibility toggle
  const _handleKeydownPasswordVisibility = (e) => {
    stopPropagation(e);
    if (isKeyIn(e, [KeyId.Enter, KeyId.SpaceBar])) {
      setShowPassword(!showPassword);
    }
  };

  // when the version text is clicked
  const onVersionClicked = async (e) => {
    preventDefault(e);
    stopPropagation(e);
    await ZensoryModule.getErrorHandler().sendProblemReport();
  };

  return (
    <>
      <div className="login-container">
        {showLoginPopup ? (
          <AuthErrorPopup
            setShowAuthErrorPopup={setShowLoginPopup}
            errorMessage={loginErrorMessage}
          />
        ) : null}
        <div className="header-row-login">
          <div className="logo-container">
            <img className="logo" src={logo} alt="Zensory logo" />
          </div>
        </div>
        <div className="login-content-container">
          <h1 className="login-heading">
            <Interweave content={getText(TextId.loginTitle)} />
          </h1>
          <div className="welcome-subheading">
            <Interweave content={getText(TextId.loginSubtitle)} />
          </div>
          <form className="input-container" onSubmit={handleSubmit}>
            <div className="input-field">
              <div className="input-title"> Email </div>
              <input
                className=""
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required={true}
                value={email}
                onChange={(e) => onEmailChange(e.target.value)}
              />
            </div>

            {emailErrorDialog ? (
              <span className="text-danger">{emailErrorDescription}</span>
            ) : null}

            <div className="input-field">
              <div className="input-title"> Password </div>
              <input
                className=""
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                required={true}
                value={password}
                autoComplete="current-password"
                onChange={(e) => onPasswordChange(e.target.value)}
              />
              <img
                id="btn-show-password"
                className="password-toggle"
                alt={showPassword ? "Hide Password" : "Show Password"}
                onClick={() => setShowPassword(!showPassword)}
                tabIndex="0"
                src={showPassword ? eye_slash : eye}
                onKeyDown={(e) => _handleKeydownPasswordVisibility(e)}
              />
            </div>

            {passwordErrorDialog ? (
              <span className="text-danger">{passwordErrorDescription}</span>
            ) : null}

            <div className="forgot-password" tabIndex="0">
            <a
              onClick={(e) => {
                preventDefault(e);
                handleRouteLinkClick(RouteId.ForgotPassword)
              }}
              style={{textDecoration:"none", color:"white"}}
            >
              Forgot Password?
              </a>
            </div>

            <button
              disabled={
                passwordErrorDialog ||
                emailErrorDialog ||
                showLoginPopup ||
                isNullOrEmpty(email) ||
                isNullOrEmpty(password)
              }
              className="next-button"
              style={{ position: "relative" }}
            >
              {loading ? <SpinnerLoader /> : "Log In"}
            </button>
          </form>
          <div className="info-container">
            <div className="sign-up-text">
              <Interweave
                // TODO: show this text when we have the sign up page working
                content={getText(TextId.signUpText)}
                // content=""
                transform={transformText}
              />
            </div>
            <div className="terms-of-service">
              <Interweave
                content={getText(TextId.termsOfService)}
                transform={transformText}
              />
            </div>
          </div>
          <div
            className="web-version"
            id="version-login"
            onClick={(e) => onVersionClicked(e)}
          >
            <Interweave content={`Version: ${getConfiguration().version}`} />
          </div>
        </div>
      </div>
    </>
  );
};

const Login = withScorm()(LoginPage);
export default Login;
