import json from '../../../buildVersion.json';

export interface BuildVersion {
    major: number;
    minor: number;
    number: number;
    revision: number;
}

export function getBuildVersion(): BuildVersion {
    const buildVersion = {...json as BuildVersion}; 
    return buildVersion;
}

export function getBuildVersionString(): string {
    const buildVersion = getBuildVersion();
    return `${buildVersion.major}.${buildVersion.minor}.${buildVersion.number}.${buildVersion.revision}`;
}