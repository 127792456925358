
export const enum TabIndex {
    Default=0,
    Disabled=-1    
}

export function getTabIndex(isEnabled: boolean): TabIndex {
    if (isEnabled) {
        return TabIndex.Default;
    } else {
        return TabIndex.Disabled;
    }
}