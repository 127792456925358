import textBase from '../../textBase.json';
import textDeploymentType from '../../textDeploymentType.json';
import textPartner from '../../textPartner.json';
import { isNullOrEmpty } from '../Data/Util/util';
import { substituteStrings, SubstitutionMap } from './SubstitutionHelper';

/**
 * Defines the enum IDs for strings we want to display on the platform
 * 
 * Note: values for each enum must be defined as the same as the enum name (without a value, they get assigned an index integer)
 */
export enum TextId {
    showInformationAlt = "showInformationAlt",
    hideInformationAlt = "hideInformationAlt",
    backButtonAlt = "backButtonAlt",
    loginTitle = "loginTitle",
    loginSubtitle = "loginSubtitle",
    signupTitle = "signupTitle",
    signupSubtitle = "signupSubtitle",
    forgotPasswordtitle = "forgotPasswordtitle",
    forgotPasswordAdvisory = "forgotPasswordAdvisory",
    forgotPasswordSuccessMessage = "forgotPasswordSuccessMessage",
    emailErrorDescription = "emailErrorDescription",
    emailNotWorkErrorDescription = "emailNotWorkErrorDescription",
    nameErrorDescription = "nameErrorDescription",
    requiredFieldErrorDescription = "requiredFieldErrorDescription",
    passwordErrorDescription = "passwordErrorDescription",
    loginPopupHeading = "loginPopupHeading",
    logoutPopupHeading = "logoutPopupHeading",
    termsOfService = "termsOfService",
    signUpText = "signUpText",
    loginText = "loginText",
    forgotPasswordText = "forgotPasswordText",
    homeWelcomeTitleIncomplete = "homeWelcomeTitleIncomplete",
    homeWelcomeTitleComplete = "homeWelcomeTitleComplete",
    homeWelcomeExperiencePromptIncomplete = "homeWelcomeExperiencePromptIncomplete",
    homeWelcomeExperiencePromptComplete = "homeWelcomeExperiencePromptComplete",
    homeWelcomeSubtitle = "homeWelcomeSubtitle",
    homeWelcomeFocusBoxFrontHeading = "homeWelcomeFocusBoxFrontHeading",
    homeWelcomeRelaxBoxFrontHeading = "homeWelcomeRelaxBoxFrontHeading",
    homeWelcomeFocusBoxFrontSubHeading = "homeWelcomeFocusBoxFrontSubHeading",
    homeWelcomeRelaxBoxFrontSubHeading = "homeWelcomeRelaxBoxFrontSubHeading",
    homeWelcomeRelaxBoxBackText = "homeWelcomeRelaxBoxBackText",
    homeWelcomeFocusBoxBackText = "homeWelcomeFocusBoxBackText",
    homeWelcomeInfoText = "homeWelcomeInfoText",
    homeWelcomeLearnWhyPopupHeading = "homeWelcomeLearnWhyPopupHeading",
    homeWelcomeLearnWhyPopupTextFocus = "homeWelcomeLearnWhyPopupTextFocus",
    homeWelcomeLearnWhyPopupTextRelax = "homeWelcomeLearnWhyPopupTextRelax",
    closeAndExitPopupHeading = "closeAndExitPopupHeading",
    closeAndExitPopupText = "closeAndExitPopupText",
    closeExperiencePopupText = "closeExperiencePopupText",
    closeExperiencePopupButtonText = "closeExperiencePopupButtonText",
    chooseZenseTitle = "chooseZenseTitle",
    chooseZenseSubtitleFocus = "chooseZenseSubtitleFocus",
    chooseZenseSubtitleRelax = "chooseZenseSubtitleRelax",
    chooseZenseBreathBoxFrontHeading = "chooseZenseBreathBoxFrontHeading",
    chooseZenseSoundBoxFrontHeading = "chooseZenseSoundBoxFrontHeading",
    chooseZenseTouchBoxFrontHeading = "chooseZenseTouchBoxFrontHeading",
    chooseZenseBreathBoxFocusBackText = "chooseZenseBreathBoxFocusBackText",
    chooseZenseSoundBoxFocusBackText = "chooseZenseSoundBoxFocusBackText",
    chooseZenseTouchBoxFocusBackText = "chooseZenseTouchBoxFocusBackText",
    chooseZenseBreathBoxRelaxBackText = "chooseZenseBreathBoxRelaxBackText",
    chooseZenseSoundBoxRelaxBackText = "chooseZenseSoundBoxRelaxBackText",
    chooseZenseTouchBoxRelaxBackText = "chooseZenseTouchBoxRelaxBackText",
    chooseZenseTouchBoxWarningText = "chooseZenseTouchBoxWarningText",
    customizeExperienceSoundTitle = "customizeExperienceSoundTitle",
    customizeExperienceBreathTitle = "customizeExperienceBreathTitle",
    customizeExperienceBreathSubTitle = "customizeExperienceBreathSubTitle",
    customizeExperienceTouchTitle = "customizeExperienceTouchTitle",
    customizeExperienceTouchWarningText = "customizeExperienceTouchWarningText",
    customizeExperienceTimeLengthText = "customizeExperienceTimeLengthText",
    customizeBreathExperienceFocusVisualGuided = "customizeBreathExperienceFocusVisualGuided",
    customizeBreathExperienceRelaxVisualGuided = "customizeBreathExperienceRelaxVisualGuided",
    customizeBreathExperienceFocusAudioGuided = "customizeBreathExperienceFocusAudioGuided",
    customizeBreathExperienceRelaxAudioGuided = "customizeBreathExperienceRelaxAudioGuided",
    experienceSummaryTitle = "experienceSummaryTitle",
    experienceSummaryGoalText = "experienceSummaryGoalText",
    experienceSummaryZenseText = "experienceSummaryZenseText",
    experienceSummaryExperienceText = "experienceSummaryExperienceText",
    experienceSummaryTimeText = "experienceSummaryTimeText",
    experienceSummaryInstructionText = "experienceSummaryInstructionText",
    experienceSummaryInstructionAriaText = "experienceSummaryInstructionAriaText",
    experienceSummaryInstructionLearnWhy = "experienceSummaryInstructionLearnWhy",
    experienceSummaryButtonText = "experienceSummaryButtonText",
    reviewTitleComplete = "reviewTitleComplete",
    reviewTitleIncomplete = "reviewTitleIncomplete",
    reviewSubTitle1Focus = "reviewSubTitle1Focus",
    reviewSubTitle1Relax = "reviewSubTitle1Relax",
    reviewSubtitle2Complete = "reviewSubtitle2Complete",
    reviewSubtitle2Incomplete = "reviewSubtitle2Incomplete",
    reviewRestartCompleteButtonText = "reviewRestartCompleteButtonText",
    reviewRestartIncompleteButtonText = "reviewRestartIncompleteButtonText",
    reviewFinishCompleteButtonText = "reviewFinishCompleteButtonText",
    reviewFinishIncompleteButtonText = "reviewFinishIncompleteButtonText",
    reviewTooltipText = "reviewTooltipText",
    mainCloseAndExitText = "mainCloseAndExitText",
    mainCloseAndExitAriaLabel = "mainCloseAndExitAriaLabel",
    endExperienceText = "endExperienceText",
    endExperienceAriaLabel = "endExperienceAriaLabel",
    learnWhyBinauralBeatsFrequencies = "learnWhyBinauralBeatsFrequencies",
    learnWhyBinauralBeatsAudioDifferential = "learnWhyBinauralBeatsAudioDifferential",
    pageNotFoundTitle = "pageNotFoundTitle",
    pastExperiencesTitle = "pastExperienceTitle",
    noExperiencesMessage = "noExperiencesMessage",
    streakDaysMessage = "streakDaysMessage",
    remaningStreakDaysMessage = "remaningStreakDaysMessage",
    profileBackText = "profileBackText",
    loadingText = "loadingText",
    sensoryInsightsTitle = "sensoryInsightsTitle",
    weeklyStreakTitle = "weeklyStreakTitle",
    subscribeTitle = "subscribeTitle",
    subscribeText = "subscribeText",
    unlockTag = "unlockTag",
    subscribeSave = "subscribeSave",
    subscribeIssue = "subscribeIssue",
    subscribeLogout = "subscribeLogout",
    subscribeSuccess= "subscribeSuccess",
    subscribePayment="subscribePayment",
    zensoryPremium = "zensoryPremium",
    manageSubscription = "manageSubscription",
    manageTitle = "manageTitle",
    activeSubscription = "activeSubscription",
    premiumTag = "premiumTag",
    subscribeOnTag = "subscribeOnTag",
    cancelledAtTag = "cancelledAtTag",
    cancelLink = "cancelLink",




}

// The map type requiring all TextIds to be present
type TextIdFullMap = { [textId in TextId]: string };

// The partial map type with optional TextIds
type TextIdOverrideMap = { [textId in TextId]?: string };

/*
 * Store the text configs for
 * - Base: in 'src/textBase.json'
 * - DeploymentType: in DeploymentTypes/{type}/textDeploymentType.json 
 * - Partner: in Partners/{partner}/textPartner.json 
 */
const DEFAULT_TEXT: TextIdFullMap = textBase as TextIdFullMap;
const DEPLOYMENT_TYPE_TEXT: TextIdOverrideMap = textDeploymentType as TextIdOverrideMap;
const PARTNER_TEXT: TextIdOverrideMap = textPartner as TextIdOverrideMap;

/**
 * @param textId The TextId enum to get the value for
 * @returns the value for the TextId, will check the Partner override
 */
export function getText(textId: TextId, substitutionMap?: SubstitutionMap): string {
    // first check the Partner values
    const partnerVal = PARTNER_TEXT[textId];
    if (!isNullOrEmpty(partnerVal)) {
        // if the text is referencing another TextId, get that value
        if (partnerVal!! in TextId) {
            return getText(partnerVal!! as TextId, substitutionMap);
        } else {
            return substituteStrings(partnerVal!!, substitutionMap);
        }
    }

    // next check the Deployment Type values
    const deploymentTypeVal = DEPLOYMENT_TYPE_TEXT[textId];
    if (!isNullOrEmpty(deploymentTypeVal)) {
        // if the text is referencing another TextId, get that value
        if (deploymentTypeVal!! in TextId) {
            return getText(deploymentTypeVal!! as TextId, substitutionMap);
        } else {
            return substituteStrings(deploymentTypeVal!!, substitutionMap);
        }
    }

    // Else return the Default value
    const defVal = DEFAULT_TEXT[textId];
    // if the text is referencing another TextId, get that value
    if (defVal in TextId) {
        return getText(defVal!! as TextId, substitutionMap);
    } else {
        return substituteStrings(defVal, substitutionMap);
    }
}