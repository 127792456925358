import MultiTrackPlayer from "../components/MultiTrackPlayer";
import cross from "../assets/icons/close.svg"
import expand_fullscreen from "../assets/icons/expand_full_screen.svg"
import reduce_fullscreen from "../assets/icons/reduce_full_screen.svg"
import { isKeyIn, isSubmitKey, preventDefault, stopPropagation } from "../components/UI/KeyPressHelper";
import levels from "../assets/icons/levels@3x.png"
import AudioGuidedBreathwork from "../components/AudioGuidedBreathwork";
import { MoodId, SubSenseId } from "../components/Data/constants";
import VolumeSetting from "../components/VolumeSetting";
import { getText, TextId } from "../components/Util/TextHelper";
import VisualGuidedBoxBreath from "../components/VisualGuidedBoxBreath";
import VisualGuidedThreePartRelax from "../components/VisualGuidedThreePartRelax";
import { isIOS, isMobile } from "../components/Util/DeviceUtil";
import { getExperienceButtonTabIndex, isShowExperienceButton, isShowExperienceButtonAux } from "./ExperienceUIHelper";
import { KeyId } from "../components/UI/KeyEnum";

const BreathExperience = (props) => {

  const close = (e) => {
    preventDefault(e);
    props.setCloseExperiencePopup(true);
  }

  // handle return key presses on fullscreen icon
  const _handleKeyDownFullScreen = (e) => {
    stopPropagation(e);
    if (isKeyIn(e, [KeyId.Enter, KeyId.SpaceBar])) {
      props.toggleFullScreen(e);
    }
  }

  // handle return key presses on the close and exit button
  const _handleKeyDownCloseExit = (e) => {
    stopPropagation(e);
    if (isSubmitKey(e)) {
      preventDefault(e);
      close(e);
    }
  }

  // handle return key presses on the volume
  const _handleKeyDownVolume = (e) => {
    stopPropagation(e);
    if (isSubmitKey(e)) {
      preventDefault(e);
      props.setShowVolumeSetting(true);
    }
  }

  return (
    <>
      <div className="close-breath"
        id="btn-breath-exp-close"
        onClick={(e) => close(e)}
        tabIndex={getExperienceButtonTabIndex(props)}
        role="button"
        aria-label={getText(TextId.endExperienceAriaLabel)}
        onKeyDown={(e) => _handleKeyDownCloseExit(e)}>
        <img
          src={cross}
          style={{ marginTop: "2px" }}
          alt="Close icon" />
        {!isMobile()
          ? <span>{getText(TextId.endExperienceText)}</span>
          : null
        }
      </div>
      <div style={{ position: "absolute", display: "flex", justifyContent: "center" }}>
        {props.showVolumeSetting
          ? <VolumeSetting
            setShowVolumeSetting={props.setShowVolumeSetting}
            zense={props.zense}
            subZense={props.subZense}
            experience={props.experience}
            binauralVolume={props.binauralVolume}
            musicVolume={props.musicVolume}
            naturescapeVolume={props.naturescapeVolume}
            audioBreathVolume={props.audioBreathVolume}
            setBinauralVolume={props.setBinauralVolume}
            setAudioBreathVolume={props.setAudioBreathVolume}
            setMusicVolume={props.setMusicVolume}
            setNaturescapeVolume={props.setNaturescapeVolume}
            visualBreathTriggerVolume={props.visualBreathTriggerVolume}
            setVisualBreathTriggerVolume={props.setVisualBreathTriggerVolume}
          />
          : null
        }
        <div className={props.isFullScreen || props.barHeight <= 0
          ? "trackplayer-fullscreen"
          : "trackplayer"} 
        >
          <div className={isMobile() || !isShowExperienceButton(props)
            ? "track-controls-not-visible"
            : "track-controls-randomize"}
          >
            <img
              id="btn-breath-exp-volume"
              src={levels}
              height={60}
              width={60}
              className="play"
              onClick={() => props.setShowVolumeSetting(true)}
              tabIndex={getExperienceButtonTabIndex(props)}
              onKeyDown={(e) => _handleKeyDownVolume(e)}
              alt="Volume"
              aria-label="volume"
              role="button"
            />
            <div className="timer">
              Volume
            </div>
          </div>
          <MultiTrackPlayer
            mood={props.mood}
            zense={props.zense}
            subZense={props.subZense}
            experience={props.experience}
            timeLimit={props.timeLimit}
            volume={props.volume}
            expired={props.expired}
            isPlaying={props.isPlaying}
            hasStarted={props.hasStarted}
            binauralVolume={props.binauralVolume}
            musicVolume={props.musicVolume}
            naturescapeVolume={props.naturescapeVolume}
            audioBreathVolume={props.audioBreathVolume}
            setBinauralVolume={props.setBinauralVolume}
            setAudioBreathVolume={props.setAudioBreathVolume}
            setMusicVolume={props.setMusicVolume}
            setNaturescapeVolume={props.setNaturescapeVolume}
            visualBreathTriggerVolume={props.visualBreathTriggerVolume}
            setVisualBreathTriggerVolume={props.setVisualBreathTriggerVolume}
            setHasStarted={props.setHasStarted}
            experienceDuration={props.experienceDuration}
            showVolumeSetting={props.showVolumeSetting}
            closeExperiencePopup={props.closeExperiencePopup}
            setVolume={props.setVolume}
            setIsPlaying={props.setIsPlaying}
            setExpired={props.setExpired}
            setExperienceDuration={props.setExperienceDuration}
            hasLoaded={props.hasLoaded}
            setHasLoaded={props.setHasLoaded}
            hasLoadedVisualBreath={props.hasLoadedVisualBreath}
            trackStartExperience={props.trackStartExperience}
          />
          <div className={!isShowExperienceButtonAux(props.isPlaying, props.hasLoaded, props.hasStarted, props.showVolumeSetting, props.closeExperiencePopup) || isIOS()
            ? "track-controls-not-visible"
            : "track-controls-randomize"}
          >
            <img
              id="btn-breath-exp-fullscreen"
              className="play"
              tabIndex={0}
              onClick={(e) => props.toggleFullScreen(e)}
              onKeyDown={(e) => _handleKeyDownFullScreen(e)}
              src={props.isFullScreen
                ? reduce_fullscreen
                : expand_fullscreen
              }
              alt={props.isFullScreen ? "Minimise full screen" : "Expand to full screen"}
            />
            <div className="timer">
            {props.isFullScreen
                ? "Minimize"
                : "Full Screen"
            }
            </div>
          </div>
        </div>
        { props.mood === MoodId.Focused && props.subZense === SubSenseId.VisualGuidedBreath
          ? <VisualGuidedBoxBreath
            mood={props.mood}
            subZense={props.subZense}
            experience={props.experience}
            isPlaying={props.isPlaying}
            expired={props.expired}
            visualBreathTriggerVolume={props.visualBreathTriggerVolume}
            setVisualBreathTriggerVolume={props.setVisualBreathTriggerVolume}
            hasStarted={props.hasStarted}
            setHasStarted={props.setHasStarted}
            hasLoadedVisualBreath={props.hasLoadedVisualBreath}
            setHasLoadedVisualBreath={props.setHasLoadedVisualBreath}
          /> 
          : props.mood === MoodId.Relax && props.subZense === SubSenseId.VisualGuidedBreath
            ? <VisualGuidedThreePartRelax
                mood={props.mood}
                subZense={props.subZense}
                experience={props.experience}
                isPlaying={props.isPlaying}
                expired={props.expired}
                visualBreathTriggerVolume={props.visualBreathTriggerVolume}
                setVisualBreathTriggerVolume={props.setVisualBreathTriggerVolume}
                hasStarted={props.hasStarted}
                setHasStarted={props.setHasStarted}
                hasLoadedVisualBreath={props.hasLoadedVisualBreath}
                setHasLoadedVisualBreath={props.setHasLoadedVisualBreath}
              />
            : <AudioGuidedBreathwork
                mood={props.mood}
                zense={props.zense}
                subZense={props.subZense}
                experience={props.experience}
              />
        }
      </div>
    </>
  )
}

export default BreathExperience;