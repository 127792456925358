import { ExperienceExpert, ExperienceLoading, Expert } from "../Models/app";
import { AudioDurationSecs, ExperienceCustomiseOptionId, ExperienceExpertId, ExpertId, MenuOptionId, MoodId, SenseId } from "../constants";

export function getExperts(): {[expertId in ExpertId]: Expert} {
    const experts: {[expertId in ExpertId]: Expert} = {
        "AmandaKerr": {
            expertId: ExpertId.AmandaKerr,
            name: "Amanda Kerr",
            moods: [ MoodId.Creative, MoodId.Focused, MoodId.Positive, MoodId.Relax ],
            senseIds: [ SenseId.Tapping ],
            title: "ETP Tapping Expert",
            profileImageUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/experts%2FprofileImages%2FAmanda-Kerr-profile.jpg?alt=media&token=21a52886-ffe5-43d1-bd54-0997806a6f69"    
        }
    };

    return experts;
}

export function getExperienceExpert(moodId: MoodId): ExperienceExpert[] {
    const arr: ExperienceExpert[] = [];

    const loading: ExperienceLoading = {
        topTextHtml: "Take a deep breath and get ready to be taken on a journey.",
        bottomTextHtml: "Put on your headphones now for a fully immersive experience",
        bottomIconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2FexperienceLoading%2Fic_exp_loading_headphones.png?alt=media&token=d2cfbafc-00ed-44fc-b5c2-db726c83a62a",
        bottomNoAudioTextHtml: "No audio will play for this experience",
        bottomNoAudioIconUrl: "",
    };

    switch (moodId) {
        case MoodId.Creative:
            arr.push({
                experienceExpertId: ExperienceExpertId.AmandaKerrTappingCreative,
                expertId: "amandaKerr",
                senseId: SenseId.Tapping,
                title: "Tap into your Creative Self",
                subTitle: "Follow expert Amanda Kerr with her EFT tapping session for increasing creativity.",
                expertImageUrl: getExperts().AmandaKerr.profileImageUrl,
                durationSecs: AudioDurationSecs.TEN_10_MINS,
                videoUrl: null,
                audioUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/experts%2FexperienceExperts%2FThe%20Zensory%20-%20Creativity%20Tapping.mp3?alt=media&token=d49a9c2f-40c0-47d2-8408-b7a454b248d3",
                contentItem: {
                    contentId: "tappingPoints",
                    description: null,
                    title: "Tapping Points",
                    imageUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/experts%2FexperienceExpertContentItems%2Fimg_tapping_points.png?alt=media&token=12cd25df-87ce-4eec-885a-bee22589ad9b",
                    durationSecs: AudioDurationSecs.TEN_10_MINS
                },
                audioBackgroundImageUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/experts%2FexperienceExpertBackgrounds%2Famanda-kerr-tapping-background.jpg?alt=media&token=223a80fc-1bc1-4cc0-8d22-4722d9092491",
                customiseDefaults: getExpertCustomDefaults(),
                customiseShowOptions: getExpertCustomShowOptions(),
                menuOptions: getExpertMeuOptions(),
                loading: loading,
            });
            break;

        case MoodId.Focused:
            arr.push({
                experienceExpertId: ExperienceExpertId.AmandaKerrTappingFocus,
                expertId: "amandaKerr",
                senseId: SenseId.Tapping,
                title: "Tap into your Focus",
                subTitle: "Follow expert Amanda Kerr with her EFT tapping session for increasing focus.",
                expertImageUrl: getExperts().AmandaKerr.profileImageUrl,
                durationSecs: AudioDurationSecs.TEN_10_MINS,
                videoUrl: null,
                audioUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/experts%2FexperienceExperts%2FThe%20Zensory%20-%20Focus%20Tapping.mp3?alt=media&token=0dcd7f76-6dbf-4cb7-8c7c-4a3bde71de8e",
                contentItem: {
                    contentId: "tappingPoints",
                    description: null,
                    title: "Tapping Points",
                    imageUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/experts%2FexperienceExpertContentItems%2Fimg_tapping_points.png?alt=media&token=12cd25df-87ce-4eec-885a-bee22589ad9b",
                    durationSecs: AudioDurationSecs.TEN_10_MINS
                },
                audioBackgroundImageUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/experts%2FexperienceExpertBackgrounds%2Famanda-kerr-tapping-background.jpg?alt=media&token=223a80fc-1bc1-4cc0-8d22-4722d9092491",
                customiseDefaults: getExpertCustomDefaults(),
                customiseShowOptions: getExpertCustomShowOptions(),
                menuOptions: getExpertMeuOptions(),
                loading: loading,
            });
            break;

        case MoodId.Positive:
            arr.push({
                experienceExpertId: ExperienceExpertId.AmandaKerrTappingPositive,
                expertId: "amandaKerr",
                senseId: SenseId.Tapping,
                title: "Tap into Feeling Good",
                subTitle: "Follow expert Amanda Kerr with her EFT tapping session for increasing positivity.",
                expertImageUrl: getExperts().AmandaKerr.profileImageUrl,
                durationSecs: AudioDurationSecs.TEN_10_MINS,
                videoUrl: null,
                audioUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/experts%2FexperienceExperts%2FThe%20Zensory%20-%20Positivity%20Tapping.mp3?alt=media&token=3590b985-d483-4f82-8b69-32fa03f97c4c",
                contentItem: {
                    contentId: "tappingPoints",
                    description: null,
                    title: "Tapping Points",
                    imageUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/experts%2FexperienceExpertContentItems%2Fimg_tapping_points.png?alt=media&token=12cd25df-87ce-4eec-885a-bee22589ad9b",
                    durationSecs: AudioDurationSecs.TEN_10_MINS
                },
                audioBackgroundImageUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/experts%2FexperienceExpertBackgrounds%2Famanda-kerr-tapping-background.jpg?alt=media&token=223a80fc-1bc1-4cc0-8d22-4722d9092491",
                customiseDefaults: getExpertCustomDefaults(),
                customiseShowOptions: getExpertCustomShowOptions(),
                menuOptions: getExpertMeuOptions(),
                loading: loading,
            });
            break;

        case MoodId.Relax:
            arr.push({
                experienceExpertId: ExperienceExpertId.AmandaKerrTappingRelax,
                expertId: "amandaKerr",
                senseId: SenseId.Tapping,
                title: "Tap into Relaxation",
                subTitle: "Follow expert Amanda Kerr with her EFT tapping session for increasing relaxation.",
                expertImageUrl: getExperts().AmandaKerr.profileImageUrl,
                durationSecs: AudioDurationSecs.TEN_10_MINS,
                videoUrl: null,
                audioUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/experts%2FexperienceExperts%2FThe%20Zensory%20-%20Relaxation%20Tapping.mp3?alt=media&token=f4184780-9e15-45dc-995c-c5c883af3639",
                contentItem: {
                    contentId: "tappingPoints",
                    description: null,
                    title: "Tapping Points",
                    imageUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/experts%2FexperienceExpertContentItems%2Fimg_tapping_points.png?alt=media&token=12cd25df-87ce-4eec-885a-bee22589ad9b",
                    durationSecs: AudioDurationSecs.TEN_10_MINS
                },
                audioBackgroundImageUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/experts%2FexperienceExpertBackgrounds%2Famanda-kerr-tapping-background.jpg?alt=media&token=223a80fc-1bc1-4cc0-8d22-4722d9092491",
                customiseDefaults: getExpertCustomDefaults(),
                customiseShowOptions: getExpertCustomShowOptions(),
                menuOptions: getExpertMeuOptions(),
                loading: loading,
            });
            break;
    }

    return arr;
}

function getExpertCustomDefaults(): {[optionId in ExperienceCustomiseOptionId]: any} {
    return {
        'affirmations': false,
        'sound': true,
        'intentions': "",
        'soundscapeId': null,
        'videoAndAudio': false,
        levels: null,
    };
}

function getExpertCustomShowOptions(): {[optionId in ExperienceCustomiseOptionId]: any} {
    return {
        affirmations: false,
        sound: false,
        intentions: true,
        soundscapeId: false,
        videoAndAudio: false,
        levels: null,
    };
}

function getExpertMeuOptions(): {[menuOptionId in MenuOptionId]: boolean} {
    return {
        muteUnmute: false,
        playPause: false,
        levels: false,
        touchpads: false,
        notes: false,
        randomiseTouchpad: false,
    };
}