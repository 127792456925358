import { LicenseApi } from "../../API/LicenseApi";
import { License, UserLicense } from "../../Data/Models/app";
import { isUseLicenses } from "../../Data/Models/configuration";
import { Platform } from "../../Data/constants";
import { CancelSubscriptionResponse, CompleteSubscriptionResponse } from "./FirebaseLicensesImpl";

export class NoLicensesImpl implements LicenseApi
{
  getStripeCoupon(): Promise<any[]> {
    throw new Error("Method not implemented.");
  }
  async cancelSubscription(): Promise<CancelSubscriptionResponse>
  {
    if (isUseLicenses()) {
      throw new Error("useLicenses not supported in Local deployment");
    } else {
      return {
        result: {
          subscriptionId:""
        }
      };
    }
  }

  async onUserCancelled(): Promise<UserLicense[]>
  {
    if (isUseLicenses()) {
      throw new Error("useLicenses not supported in Local deployment");
    } else {
      return [];
    }
  }

  async completeSubscription(): Promise<CompleteSubscriptionResponse>
  {
    if (isUseLicenses()) {
      throw new Error("useLicenses not supported in Local deployment");
    } else {
      return {
        result: {
          clientSecret:"",
          subscriptionId:""
        }
      };
    }  
  }

  async onUserSubsrcibed(): Promise<UserLicense[]>
  {
    if (isUseLicenses()) {
      throw new Error("useLicenses not supported in Local deployment");
    } else {
      return [];
    }
  }
  
  async getAvailableLicenses(): Promise<License[]>
  {
    if (isUseLicenses()) {
      throw new Error("useLicenses not supported in Local deployment");
    } else {
      return [];
    }
  }

  async getUserLicense(): Promise<UserLicense[]>
  {
    if (isUseLicenses()) {
      throw new Error("useLicenses not supported in Local deployment");
    } else {
      return [];
    }
  }

  redeemCode(
    userId: string,
    redeemCode: string,
    platform: Platform
  ): Promise<UserLicense[]> {
    return Promise.resolve([]);  
  }

}