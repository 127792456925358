import { User } from "./Data/Models/app";
import { AudioDurationSecs, ExperienceId, MoodId, SenseId, SoundscapeId, SubSenseId } from "./Data/constants";
import { ExperienceAnalyticsHolder, getExperienceAnalyticsHolderDefault } from "./Util/ExperienceAnalyticsHelper";
import { LOGGER, LogLevel } from "./Util/Logger";

type StateBooleanFunction = (state: boolean) => void;
type StateEnumFunction<T> = (state?: T | undefined) => void;
type StateObjectFunction<T> = (state?: T | undefined) => void;
type StateNumberFunction = (state?: number) => void;
type GetStateEnumFunction<T> = () => T | undefined;
type GetStateObjectFunction<T> = () => T;
type GetStateBooleanFunction = () => boolean | undefined;

export interface AppState {
    setStart: StateBooleanFunction;
    setRestart: StateBooleanFunction;
    setChooseZense: StateBooleanFunction;
    setChooseNaturescape: StateBooleanFunction;
    setChooseBreathwork: StateBooleanFunction;
    setChooseTouchpad: StateBooleanFunction;
    setExperienceSummary: StateBooleanFunction;
    setReview: StateBooleanFunction;
    setExpired: StateBooleanFunction;
    setAfterExperience: StateBooleanFunction;

    setZense: StateEnumFunction<SenseId>;
    setSubZense: StateEnumFunction<SubSenseId>;
    setMood: StateEnumFunction<MoodId>;
    getMood: GetStateEnumFunction<MoodId>;
    setExperience: StateEnumFunction<ExperienceId>;

    setIsDeepLink: StateBooleanFunction;
    getIsDeepLink: GetStateBooleanFunction;

    setUser: StateObjectFunction<User>;
    getUser: GetStateObjectFunction<User | null>;

    setBeforeRating: StateNumberFunction;
    setAfterRating: StateNumberFunction;
    setExperienceDuration: StateNumberFunction;

    setExperienceAnalyticsHolder: StateObjectFunction<ExperienceAnalyticsHolder>;
    getExperienceAnalyticsHolder: GetStateObjectFunction<ExperienceAnalyticsHolder>;

    setTimeLimit: StateNumberFunction;
    setIsLoggedIn: StateBooleanFunction;
    
    isSigningUp: GetStateBooleanFunction;
    setIsSigningUp: StateBooleanFunction;

    setSignupPage: StateBooleanFunction;
    setLoginPage: StateBooleanFunction;
    setProfilePage: StateBooleanFunction;
    setForgotPasswordPage: StateBooleanFunction;
}

export let GlobalAppState: AppState;

export function getGlobalAppState(): AppState {
    return GlobalAppState;
}

export function setGlobalAppState(appState: AppState) {
    GlobalAppState = appState;
}

/**
 * Reset the state to go back to the start with default values
 * 
 * @param appState the AppState with functions to call
 */
export function setAppStateToStart(appState: AppState) {
    LOGGER.log(LogLevel.WARN, `setAppStateToStart`);
    appState.setReview(false);
    appState.setExpired(false);
    appState.setAfterExperience(false);
    appState.setExperienceSummary(false);
    appState.setMood();
    appState.setZense();
    appState.setSubZense();
    appState.setExperience(SoundscapeId.NoNature);
    appState.setTimeLimit(AudioDurationSecs.THREE_3_MINS);
    appState.setBeforeRating(0);
    appState.setAfterRating(undefined);
    appState.setExperienceAnalyticsHolder(getExperienceAnalyticsHolderDefault());
}

/**
 * Restart the Experience that just finished
 * 
 * @param appState the AppState with functions to call
 * @param afterRating the users after rating
 */
export function restartExperience(appState: AppState) {
    appState.setStart(true);
    appState.setExpired(false);
    appState.setReview(false);
    appState.setExperienceSummary(false);
    appState.setAfterExperience(false);
    appState.setBeforeRating(0); // always ensure the beforeRating is 0
    appState.setAfterRating();
    appState.setRestart(true);
}