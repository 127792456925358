import React, { useEffect, useState } from "react";
import "../../styles/login.css";
import logo from "../../assets/icons/zensory_logo.svg";
import unlocked from "../../assets/images/unlocked.svg";

import checkCircle from "../../assets/icons/check-circle.svg";

import { withScorm } from "react-scorm-provider";
import { ZensoryModule } from "../../ZensoryModule";
import { AnalyticsScreenName } from "../API/AnalyticsApi";
import { RouteId } from "../../Routes";
import { getText, TextId } from "../Util/TextHelper";
import { Interweave } from "interweave";
import { preventDefault, stopPropagation } from "../UI/KeyPressHelper";
import { getConfiguration } from "../Util/ConfigHelper";
import { getGlobalAppState } from "../AppState";
import PaymentPopup from "../PaymentPopup";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import getSymbolFromCurrency from 'currency-symbol-map'
import { EnvId } from "../Data/constants";
import AuthErrorPopup from "../AuthErrorPopup";

const stripePromise = loadStripe(
getConfiguration().envId===EnvId.PROD?"pk_live_51NQ6ffCp7TFC1JTAtvoYd1Hn4zUwmW0Z6A0cBVYuK3Et432QGTVwb8U6OcjP6bCrD601OvNG7dDd8TOAD1Glqfoz00UlEPeKMg": "pk_test_51NQ6ffCp7TFC1JTANEI48STyAvkXaYYwp00fcTmbdLNOusRCCp3E9eRO7aKms7LLCbIPa86jOnALhk6boqy1gYYR00nulVEIC6"
); // starts with pk_

const SubscribePage = (props) => {
  const [licenses, setLicenses] = useState();
  const [selectedPlan, setSelectedPlan] = useState();
  const [selectedZenLicense, setSelectedZenLicense] = useState();

  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [logoutErrorMessage, setLogoutErrorMessage] = useState();

  const [showPaymentPopup, setShowPaymentPopup] = useState(false);

  useEffect(() => {
    ZensoryModule.getAnalytics().trackScreen(
      AnalyticsScreenName.ScreenSubscribe
    );
  }, []);

  useEffect(() => {
    async function availableLicences() {
      try {
        const result = await ZensoryModule.getLicenses().getAvailableLicenses(
          getGlobalAppState().getUser().userId
        );
        setLicenses(result);
      } catch (error) {
        ZensoryModule.getErrorHandler().handleError(error);
      }
    }

    availableLicences();
  }, []);

  useEffect(() => {
    if (props.isLoggedIn) {
      ZensoryModule.getNavigationHandler().navigate(window, RouteId.Root);
    }
  }, [props.isLoggedIn]);

  const onLogoutClicked = async (e) => {
    preventDefault(e);
    await ZensoryModule.getAuth()
      .logout()
      .then(() => {
        ZensoryModule.getNavigationHandler().navigate(window, RouteId.Login);
      })
      .catch((error) => {
        setLogoutErrorMessage(error.message);
        setShowLogoutPopup(true);
      });
  };

  // when the version text is clicked
  const onVersionClicked = async (e) => {
    preventDefault(e);
    stopPropagation(e);
    await ZensoryModule.getErrorHandler().sendProblemReport();
  };

  const processBuy = (price, zenLicense) => {
    setSelectedPlan(price);
    setSelectedZenLicense(zenLicense);
    setShowPaymentPopup(true);
  };

  if (!licenses) return <h3 style={{ color: "white", fontFamily: "Poppins" }}>
  {getText(TextId.loadingText)}
</h3>;

  return (
    <>
      {showLogoutPopup 
        ? (
          <AuthErrorPopup
            setShowAuthErrorPopup={setShowLogoutPopup}
            errorMessage={logoutErrorMessage}
          />
        )
        : null
      }

      {showPaymentPopup ? (
        <Elements stripe={stripePromise}>
          <PaymentPopup
            setShowPaymentPopup={setShowPaymentPopup}
            plan={selectedPlan}
            selectedZenLicense={selectedZenLicense}
          />
        </Elements>
      ) : null}

      <div className="subscribe-container">
        <div className="header-row-subscribe">
          <div className="logo-container-subscribe">
            <img className="subscribelogo" src={logo} alt="Zensory logo" />
          </div>
          <h1 className="subscribe-heading">
            <Interweave content={getText(TextId.subscribeTitle)} />
          </h1>
        </div>

        <div className="subscribe-content-box">
          <img src={unlocked} width={50} alt="Lock" />

          <Interweave content={getText(TextId.subscribeText)} />
          <div className="ad-container">
            <div className="unlock-tag">
              <Interweave content={getText(TextId.unlockTag)} />
            </div>
            <div className="features-list">
              {licenses[0].featuresList.map((feature) => (
                <div className="feature-line">
                  <img src={checkCircle} alt="tick"></img>
                  <div className="feature">{feature}</div>
                </div>
              ))}
            </div>
          </div>
          <Interweave content={getText(TextId.subscribeSave)} />

          <div className="buy-buttons-container">
            {licenses.map((license) => {
              if (license.stripeProductId) {
                const thisPrice = license.prices.find(
                  (x) => x.id === license.stripePriceId
                );
                return (
                  <div
                    className="buy-button"
                    onClick={() => processBuy(thisPrice, license.licenseId)}
                  >
            
                    <span>
                      {getSymbolFromCurrency(thisPrice.currency)}
                      {thisPrice.unit_amount / 100}/
                      {thisPrice.recurring.interval}
                    </span>
                  </div>
                );
              } else return null;
            })}
          </div>
        </div>

        <div 
          className="help-section"
          onClick={(e) => onLogoutClicked(e)}
        >
          <Interweave content={`<u>${getText(TextId.subscribeLogout)}<u>`} />
        </div>

        <div className="help-section">
          <Interweave content={getText(TextId.subscribeIssue)} />
        </div>

        <div
          className="web-version"
          id="version-login"
          onClick={(e) => onVersionClicked(e)}
        >
          <Interweave content={`Version: ${getConfiguration().version}`} />
        </div>
      </div>
    </>
  );
};

const Subscribe = withScorm()(SubscribePage);
export default Subscribe;
