import { ScreenOrientation, SoundscapeId } from "../Data/constants"

// Landscape
import noNatureLandscape from "../../assets/images/naturescapeBackground/no_nature_headphones_background_landscape.jpg"
import forestLandscape from  "../../assets/images/naturescapeBackground/forest_background_landscape.jpg"
import beachLandscape from  "../../assets/images/naturescapeBackground/beach_background_landscape.jpg"
import gardenLandscape from  "../../assets/images/naturescapeBackground/garden_background_landscape.jpg"
import fireplaceLandscape from  "../../assets/images/naturescapeBackground/fireplace_background_landscape.jpg"
import meadowLandscape from  "../../assets/images/naturescapeBackground/meadow_background_landscape.jpg"
import rainLandscape from  "../../assets/images/naturescapeBackground/rain_background_landscape.jpg"
import tropicalBeachLandscape from  "../../assets/images/naturescapeBackground/tropicalBeach_background_landscape.jpg"
import rainforestLandscape from  "../../assets/images/naturescapeBackground/rainforest_background_landscape.jpg"
import whitenoiseLandscape from  "../../assets/images/naturescapeBackground/whitenoise_background_landscape.jpg"
import brownnoiseLandscape from  "../../assets/images/naturescapeBackground/naturescape_brownnoise_background_landscape.jpg"
import babblingBrookLandscape from  "../../assets/images/naturescapeBackground/naturescape_babbling_brook_background_landscape.jpg"
import desertLandscape from  "../../assets/images/naturescapeBackground/naturescape_desert_background_landscape.jpg"
import lakeAtNightLandscape from  "../../assets/images/naturescapeBackground/naturescape_lake_at_night_background_landscape.jpg"
import mountainLandscape from  "../../assets/images/naturescapeBackground/naturescape_mountain_background_landscape.jpg"
import oceanLandscape from  "../../assets/images/naturescapeBackground/naturescape_ocean_background_landscape.jpg"
import trainLandscape from  "../../assets/images/naturescapeBackground/naturescape_train_background_landscape.jpg"

// Landscape Low Res
import noNatureLandscapeLowRes from "../../assets/images/naturescapeBackground/no_nature_headphones_background_landscape_low_res.jpg"
import forestLandscapeLowRes from  "../../assets/images/naturescapeBackground/forest_background_landscape_low_res.jpg" 
import beachLandscapeLowRes from  "../../assets/images/naturescapeBackground/beach_background_landscape_low_res.jpg"
import gardenLandscapeLowRes from  "../../assets/images/naturescapeBackground/garden_background_landscape_low_res.jpg"
import fireplaceLandscapeLowRes from  "../../assets/images/naturescapeBackground/fireplace_background_landscape_low_res.jpg"
import meadowLandscapeLowRes from  "../../assets/images/naturescapeBackground/meadow_background_landscape_low_res.jpg"
import rainLandscapeLowRes from  "../../assets/images/naturescapeBackground/rain_background_landscape_low_res.jpg"
import tropicalBeachLandscapeLowRes from  "../../assets/images/naturescapeBackground/tropicalbeach_background_landscape_low_res.jpg"
import rainforestLandscapeLowRes from  "../../assets/images/naturescapeBackground/rainforest_background_landscape_low_res.jpg"
import whitenoiseLandscapeLowRes from  "../../assets/images/naturescapeBackground/whitenoise_background_landscape_low_res.jpg"
import brownnoiseLandscapeLowRes from  "../../assets/images/naturescapeBackground/naturescape_brownnoise_background_landscape_lowres.jpg"
import babblingBrookLandscapeLowRes from  "../../assets/images/naturescapeBackground/naturescape_babbling_brook_background_landscape_lowres.jpg"
import desertLandscapeLowRes from  "../../assets/images/naturescapeBackground/naturescape_desert_background_landscape_lowres.jpg"
import lakeAtNightLandscapeLowRes from  "../../assets/images/naturescapeBackground/naturescape_lake_at_night_background_landscape_lowres.jpg"
import mountainLandscapeLowRes from  "../../assets/images/naturescapeBackground/naturescape_mountain_background_landscape_lowres.jpg"
import oceanLandscapeLowRes from  "../../assets/images/naturescapeBackground/naturescape_ocean_background_landscape_lowres.jpg"
import trainLandscapeLowRes from  "../../assets/images/naturescapeBackground/naturescape_train_background_landscape_lowres.jpg"

// Portrait
import noNaturePortrait from "../../assets/images/naturescapeBackground/no_nature_headphones_background_portrait.jpg"
import forestPortrait from  "../../assets/images/naturescapeBackground/forest_background_portrait.jpg"
import beachPortrait from  "../../assets/images/naturescapeBackground/beach_background_portrait.jpg"
import gardenPortrait from  "../../assets/images/naturescapeBackground/garden_background_portrait.jpg"
import fireplacePortrait from  "../../assets/images/naturescapeBackground/fireplace_background_portrait.jpg"
import meadowPortrait from  "../../assets/images/naturescapeBackground/meadow_background_portrait.jpg"
import rainPortrait from  "../../assets/images/naturescapeBackground/rain_background_portrait.jpg"
import tropicalBeachPortrait from  "../../assets/images/naturescapeBackground/tropical_beach_background_portrait.jpg"
import rainforestPortrait from  "../../assets/images/naturescapeBackground/rainforest_background_portrait.jpg"
import whitenoisePortrait from  "../../assets/images/naturescapeBackground/whitenoise_background_portrait.jpg"
import brownnoisePortrait from  "../../assets/images/naturescapeBackground/naturescape_brownnoise_background.jpg"
import babblingBrookPortrait from  "../../assets/images/naturescapeBackground/naturescape_babbling_brook_background.jpg"
import desertPortrait from  "../../assets/images/naturescapeBackground/naturescape_desert_background.jpg"
import lakeAtNightPortrait from  "../../assets/images/naturescapeBackground/naturescape_lake_at_night_background.jpg"
import mountainPortrait from  "../../assets/images/naturescapeBackground/naturescape_mountain_background.jpg"
import oceanPortrait from  "../../assets/images/naturescapeBackground/naturescape_ocean_background.jpg"
import trainPortrait from  "../../assets/images/naturescapeBackground/naturescape_train_background.jpg"

// Portrait Low Res
import noNaturePortraitLowRes from "../../assets/images/naturescapeBackground/no_nature_headphones_background_portrait_low_res.jpg"
import forestPortraitLowRes from  "../../assets/images/naturescapeBackground/forest_background_portrait_low_res.jpg"
import beachPortraitLowRes from  "../../assets/images/naturescapeBackground/beach_background_portrait_low_res.jpg"
import gardenPortraitLowRes from  "../../assets/images/naturescapeBackground/garden_background_portrait_low_res.jpg"
import fireplacePortraitLowRes from  "../../assets/images/naturescapeBackground/fireplace_background_portrait_low_res.jpg"
import meadowPortraitLowRes from  "../../assets/images/naturescapeBackground/meadow_background_portrait_low_res.jpg"
import rainPortraitLowRes from  "../../assets/images/naturescapeBackground/rain_background_portrait_low_res.jpg"
import tropicalBeachPortraitLowRes from  "../../assets/images/naturescapeBackground/tropical_beach_background_portrait_low_res.jpg"
import rainforestPortraitLowRes from  "../../assets/images/naturescapeBackground/rainforest_background_portrait_low_res.jpg"
import whitenoisePortraitLowRes from  "../../assets/images/naturescapeBackground/whitenoise_background_portrait_low_res.jpg"
import brownnoisePortraitLowRes from  "../../assets/images/naturescapeBackground/naturescape_brownnoise_background_lowres.jpg"
import babblingBrookPortraitLowRes from  "../../assets/images/naturescapeBackground/naturescape_babbling_brook_background_lowres.jpg"
import desertPortraitLowRes from  "../../assets/images/naturescapeBackground/naturescape_desert_background_lowres.jpg"
import lakeAtNightPortraitLowRes from  "../../assets/images/naturescapeBackground/naturescape_lake_at_night_background_lowres.jpg"
import mountainPortraitLowRes from  "../../assets/images/naturescapeBackground/naturescape_mountain_background_lowres.jpg"
import oceanPortraitLowRes from  "../../assets/images/naturescapeBackground/naturescape_ocean_background_lowres.jpg"
import trainPortraitLowRes from  "../../assets/images/naturescapeBackground/naturescape_train_background_lowres.jpg"

// Select
import noNatureSelect from "../../assets/images/naturescapeSelect/no_nature_headphones_select.jpg"
import forestSelect from "../../assets/images/naturescapeSelect/forest_select.jpg"
import beachSelect from "../../assets/images/naturescapeSelect/beach_select.jpg"
import gardenSelect from "../../assets/images/naturescapeSelect/garden_select.jpg"
import fireplaceSelect from "../../assets/images/naturescapeSelect/fireplace_select.jpg"
import meadowSelect from "../../assets/images/naturescapeSelect/meadow_select.jpg"
import rainSelect from "../../assets/images/naturescapeSelect/rain_select.jpg"
import tropicalBeachSelect from "../../assets/images/naturescapeSelect/tropicalBeach_select.jpg"
import rainforestSelect from "../../assets/images/naturescapeSelect/rainforest_select.jpg"
import whitenoiseSelect from "../../assets/images/naturescapeSelect/whitenoise_select.jpg"
import brownnoiseSelect from  "../../assets/images/naturescapeSelect/naturescape_brownnoise_select.jpg"
import babblingBrookSelect from  "../../assets/images/naturescapeSelect/naturescape_babbling_brook_select.jpg"
import desertSelect from  "../../assets/images/naturescapeSelect/naturescape_desert_select.jpg"
import lakeAtNightSelect from  "../../assets/images/naturescapeSelect/naturescape_lake_at_night_select.jpg"
import mountainSelect from  "../../assets/images/naturescapeSelect/naturescape_mountain_select.jpg"
import oceanSelect from  "../../assets/images/naturescapeSelect/naturescape_ocean_select.jpg"
import trainSelect from  "../../assets/images/naturescapeSelect/naturescape_train_select.jpg"


export function getNaturescapeSelectImage(naturecapeId) {
    switch (naturecapeId) {
        case SoundscapeId.NoNature:
            return noNatureSelect;
        case SoundscapeId.Beach:
            return beachSelect;
        case SoundscapeId.Forest:
            return forestSelect;
        case SoundscapeId.Garden:
            return gardenSelect;
        case SoundscapeId.Fireplace:
            return fireplaceSelect;
        case SoundscapeId.Meadow:
            return meadowSelect;
        case SoundscapeId.Rain:
            return rainSelect;
        case SoundscapeId.TropicalBeach:
            return tropicalBeachSelect;
        case SoundscapeId.Rainforest:
            return rainforestSelect;
        case SoundscapeId.WhiteNoise:
            return whitenoiseSelect;
        case SoundscapeId.BrownNoise:
            return brownnoiseSelect;
        case SoundscapeId.BabblingBrook:
            return babblingBrookSelect;
        case SoundscapeId.Desert:
            return desertSelect;
        case SoundscapeId.LakeAtNight:
            return lakeAtNightSelect;
        case SoundscapeId.Mountain:
            return mountainSelect;
        case SoundscapeId.Ocean:
            return oceanSelect;
        case SoundscapeId.Train:
            return trainSelect;
        default:
            throw new Error(`getNaturescapeSelectImage - invalid naturescapeId=${naturecapeId}`);
    }
}

export function getNaturescapeBackgroundImage(naturecapeId, screenOrientation) {
    if (screenOrientation === ScreenOrientation.Landscape) {
        switch (naturecapeId) {
            case SoundscapeId.NoNature:
                return { 
                    highRes: noNatureLandscape,
                    lowRes: noNatureLandscapeLowRes
                };
            case SoundscapeId.Beach:
                return { 
                    highRes: beachLandscape, 
                    lowRes: beachLandscapeLowRes
                };
            case SoundscapeId.Forest:
                return { 
                    highRes: forestLandscape,
                    lowRes: forestLandscapeLowRes
                };
            case SoundscapeId.Garden:
                return {
                    highRes: gardenLandscape,
                    lowRes: gardenLandscapeLowRes
                };
            case SoundscapeId.Fireplace:
                return { 
                    highRes: fireplaceLandscape,
                    lowRes: fireplaceLandscapeLowRes
                };
            case SoundscapeId.Meadow:
                return { 
                    highRes: meadowLandscape,
                    lowRes: meadowLandscapeLowRes
                };
            case SoundscapeId.Rain:
                return {
                    highRes: rainLandscape,
                    lowRes: rainLandscapeLowRes
                };
            case SoundscapeId.TropicalBeach:
                return { 
                    highRes: tropicalBeachLandscape,
                    lowRes: tropicalBeachLandscapeLowRes
                };
            case SoundscapeId.Rainforest:
                return { 
                    highRes: rainforestLandscape,
                    lowRes: rainforestLandscapeLowRes
                };
            case SoundscapeId.WhiteNoise:
                return { 
                    highRes: whitenoiseLandscape,
                    lowRes: whitenoiseLandscapeLowRes
                };
            case SoundscapeId.BrownNoise:
                return { 
                    highRes: brownnoiseLandscape,
                    lowRes: brownnoiseLandscapeLowRes
                };
            case SoundscapeId.BabblingBrook:
                return { 
                    highRes: babblingBrookLandscape,
                    lowRes: babblingBrookLandscapeLowRes
                };
            case SoundscapeId.Desert:
                return { 
                    highRes: desertLandscape,
                    lowRes: desertLandscapeLowRes
                };
            case SoundscapeId.LakeAtNight:
                return { 
                    highRes: lakeAtNightLandscape,
                    lowRes: lakeAtNightLandscapeLowRes
                };
            case SoundscapeId.Mountain:
                return { 
                    highRes: mountainLandscape,
                    lowRes: mountainLandscapeLowRes
                };
            case SoundscapeId.Ocean:
                return { 
                    highRes: oceanLandscape,
                    lowRes: oceanLandscapeLowRes
                };
            case SoundscapeId.Train:
                return { 
                    highRes: trainLandscape,
                    lowRes: trainLandscapeLowRes
                };
            default:
                throw new Error(`getNaturescapeBackgroundImage - invalid naturecapeId=${naturecapeId}`);
        }
    } else if (screenOrientation === ScreenOrientation.Portrait) {
        switch (naturecapeId) {
            case SoundscapeId.NoNature:
                return { 
                    highRes: noNaturePortrait,
                    lowRes: noNaturePortraitLowRes
                };
            case SoundscapeId.Beach:
                return { 
                    highRes: beachPortrait,
                    lowRes: beachPortraitLowRes
                };
            case SoundscapeId.Forest:
                return { 
                    highRes: forestPortrait,
                    lowRes: forestPortraitLowRes
                };
            case SoundscapeId.Garden:
                return { 
                    highRes: gardenPortrait,
                    lowRes: gardenPortraitLowRes
                };
            case SoundscapeId.Fireplace:
                return { 
                    highRes: fireplacePortrait,
                    lowRes: fireplacePortraitLowRes
                };
            case SoundscapeId.Meadow:
                return { 
                    highRes: meadowPortrait,
                    lowRes: meadowPortraitLowRes
                };
            case SoundscapeId.Rain:
                return { 
                    highRes: rainPortrait,
                    lowRes: rainPortraitLowRes
                };
            case SoundscapeId.TropicalBeach:
                return { 
                    highRes: tropicalBeachPortrait,
                    lowRes: tropicalBeachPortraitLowRes
                };
            case SoundscapeId.Rainforest:
                return { 
                    highRes: rainforestPortrait,
                    lowRes: rainforestPortraitLowRes
                };
            case SoundscapeId.WhiteNoise:
                return { 
                    highRes: whitenoisePortrait,
                    lowRes: whitenoisePortraitLowRes
                };
            case SoundscapeId.BrownNoise:
                return { 
                    highRes: brownnoisePortrait,
                    lowRes: brownnoisePortraitLowRes
                };
            case SoundscapeId.BabblingBrook:
                return { 
                    highRes: babblingBrookPortrait,
                    lowRes: babblingBrookPortraitLowRes
                };
            case SoundscapeId.Desert:
                return { 
                    highRes: desertPortrait,
                    lowRes: desertPortraitLowRes
                };
            case SoundscapeId.LakeAtNight:
                return { 
                    highRes: lakeAtNightPortrait,
                    lowRes: lakeAtNightPortraitLowRes
                };
            case SoundscapeId.Mountain:
                return { 
                    highRes: mountainPortrait,
                    lowRes: mountainPortraitLowRes
                };
            case SoundscapeId.Ocean:
                return { 
                    highRes: oceanPortrait,
                    lowRes: oceanPortraitLowRes
                };
            case SoundscapeId.Train:
                return { 
                    highRes: trainPortrait,
                    lowRes: trainPortraitLowRes
                };
            
            default:
                throw new Error(`getNaturescapeBackgroundImage - invalid naturecapeId=${naturecapeId}`);
        }
    }

    throw new Error(`Invalid screen orientation=${screenOrientation}`);
}
