import React, { useEffect, useState } from "react";
import "../../styles/choose-zense.css"
import music from "../../assets/icons/sound.svg"
import breathe from "../../assets/icons/breathe.svg"
import touch from "../../assets/icons/touch.svg"
import arrow_back from "../../assets/icons/arrow_back.svg"
import logo from "../../assets/icons/zensory_logo.svg"
import cross from "../../assets/icons/close.svg"
import info from "../../assets/icons/info.svg"
import rotate from "../../assets/icons/rotate.svg"
import { ExperienceSource, getSenseTitle, MoodId, SenseId, SoundscapeId, SubSenseId } from "../Data/constants";
import { withScorm } from 'react-scorm-provider';
import { ZensoryModule } from "../../ZensoryModule";
import { AnalyticsScreenName } from "../API/AnalyticsApi";
import { RouteId } from "../../Routes";
import { getText, TextId } from "../Util/TextHelper";
import { Interweave } from "interweave";
import { isSubmitKey, preventDefault, stopPropagation } from "../UI/KeyPressHelper";
import CloseAndExitPopup from "../Close&ExitPopup";
import { isMacOS } from "../Util/DeviceUtil";
import { GlobalAppState } from "../AppState";
import { setExperienceSourceGlobal } from "../Util/ExperienceAnalyticsHelper";

const ChooseZensePage = (props) => {

  // get the Zenses to show in display order
  const ZENSE_IDS = ZensoryModule
    .getExperienceConfig()
    .getZensesForMood(
      props.mood,
      {
        isOrder: true,
      }
    );

  ZensoryModule.getAnalytics().trackScreen(AnalyticsScreenName.ScreenChooseSense);

  useEffect(() => {
    if (document.getElementById("zense-box-row")) {
      document.getElementById("zense-box-row").focus();
    };
    }, []);

  const [breathFlip, setBreathFlip] = useState(false);
  const [soundFlip, setSoundFlip] = useState(false);
  const [touchFlip, setTouchFlip] = useState(false);
  const [showCloseAndExitPopup, setShowCloseAndExitPopup] = useState(false);
  const isShowExitButton = ZensoryModule.getFeatureUi().isShowExitButton();
  const isShowBackButton = ZensoryModule.getFeatureUi().isShowBackButton();

  const startChooseNaturescape = (e) => {
    preventDefault(e);
    props.setZense(SenseId.Sound);
    props.setSubZense(SubSenseId.Sound);
    props.setExperience(SoundscapeId.NoNature); // better if we set experience here
    ZensoryModule.getNavigationHandler().navigate(window, RouteId.ChooseNaturescape, props.onChooseNaturescape, props.onChooseZense);
  }

  const startChooseBreathwork = (e) => {
    preventDefault(e);
    props.setZense(SenseId.Breath);
    props.setSubZense(null); // clear this to ensure ChooseBreathwork sets the default
    props.setExperience(null);
    ZensoryModule.getNavigationHandler().navigate(window, RouteId.ChooseBreathwork, props.onChooseBreathwork, props.onChooseZense);
  }

  const startChooseTouchpad = (e) => {
    preventDefault(e);
    props.setZense(SenseId.Touch);
    props.setSubZense(SubSenseId.Touch);
    props.setExperience(null); // let the default Touchpad be set further down
    ZensoryModule.getNavigationHandler().navigate(window, RouteId.ChooseTouchpad, props.onChooseTouchpad, props.onChooseZense);
  }

  const onFlip = (e, senseId) => {
    stopPropagation(e);
    switch (senseId) {
      case SenseId.Breath:
        setBreathFlip(!breathFlip);
        break;
      case SenseId.Touch:
        setTouchFlip(!touchFlip);
        break;
      case SenseId.Sound:
        setSoundFlip(!soundFlip);
        break;
      default:
        throw new Error(`Invalid senseId=${senseId}`);
    }
  }

  const back = (e) => {
    preventDefault(e);
    ZensoryModule.getNavigationHandler().back(window, props.setMood, props.onChooseZense, true, GlobalAppState.getIsDeepLink());
  }

  const close = (e) => {
    preventDefault(e);
    setShowCloseAndExitPopup(true);
    ZensoryModule.getNavigationHandler().exit(window, props.sco);
  }

  // handle return key presses on the back button
  const _handleKeyDownBack = (e) => {
    stopPropagation(e);
    if (isSubmitKey(e)) {
      back(e);
    }
  }

  // handle return key presses on the close and exit button
  const _handleKeyDownCloseExit = (e) => {
    stopPropagation(e);
    if (isSubmitKey(e)) {
      close(e);
    }
  }

  // handle return key presses on the Info icon on the Tiles
  const _handleKeyDownTileInfo = (e, zense) => {
    stopPropagation(e);
    if (isSubmitKey(e)) {
      onFlip(e, zense);
    }
  }

  const startExperienceWithSenseId = (e, senseId) => {
    // set that we came from the choose zense page
    setExperienceSourceGlobal(ExperienceSource.WebChooseZense);

    switch (senseId) {
      case SenseId.Breath:
        startChooseBreathwork(e);
        break;
      case SenseId.Touch:
        startChooseTouchpad(e);
        break;
      case SenseId.Sound:
        startChooseNaturescape(e);
        break;
      default:
        throw new Error(`Invalid senseId=${senseId}`);
    }
  }

  // handle return key presses on the Info icon on the Tiles
  const _handleKeyDownTile = (e, senseId) => {
    stopPropagation(e);
    if (isSubmitKey(e)) {
      startExperienceWithSenseId(e, senseId)
    }
  }

  return (
    <>
      { showCloseAndExitPopup
        ? <CloseAndExitPopup setShowCloseAndExitPopup={setShowCloseAndExitPopup}/>
        : null
      }
      <div className="header-row">
      { isShowBackButton
        ? <div className="back" 
            id="btn-choose-zense-back"
            onClick={(e) => back(e)}
            tabIndex="0"
            onKeyDown={(e) => _handleKeyDownBack(e)}
            role="button"
            alt={getText(
              TextId.backButtonAlt,
              { GENERIC: "to Choose a Mood"})}
            aria-label={getText(
              TextId.backButtonAlt,
              { GENERIC: "to Choose a Mood"})}>
          <img 
            src={arrow_back}
            style={{ marginTop: "2px" }}
            alt="Back to Choose a Mood"
          />
          <span>
            { GlobalAppState.getIsDeepLink()
              ? "Home"
              : "Mood"
            } 
          </span>
        </div>
        : <div className="placeholder"> </div> }

        { isShowExitButton 
          ? <div className="close" 
              id="btn-choose-zense-close"
              onClick={(e) => close(e)}
              tabIndex="0"
              onKeyDown={(e) => _handleKeyDownCloseExit(e)}
              alt={getText(TextId.mainCloseAndExitAriaLabel)}
              aria-label={getText(TextId.mainCloseAndExitAriaLabel)}
              role="button" >
              <img 
                src={cross} 
                style={{ marginTop: "2px" }} 
                alt="Close icon"
              />
              <span>{getText(TextId.mainCloseAndExitText)}</span>
            </div>
          : null}
        <div className="logo-container">
          <img 
            className="logo" 
            src={logo} 
            alt="Zensory logo"
          />
        </div>
      </div>
      <div className="choose-zense-container">
        <h1 className="major-heading">{getText(TextId.chooseZenseTitle)}</h1>
        <div className="zense-heading">
          <Interweave content=
            { getText(props.mood === MoodId.Focused 
              ? TextId.chooseZenseSubtitleFocus 
              : TextId.chooseZenseSubtitleRelax)
            }
          />
        </div>
        <div className="box-row-zense" id="zense-box-row" tabIndex="-1">

          { // map over the zenses in the order to show
          ZENSE_IDS.map(zenseId => {
            switch (zenseId) {
              case SenseId.Breath:
                return <div className={`zense-box ${breathFlip ? 'active' : ''}`} 
                  id="tile-choose-zense-breath"
                  onClick={(e) => startExperienceWithSenseId(e, SenseId.Breath)}
                  tabIndex="0"
                  onKeyDown={(e) => _handleKeyDownTile(e, SenseId.Breath)}
                  alt="Breath selection tile"
                  aria-label="Breath selection tile"
                >
                  <img 
                    id="btn-choose-zense-breath-info"
                    src={breathFlip? rotate : info} 
                    alt={getText(breathFlip 
                      ? TextId.hideInformationAlt
                      : TextId.showInformationAlt,
                      { GENERIC: getSenseTitle(SenseId.Breath)})
                    }
                    className="info-icon-zense" 
                    onClick={(e) => onFlip(e, SenseId.Breath)}
                    tabIndex="0"
                    onKeyDown={(e) => _handleKeyDownTileInfo(e, SenseId.Breath)}
                  />
                  <div className="zense-box-inner">
                    <div className="zense-box-front">
                      <img 
                        src={breathe} 
                        className="zense-box-vector"
                        alt="breath icon" 
                      />
                      <div 
                        className="zense-box-subheading" 
                        tabIndex={breathFlip ? "-1" : "0"}
                      >
                        <Interweave
                          content={getText( TextId.chooseZenseBreathBoxFrontHeading)}
                        />
                      </div>
                    </div>
                    <div 
                      className="focus-box-back" 
                      role={isMacOS() ? "textbox" : null}
                      tabIndex={breathFlip ? "0" : "-1"}
                    >
                      <Interweave className="zense-box-back-body" 
                        content={
                          getText(props.mood === MoodId.Focused
                            ? TextId.chooseZenseBreathBoxFocusBackText 
                            : TextId.chooseZenseBreathBoxRelaxBackText)
                        }
                      />
                    </div>
                  </div> 
                </div>

              case SenseId.Sound:
                return <div className={`zense-box ${soundFlip ? 'active' : ''}`}
                  id="tile-choose-zense-sound" 
                  onClick={(e) => startExperienceWithSenseId(e, SenseId.Sound)}
                  tabIndex="0"
                  onKeyDown={(e) => _handleKeyDownTile(e, SenseId.Sound)}
                  alt="Sound selection tile"
                  aria-label="Sound selection tile"
                >
                  <img 
                    id="btn-choose-zense-sound-info"
                    src={soundFlip? rotate : info} 
                    alt={getText(soundFlip 
                      ? TextId.hideInformationAlt
                      : TextId.showInformationAlt,
                      { GENERIC: getSenseTitle(SenseId.Sound)})
                    }
                    className="info-icon-zense" 
                    onClick={(e) => onFlip(e, SenseId.Sound)}
                    tabIndex="0"
                    onKeyDown={(e) => _handleKeyDownTileInfo(e, SenseId.Sound)} 
                  />
                  <div className="zense-box-inner">
                    <div className="zense-box-front">
                      <img 
                        src={music}
                        className="zense-box-vector"
                        alt="music icon" />
                      <div 
                        className="zense-box-subheading" 
                        tabIndex={soundFlip ? "-1" : "0"}
                      >
                        <Interweave
                          content={getText(TextId.chooseZenseSoundBoxFrontHeading)}
                        />
                      </div>
                    </div>
                    <div 
                      className="focus-box-back" 
                      role={isMacOS() ? "textbox" : null} 
                      tabIndex={soundFlip ? "0" : "-1"}
                    >
                      <Interweave className="zense-box-back-body"
                        content={
                          getText(props.mood === MoodId.Focused 
                            ? TextId.chooseZenseSoundBoxFocusBackText
                            : TextId.chooseZenseSoundBoxRelaxBackText)
                        }
                      />
                    </div>
                  </div> 
                </div>

              case SenseId.Touch:
                return <div className={`zense-box ${touchFlip ? 'active' : ''}`} 
                  id="tile-choose-zense-touch"
                  onClick={(e) => startExperienceWithSenseId(e, SenseId.Touch)}
                  tabIndex="0"
                  onKeyDown={(e) => _handleKeyDownTile(e, SenseId.Touch)}
                  alt="Touch tile"
                  aria-label="Touch tile"
                >
                  <img 
                    id="btn-choose-zense-touch-info"
                    src={touchFlip? rotate : info} 
                    alt={getText(touchFlip 
                      ? TextId.hideInformationAlt
                      : TextId.showInformationAlt,
                      { GENERIC: getSenseTitle(SenseId.Touch) })
                    }
                    className="info-icon-zense" 
                    onClick={(e) => onFlip(e, SenseId.Touch)} 
                    tabIndex="0"
                    onKeyDown={(e) => _handleKeyDownTileInfo(e, SenseId.Touch)}
                  />
                  <div className="zense-box-inner">
                    <div className="zense-box-front">
                      <img 
                        src={touch} 
                        className="zense-box-vector"
                        alt="touch icon" />
                      <div 
                        className="zense-box-subheading" 
                        tabIndex={touchFlip ? "-1" : "0"}
                      >
                        <Interweave
                            content={getText(TextId.chooseZenseTouchBoxFrontHeading)}
                        />  
                      </div>
                    </div>
                    <div 
                      className="focus-box-back" 
                      role={isMacOS() ? "textbox" : null}
                      tabIndex={touchFlip ? "0" : "-1"}
                    >
                      <Interweave className="zense-box-back-body"
                        content={
                          getText(props.mood === MoodId.Focused 
                            ? TextId.chooseZenseTouchBoxFocusBackText 
                            : TextId.chooseZenseTouchBoxRelaxBackText)
                        }
                      />
                      <Interweave className="touch-zense-warning-text"
                        content={
                          getText(TextId.chooseZenseTouchBoxWarningText)
                        }
                      />
                    </div>
                  </div> 
                </div>

              default:
                throw new Error(`Invalid zenseId=${zenseId}`);
            }
          })
        }
        </div>
      </div>
    </>
  );
};

const ChooseZense = withScorm()(ChooseZensePage);

export default ChooseZense;
