import { getCurrentMillis, isNullOrEmpty, pad, randomInteger } from "./util";

export const SECS_IN_MILLIS = 1000;
export const MINUTE_IN_SECS = 60;
export const HOUR_IN_MINS = 60;
export const DAY_IN_HOURS = 24;

export const ONE_DAY = DAY_IN_HOURS * HOUR_IN_MINS * MINUTE_IN_SECS * SECS_IN_MILLIS; // hours*minutes*seconds*milliseconds
export const ONE_HOUR = HOUR_IN_MINS * MINUTE_IN_SECS * SECS_IN_MILLIS; // minutes*seconds*milliseconds
export const ONE_MINUTE = MINUTE_IN_SECS * SECS_IN_MILLIS; // seconds*milliseconds

export function getCurrentDateAtStart(): Date {
    const date = new Date();
    date.setUTCHours(0,0,0,1);
    return date;
}

export function add1Day(timestampMillis: number): number {
    return timestampMillis + ONE_DAY;
}

export function getCurrentDateAtStartOfMonth(timestampMillis: number): number {
    const date = new Date();
    date.setTime(timestampMillis);
    date.setDate(1);
    date.setUTCHours(0,0,0,1);
    return date.getTime();
}

export function getYYY_MM_DDForTimestamp(timestamp?: number): string {
    if (isNullOrEmpty(timestamp)) {
        timestamp = getCurrentMillis();
    }

    const d = new Date();
    d.setTime(timestamp!!);
    
    const yyyy = d.getFullYear();
    const mm = String(d.getMonth() + 1).padStart(2, '0'); //January is 0!
    const dd = String(d.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
}

export function getYYY_MM_DD_hh_mm_ForTimestamp(timestamp: number): string {
    return `${getYYY_MM_DDForTimestamp(timestamp)} ${getHH_mm_ForTimestamp(timestamp)}`;
}

export function getYYY_MM_ForTimestamp(timestamp: number): string {
    const d = new Date();
    d.setTime(timestamp);
    
    const yyyy = d.getFullYear();
    const mm = String(d.getMonth() + 1).padStart(2, '0'); //January is 0!
    return `${yyyy}-${mm}`;
}

export function getHH_mm_ForTimestamp(timestamp?: number): string {
    if (isNullOrEmpty(timestamp)) {
        timestamp = getCurrentMillis();
    }

    const d = new Date();
    d.setTime(timestamp!!);

    return String(d.getHours()).padStart(2, '0') + ":" + String(d.getMinutes()).padStart(2, '0');
}

export function getHH_00ForTimestamp(timestamp?: number): string {
    if (isNullOrEmpty(timestamp)) {
        timestamp = getCurrentMillis();
    }

    const d = new Date();
    d.setTime(timestamp!!);

    return String(d.getHours()).padStart(2, '0') + ":00";
}

export function getMonday(timestampMillis: number): Date {
    const date = new Date();
    date.setTime(timestampMillis);
    var day = date.getDay() || 7;
    if (day !== 1) { 
        date.setHours(-24 * (day - 1)); 
    }
    date.setUTCHours(0,0,0,1);
    return date;
}

export function getStartOfDay(timestampMillis: number): Date {
    const date = new Date();
    date.setTime(timestampMillis);
    date.setUTCHours(0,0,0,1);
    return date;
}

export function getEndOfDay(timestampMillis: number): Date {
    const date = new Date();
    date.setTime(getStartOfDay(timestampMillis).getTime() + ONE_DAY - 2);
    return date;
}

export function daysBetweenMillis(first: number, second: number) {
    const fristDate = getStartOfDay(first);
    const secondDate = getStartOfDay(second);
    return Math.round(Math.abs((fristDate.valueOf() - secondDate.valueOf()) / ONE_DAY));
}

export function addMinutesToHH_MM(timeHH_MM: string, incrementMins: number) {
    const chars = timeHH_MM.split("");
    var hour = parseInt(chars[0])*10 + parseInt(chars[1]);
    var minutes = parseInt(chars[3])*10 + parseInt(chars[4]);
    minutes += incrementMins;
    if (minutes < 0) {
        hour--;
        minutes *= -1; // flip the minutes back
    } else if (minutes >= 60) {
        hour++;
        minutes = minutes - 60;
    }

    if (minutes === 60) {
        minutes = 0;
    }

    if (hour < 0) {
        hour = 23;
    } else if (hour === 24) {
        hour = 0;
    }

    return pad(hour, 2) + ":" + pad(minutes, 2);
}

export function getTimestampWithHH_MM(timestamp: number, timeHH_MM: string): number {
    const date = new Date();
    date.setTime(timestamp);

    var chars = timeHH_MM.split("");
    var hour = parseInt(chars[0])*10 + parseInt(chars[1]);
    var mins = parseInt(chars[3])*10 + parseInt(chars[4]);    
    date.setHours(hour, mins, 0, 0);
    return date.getTime();
}

export function getRandomTimeBetween(timestamp: number, fromHH_MM: string, toHH_MM: string): number {
    const date = new Date();
    date.setTime(timestamp);

    var chars = fromHH_MM.split("");
    var hour = parseInt(chars[0])*10 + parseInt(chars[1]);
    var mins = parseInt(chars[3])*10 + parseInt(chars[4]);
    date.setHours(hour, mins, 0, 0);
    const fromTime = date.getTime();

    // if the times are the same, just return the from time
    if (fromHH_MM === toHH_MM) {
        return fromTime;
    }

    chars = toHH_MM.split("");
    hour = parseInt(chars[0])*10 + parseInt(chars[1]);
    mins = parseInt(chars[3])*10 + parseInt(chars[4]);
    date.setHours(hour, mins, 0, 0);

    // if the from time is the day before the to time 
    // (eg. 9pm-8am would be 21:00 to 08:00, because of timezones)
    // we should add 1 day to the end (to) time
    if (fromHH_MM > toHH_MM) {
        date.setDate(date.getDate() + 1);
    }

    const toTime = date.getTime();

    return randomInteger(fromTime, toTime);
}

export function getTimestampISO(timestampMillis: number): string {
    const date = new Date();
    date.setTime(timestampMillis);
    return date.toISOString();
}

export function getTimestampISONow(): string {
    const date = new Date();
    return date.toISOString();
}

export function getLastDayOfMonth(fullYearYYYY: number, month: number): number {
    const lastDayOfMonth = new Date(fullYearYYYY, month + 1, 0);
    return lastDayOfMonth.getDate();
}

export function getLastDateOfMonthDate(fullYearYYYY: number, month: number): Date {
    return new Date(fullYearYYYY, month + 1, 0, 23, 59, 59);
}

export function getFirstDateOfMonthDate(fullYearYYYY: number, month: number): Date {
    return new Date(fullYearYYYY, month, 1);
}

export function getDayOfMonth(timestampMillis: number): number {
    const date = new Date();
    date.setTime(timestampMillis);
    return date.getDate();
}

export function convertToTimezone(timestamp: number, timezone: string): number {
    var date = new Date();
    date.setTime(timestamp);
    const dateTimezoneStr = date.toLocaleString("en-US", {timeZone: timezone});
    date = new Date(dateTimezoneStr);
    return date.getTime();
}

export function adjustForTimezone(timestamp: number, timezone: string): number {
    const diff = getTimezoneMilisDiff(timezone);
    return timestamp - diff;
}

export function getTimezoneMilisDiff(timezone: string): number {
    const diffMins = getTimezoneMinsDiff(timezone);
    return diffMins * ONE_MINUTE;
}

export function getTimezoneMinsDiff(timezone: string): number {
    const currentMillis = getCurrentMillis();
    const timezoneMillis = convertToTimezone(currentMillis, timezone);
    return Math.round((timezoneMillis - currentMillis) / ONE_MINUTE);
}

export function formatSecsToMins (secs: number): string {
    return "" + Math.floor(secs / MINUTE_IN_SECS);
}