import { TextId, getText } from "./Util/TextHelper";

export const SensoryInsights = ({ sensoryStats }) => {
  // convert stats object to array for easy mapping
  const sensoryArray = Object.entries(sensoryStats).map(([sense, value]) => {
    return { sense, value };
  });

  return (
    <div className="profile-box-empty">
      <h1 className="profile-box-heading">{getText(TextId.sensoryInsightsTitle)}</h1>
      <br />

      <div className="sensory-insight-stats-container">
        {sensoryArray.map((x) => (
          <div className="single-sense">
            <img
              src={require(`../assets/icons/ic_sense_${x.sense}.png`)}
              alt={x.sense + "icon"}
              height={50}
            ></img>
            <h3 className="sense-tag">{x.sense}</h3>
            <h3 className="sense-tag">{Math.ceil(x.value * 100)}%</h3>
          </div>
        ))}
      </div>
    </div>
  );
};
