import { ImageLoaderApi } from "../../API/ImageLoaderApi";
import { MoodId, SoundscapeId, SubSenseId } from "../../Data/constants";
import { MultiResolutionImgHolder } from "../../Data/Models/app";
import { getBreathExperienceImage, getChooseBreathTileImage } from "../../Loaders/ImageLoader";
import { getNaturescapeBackgroundImage, getNaturescapeSelectImage } from "../../Loaders/ImageLoaderNaturescapes";


export class BaseImageLoader implements ImageLoaderApi {
    
    getNaturescapeBackgroundImage(naturescapeId: SoundscapeId, screenOrientation: ScreenOrientation): MultiResolutionImgHolder {
        return getNaturescapeBackgroundImage(naturescapeId, screenOrientation);
    }

    getChooseBreathTileImage(moodId: MoodId, subSenseId: SubSenseId, screenOrientation: ScreenOrientation): MultiResolutionImgHolder {
        return getChooseBreathTileImage(moodId, subSenseId);
    }

    getBreathExperienceImage(moodId: MoodId, subSenseId: SubSenseId, screenOrientation: ScreenOrientation): MultiResolutionImgHolder {
        return getBreathExperienceImage(moodId, subSenseId);
    }

    getNaturescapeSelectImage(naturescapeId: SoundscapeId): String {
        return getNaturescapeSelectImage(naturescapeId);
    }

}