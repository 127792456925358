import cross from "../assets/icons/close.svg"
import { isKeyIn, preventDefault, stopPropagation } from './UI/KeyPressHelper';
import { Interweave } from "interweave";
import { getText, TextId } from "./Util/TextHelper";
import { useEffect, useRef, useState } from "react";
import { handleTabKey } from "./Util/ModalAccessibilityHelper";
import { KeyCode, KeyId } from "./UI/KeyEnum";

const CloseExperiencePopup = (props) => {

    const modalRef = useRef();
    
    //set focus to the confirm end button when the dialog shows
    const [btnStartFocus, setStartFocusButton] = useState(null);
    useEffect(() => {
        if (btnStartFocus != null) {
            btnStartFocus.focus();
        }
    }, [btnStartFocus]);

    useEffect(() => {
        if (props.isBackNavigation){
            window.history.pushState(null, null,window.location.pathname);
        }
        window.addEventListener('popstate', window.history.pushState(null, null, window.location.pathname));
        return () => {
          window.removeEventListener('popstate', window.history.pushState(null, null, window.location.pathname));
          props.setIsBackNavigation(false);
        };
      }, []);
    
      useEffect(() => {
        function keyListener(e) {
            const listener = e.keyCode === KeyCode.Tab 
                ? handleTabKey(e, modalRef,'img, button') 
                : e.keyCode === KeyCode.Esc
                ? closePopup(e) 
                : null; 
            return listener && listener(e, modalRef,'img, button');
          }
        document.addEventListener("keydown", keyListener);
        return () => document.removeEventListener("keydown", keyListener);
      },[]);

    const closePopup = (e) => {
        preventDefault(e);
        if (props.isBackNavigation){ 
            window.history.pushState(null, null, window.location.pathname);
        }
        props.setCloseExperiencePopup(false);
    }

    const onConfirmEndExpClicked = (e) => {
        confirmBackNav(e);
    }

    const confirmBackNav = (e) => {
        stopPropagation(e);
        props.setExpired(true);
    }

    // handle return key presses on the close popup icon
    const _handleKeyDownClose = (e) => {
        stopPropagation(e);
        if (isKeyIn(e, [KeyId.Enter, KeyId.SpaceBar])) {
            closePopup(e);
        }
    }

   // handle return key presses on the end experience button
    const _handleKeyDownConfirmEnd = (e) => {
        //stopPropagation(e);
        if (isKeyIn(e, [KeyId.Enter, KeyId.SpaceBar])) {
            confirmBackNav(e);   
        }
    }

    return (
        <div className="popup-container-close-exp"
            ref={modalRef}>
            <div className='popup-header'>
                <img
                    id="btn-close-exp-popup-close"
                    src={cross}
                    className="popup-close-icon"
                    onClick={(e) => closePopup(e)}
                    alt="Close popup"
                    tabIndex="0"
                    onKeyDown={(e) => _handleKeyDownClose(e)}
                />
            </div>
            <div className='popup-instruction-close'>
                <Interweave
                    content={getText(TextId.closeExperiencePopupText)}
                />
            </div>
            <div style={{display:"flex", flexDirection:"row", columnGap:"1pc"}}>
            <button
                className="okay-button"
                id="btn-close-exp-popup-okay"
                onClick={(e) => onConfirmEndExpClicked(e)}
                ref={(button) => { setStartFocusButton(button); }}
                onKeyDown={(e) => _handleKeyDownConfirmEnd(e)}
                tabIndex="0"
                alt="Confirm end experience button"
                aria-label="Confirm end experience button"
            >
                <Interweave
                  content={getText(TextId.closeExperiencePopupButtonText)}
                />
            </button>
            </div>
        </div>
    );
};

export default CloseExperiencePopup;