import { RouteId } from "../../../Routes";
import { AnalyticsApi } from "../../API/AnalyticsApi";
import { NavigationHandlerApi } from "../../API/NavigationHandlerApi";
import { SessionHandlerApi } from "../../API/SessionHandlerApi";
import { getGlobalAppState, setAppStateToStart } from "../../AppState";

export class ScormNavigationHandler implements NavigationHandlerApi {

    analyticsApi: AnalyticsApi;
    sessionHadlerApi: SessionHandlerApi;

    constructor(analyticsApi: AnalyticsApi, sessionHadlerApi: SessionHandlerApi) {
        this.analyticsApi = analyticsApi;
        this.sessionHadlerApi = sessionHadlerApi;
    }

    // @ts-ignore    
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    navigate(window: Window & typeof globalThis, location: string, 
            previousPageStateHandler?: any, 
            currentPageStateHandler?: any): void {
        
        if (previousPageStateHandler) {
            previousPageStateHandler(true);
        }
        if (currentPageStateHandler) {
            currentPageStateHandler(false);
        }

        if (location === RouteId.Root) {
            // clear global app state
            setAppStateToStart(getGlobalAppState());
        }

        getGlobalAppState().setLoginPage(location === RouteId.Login);
        getGlobalAppState().setSignupPage(location === RouteId.SignUp);
        getGlobalAppState().setProfilePage(location === RouteId.Profile);
        getGlobalAppState().setForgotPasswordPage(location === RouteId.ForgotPassword);
        getGlobalAppState().setReview(location === RouteId.Review);
        getGlobalAppState().setAfterExperience(location === RouteId.AfterExperience);
        getGlobalAppState().setExperienceSummary(location === RouteId.ExperienceSummary);
    }

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    back(window: Window & typeof globalThis, 
        previousPageStateHandler: any, 
        currentPageStateHandler: any, 
        isChooseZense: boolean,
        isDeepLink: boolean): void {
        
        var obj: any;
        if (isChooseZense) {
            obj = "";
        } else {
            obj = true;
        }
        previousPageStateHandler(obj);

        currentPageStateHandler(false);
    }

    exit(window: Window & typeof globalThis, scorm: any): void {
        // TODO: only set scorm values if the user started an experience in this session or a previous one
        // const summary = this.analyticsApi.getSessionSummaryJson();
        // if (containsAnalyticsEvent(AnalyticMainEventName.StartExperience, summary.events)) {
            this.sessionHadlerApi.onExperienceReviewFinish(window, scorm, true);
        // }

        scorm.close();
        window.close();
    }

    checkExperiencePage(window: Window & typeof globalThis, location: RouteId, props: any): void {
        // ignore for Scorm
    }
}