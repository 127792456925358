import React from "react";
import { useEffect } from "react";

import "../styles/lavalamp.css";

const LavalampRed = () => {
  const url = "red-script.js"

  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url]);
  return (
    <div className="red_wrapper">
      <canvas
        id="redbubble"
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></canvas>
    </div>
  );
};

export default LavalampRed;
