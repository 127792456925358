import { ZensoryModule } from "../../ZensoryModule";
import { getConfiguration } from "../Util/ConfigHelper";
import { LogLevel } from "../Util/Logger";
import { getBuildVersionString } from "./Models/build_version";
import { AnalyticsMethod, AuthMethod, AuthProvider, Configuration, CrashTrackingMethod, CustomerId, DatabaseType, DeploymentType, EndExperienceType, MoodZenseSoundConfig } from "./Models/configuration";
import { getEnumValues, isNullOrEmpty, isValidEnumValue } from "./Util/util";

export enum Platform {
    Android = 'Android',
    Ios = 'iOS',
    Web = 'web',
    Microsoft = 'Microsoft'
}

export enum ZensoryExperienceId {
    Creative = 'creative',
    Focus = 'focused',
    Relax = 'relax',
    Positive = 'positive',
}

export enum MoodId {
    Creative = 'creative',
    Focused = 'focused',
    Relax = 'relax',
    Positive = 'positive',
    // Sleep = 'sleep',
    // Energise = 'energise',
    All = 'all',
}

export enum SenseId {
    Taste = "taste",
    Smell = "smell",
    Touch = "touch",
    Breath = "breath",
    Pressure = "pressure",
    Movement = "movement",
    Sound = "sound",
    Tapping = "tapping",
    NoNature = "noNature"
}

export enum SubSenseId {
    Sound = "sound",
    Taste = "taste",
    Smell = "smell",
    Touch = "touch",
    AudioGuidedBreath = "audioGuided",
    VisualGuidedBreath = "visualGuided",
}

export type ExperienceId = SoundscapeId | TouchpadId;

export function isBreathSubSense(subSenseId: SubSenseId) {
    return subSenseId === SubSenseId.AudioGuidedBreath || subSenseId === SubSenseId.VisualGuidedBreath
}

export enum AudioTriggerId {
    Hold1 = 'hold1',
    In1 = 'in1',
    Out1 = 'out1',
    Num2 = 'num2',
    Num3 = 'num3',
    Num4 = 'num4',
    Num5 = 'num5',
    Num6 = 'num6',
    Num7 = 'num7'
}

export enum ExperienceSource {
    QuickStart = 'quickStart',
    Custom = 'custom',
    Previous = 'previous',
    HomeCards = 'HomeCards',
    Downloads = 'downloads',
    NotImprovedQuickStart = 'NotImprovedQuickStart',
    Offline = 'offline',
    Calendar = 'calendar',
    WebChooseZense = 'webChooseZense',
    WebDeepLink = 'webDeepLink',
}

export enum QuickStartExperienceId {
    EnergyBoost = "energyBoost",
    DeepWork = "deepWork",
    CreativeReflection = "creativeReflection",
    FeelgoodFlow = "feelgoodFlow",
    ZoneOut = "zoneOut",
    ZoneIn = "zoneIn",
    EndOfDayUnwind = "endOfDayUnwind",
    MiddayMotivation = "middayMotivation",
    IdeaSparker = "ideaSparker",
    MorningMotivation = "morningMotivation",
    SoothingSoundscapes = "soothingSoundscapes",
    TapIntoRelaxation = "tapIntoRelaxation",
    TapIntoFeelingGood = "tapIntoFeelingGood",
    TapIntoFocus = "tapIntoFocus",
    TapIntoCreativity = "tapIntoCreativity",
    EnergisingBreathwork = "energisingBreathwork",
    PlayfulRecalibtation = "playfulRecalibtation",
    BlissfulBeach = "blissfulBeach",
    SwitchOff = "switchOfff",
    MellowMovesMorning = "mellowMovesMorning",
    MellowMovesAfternoon = "mellowMovesAfternoon",
    MellowMovesEvening = "mellowMovesEvening",
    MorningChillout = "morningChillout",
    CalmingBreathwork = "calmingBreathwork",
    CatchYourBreath = "catchYourBreath",
    PeacefulPressurePoints = "peacefulPressurePoints",
    FeelGoodMoves = "feelGoodMoves",
    PositivePressurePoints = "positivePressurePoints",
    BreatheToFocus = "breatheToFocus",
    FocusPressurePoints = "focusPressurePoints",
}

export enum TouchpadId {
    Smoke = "smoke",
    Squiggles = "squiggles",
    Bubbles = "bubbles",
    LittleBubbles = "littleBubbles",
    PaintSpill = "paintSpill",
    Cones = "cones",
    Snakes = "snakes",
    GreenFluid = "greenFluid",
    ColorMix = "colorMix",
    MoonDust = "moonDust",
    ExplosiveAttraction = "explosiveAttraction",
    Orbit = "orbit",
    RacingStripes = "racingStripes",
    RainColor = "rainColor",
    SpinningDots = " spinningDots",
    Muscular = "muscular",
    WaterIntense = "waterIntense",
    WaterGentle = "waterGentle",
}

export enum SoundscapeId {
    None = 'none',
    Beach = 'beach',
    TropicalBeach = 'tropicalBeach',
    Fireplace = 'fireplace',
    Forest = 'forest',
    Meadow = 'meadow',
    Rainforest = 'rainforest',
    Garden = 'garden',
    Rain = 'rain',
    WhiteNoise = "whiteNoise",
    BrownNoise = 'brownNoise',
    Train = 'train',
    Ocean = 'ocean',
    Mountain = 'mountain',
    BabblingBrook = 'babblingBrook',
    LakeAtNight = 'lakeAtNight',
    Desert = 'desert',
    NoNature = "noNature"
}

export function isVisualGuidedBreath(zenseId: SenseId, subZenseId: SubSenseId) {
    return zenseId === SenseId.Breath
        && subZenseId === SubSenseId.VisualGuidedBreath;
}

export function isAudioGuidedBreath(zenseId: SenseId, subZenseId: SubSenseId) {
    return zenseId === SenseId.Breath
        && subZenseId === SubSenseId.AudioGuidedBreath
}

export function hasNaturescapeAudio(soundscapeId: SoundscapeId) {
    return soundscapeId !== SoundscapeId.NoNature;
}

export function hasMusicAudio(experienceId: SoundscapeId | TouchpadId) {
    return experienceId !== SoundscapeId.WhiteNoise && experienceId !== SoundscapeId.BrownNoise;
}

export function getMoodIdFromExperienceId(expId: ZensoryExperienceId): MoodId {
    switch (expId) {
        case ZensoryExperienceId.Creative:
            return MoodId.Creative;

        case ZensoryExperienceId.Relax:
            return MoodId.Relax;

        case ZensoryExperienceId.Positive:
            return MoodId.Positive;

        case ZensoryExperienceId.Focus:
        default:
            return MoodId.Focused;
    }
}

export function getExperienceIdFromMoodId(moodId: MoodId): ZensoryExperienceId {
    switch (moodId) {
        case MoodId.Creative:
            return ZensoryExperienceId.Creative;
        case MoodId.Relax:
            return ZensoryExperienceId.Relax;
        case MoodId.Positive:
            return ZensoryExperienceId.Positive;
        case MoodId.Focused:
        default:
            return ZensoryExperienceId.Focus;
    }
}

export function isMoodZoneIn(moodId: MoodId): boolean {
    return moodId === MoodId.Focused || moodId === MoodId.Creative;
}

export enum MoodTitle {
    Creative = 'Creative',
    Focused = 'Focus',
    Relax = 'Relax',
    Positive = 'Positive',
    Sleep = 'Sleep',
    Energise = 'Energise',
    All = 'All',
}

export function getMoodIdTitle(moodId: MoodId): string {
    switch (moodId) {
        case MoodId.Creative:
            return MoodTitle.Creative;
        case MoodId.Relax:
            return MoodTitle.Relax;
        case MoodId.Positive:
            return MoodTitle.Positive;
        case MoodId.Focused:
            return MoodTitle.Focused;
        default:
            throw new Error(`Invalid moodId=${moodId}`);
    }
}

export enum ExperienceCategory {
    Mood = 'mood',
    Task = 'task'
}

export enum AudioDurationSecs {
    THREE_3_MINS = (3 * 60),
    FOUR_4_MINS = (4 * 60),
    FIVE_5_MINS = (5 * 60),
    TEN_10_MINS = (10 * 60),
    TWENTY_20_MINS = (20 * 60),
    TWENTY_FIVE_25_MINS = (25 * 60),
    UNLIMITED_LOOP = -1
}

export function getAudioDurationFromMins(mins: number): AudioDurationSecs {
    switch (mins) {
        case 3:
            return AudioDurationSecs.THREE_3_MINS;
        case 4:
            return AudioDurationSecs.FOUR_4_MINS;
        case 5:
            return AudioDurationSecs.FIVE_5_MINS;
        case 10:
            return AudioDurationSecs.TEN_10_MINS;
        case 20:
            return AudioDurationSecs.TWENTY_20_MINS;
        case 25:
            return AudioDurationSecs.TWENTY_FIVE_25_MINS;
        case -1:
            return AudioDurationSecs.UNLIMITED_LOOP;
        default:
            throw new Error(`Invalid mins: ${mins}`);
    }
}

export function getAudioDurationInMins(audioDurationSecs: AudioDurationSecs): number {
    if (audioDurationSecs === AudioDurationSecs.UNLIMITED_LOOP) {
        return -1;
    } else {
        return (audioDurationSecs / 60);
    }
}

export function getAudioDurationTitle(audioDurationSecs: AudioDurationSecs): string {
    if (audioDurationSecs === AudioDurationSecs.UNLIMITED_LOOP) {
        return "Unlimited";
    }
    else {
        return `\u00A0${getAudioDurationInMins(audioDurationSecs)} min\u00A0`;
    }
}

export const ALL_AUDIO_DURATIONS: AudioDurationSecs[] = [
    AudioDurationSecs.THREE_3_MINS,
    AudioDurationSecs.FOUR_4_MINS,
    AudioDurationSecs.FIVE_5_MINS,
    AudioDurationSecs.TEN_10_MINS,
    AudioDurationSecs.TWENTY_20_MINS,
    AudioDurationSecs.TWENTY_20_MINS,
    AudioDurationSecs.UNLIMITED_LOOP];

export enum EnvId {
    DEV = "DEV",
    PROD = "PROD",
    RESEARCH_URL = "RESEARCH_UCL",
}

export function getSoundscapeTitle(soundscapeId: SoundscapeId): string {
    switch (soundscapeId) {
        case SoundscapeId.None:
            return "";
        case SoundscapeId.Beach:
            return "Beach";
        case SoundscapeId.TropicalBeach:
            return "Tropical Beach";
        case SoundscapeId.Fireplace:
            return "Fireplace";
        case SoundscapeId.Forest:
            return "Forest";
        case SoundscapeId.Meadow:
            return "Meadow";
        case SoundscapeId.Rainforest:
            return "Rainforest";
        case SoundscapeId.Garden:
            return "Garden";
        case SoundscapeId.Rain:
            return "Rain";
        case SoundscapeId.WhiteNoise:
            return "Whitenoise";
        case SoundscapeId.NoNature:
            return "No Naturescape";
        case SoundscapeId.BrownNoise:
            return "Brown Noise";
        case SoundscapeId.Train:
            return "Train";
        case SoundscapeId.Ocean:
            return "Ocean";
        case SoundscapeId.Mountain:
            return "Mountain";
        case SoundscapeId.BabblingBrook:
            return "Babbling Brook";
        case SoundscapeId.LakeAtNight:
            return "Lake at Night";
        case SoundscapeId.Desert:
            return "Desert";
    }
}

export enum ScreenOrientation {
    Landscape = "landscape",
    Portrait = "portrait"
}

export enum ExpertId {
    AmandaKerr = "AmandaKerr"
}

export enum ExperienceExpertId {
    AmandaKerrTappingPositive = "amandaTappingPositive",
    AmandaKerrTappingCreative = "amandaTappingCreative",
    AmandaKerrTappingFocus = "amandaTappingFocus",
    AmandaKerrTappingRelax = "amandaTappingRelax",
}

export enum ColourId {
    Purple = 'purple',
    Blue = 'blue',
    Green = 'green',
    Orange = 'orange',
    Red = 'red',
}

export enum ColourValues {
    Purple = '#350879',
    PurpleShade1 = '#8f58e4',
    PurpleShade2 = '#b646be',
    PurpleShade3 = '#D6378D',
    PurpleShade4 = '#E97B96',
    Blue = '#0075a8',
    BlueShade1 = '#0284bc',
    BlueShade2 = '#79d0e7',
    BlueShade3 = '#56B5DD',
    BlueShade4 = '#94D6F2',
    Green = '#288765',
    GreenShade1 = '#3B9374',
    GreenShade2 = '#B1D28B',
    GreenShade3 = '#A6D38D',
    GreenShade4 = '#B3D58A',
    Orange = '#f18823',
    OrangeShade1 = '#F6722B',
    OrangeShade2 = '#F0D105',
    OrangeShade3 = '#FEBE08',
    OrangeShade4 = '#F7B303',
    Red = '#d84444',
    RedShade1 = '#FF9666',
    RedShade2 = '#D43D5C',
    RedShade3 = '#FF6363',
    RedShade4 = '#EB5A5A',

}

export enum ContentSetupId {
    Touchpads = "touchpads",
    BreathingExercises = "breathingExercises",
    Tastes = "tastes",
    Smells = "smells",
    PressurePoints = "pressurePoints",
    Movements = "movements",
    NatureBasic = "natureBasic"
}

export enum ExperienceCustomiseOptionId {
    Affirmations = 'affirmations',
    Sound = 'sound',
    Intentions = 'intentions',
    SoundscapeId = 'soundscapeId',
    VideoAndAudio = 'videoAndAudio',
    AudioLevels = 'levels',
}

export enum MenuOptionId {
    MuteUnmute = 'muteUnmute',
    PlayPause = 'playPause',
    Levels = 'levels',
    Touchpads = 'touchpads',
    Notes = "notes",
    RandomiseTouchpad = "randomiseTouchpad"
}

export enum UserRelease {
    Stress = "Stress",
    Overwhelm = "Overwhelm",
    Uninspired = "Uninspired",
    Anxiety = "Anxiety",
    Poor_Sleep = "Poor Sleep",
    Creative_Blocks = "Creative Blocks",
    Sadness = "Sadness",
    Distraction = "Distraction",
}

export enum UserAddition {
    Clarity = "Clarity",
    Joy = "Joy",
    Focus = "Focus",
    Creativity = "Creativity",
    Excitement = "Excitement",
    Fun = "Fun",
    Ideas = "Ideas",
    Peace = "Peace",
}

export function getSenseTitle(senseId: SenseId): string {
    switch (senseId) {
        case SenseId.Taste: return "Taste";
        case SenseId.Smell: return "Smell";
        case SenseId.Touch: return "Touch";
        case SenseId.Breath: return "Breath";
        case SenseId.Pressure: return "Pressure";
        case SenseId.Movement: return "Movement";
        case SenseId.Sound: return "Sound";
        default: throw new Error("Invalid senseId: " + senseId);
    }
}

export enum InfoPageId {
    Home = "home",
    Experience = "experience",
    Senses = "senses",
    SenseTouchpads = "senseTouchpads",
    SenseTaste = "senseTaste",
    SenseSmell = "senseSmell",
    SenseBreath = "senseBreath",
    SenseSound = "senseSound",
    SenseMovement = "senseMovement",
    NaturescapeSelect = "naturescapeSelect",
    CustomiseExperience = "customiseExperience",
    Notes = "notes",
    SenseBreathFocus = "senseBreathFocus",
    senseBreathPositive = "senseBreathPositive",
    senseBreathRelax = "senseBreathRelax",
    senseBreathCreative = "senseBreathCreative",
    Profile = "profile",
    SensePressureEyebrow = "sensePressureEyebrow",
    SensePressureLip = "sensePressureLip",
    SensePressureTopOfHead = "sensePressureTopOfHead",
    SensePressureNeck = "sensePressureNeck",
    SensePressureTemple = "sensePressureTemple",
    SensePressureUnderCollarBone = "sensePressureUnderCollarBone",
    SensePressureThymus = "sensePressureThymus",
    SensePressureSpleen = "sensePressureSpleen",
    FocusSessionCustomise = "focusSessionCustomise",
}

export enum TextOptionsId {
    ExperienceLoadingMain = "experienceLoadingMain",
    ExperienceLoadingFactTip = "experienceLoadingFactTip",
    BreathingLoading = "breathingLoading",
    BreathingLoadingFactTip = "breathingLoadingFactTip",
}

export enum UserActivityType {
    All = "all",
    StartExperience = "startExperience",
    EndExperience = "experience",
    Sense = "sense",
    FocusSession = "focusSession",
    AppOpen = "appOpen"
}

export enum StatsTimeFrame {
    Today = "statsByDay",
    ThisWeek = "statsByWeek",
    ThisMonth = "statsByMonth",
    AllTime = "allTime",
}

export enum SenseContentClickAction {
    MoreInfo = "moreInfo",
    Music = "music",
    Breathing = "breathing",
    Touchpad = "touchpad",
    Video = "video",
}

export function getPopUpTextFromTouchpadId(touchpadId: TouchpadId, isMobile = false): string {
    if (isMobile) {
        switch (touchpadId) {
            case TouchpadId.Smoke: return "Touch and move 👉 the screen to play";
            case TouchpadId.Cones: return "Touch and Move 👉 to play";
            case TouchpadId.LittleBubbles: return "Touch and move 👉 to play";
            case TouchpadId.Squiggles: return "Tap or move 👉 to play";
            case TouchpadId.Bubbles: return "Tap and move or long-hold 👉 to play";
            case TouchpadId.GreenFluid: return "Touch and move 👉 the screen to play";
            case TouchpadId.ColorMix: return "Touch and move 👉 the screen to play";
            case TouchpadId.MoonDust: return "Touch and move 👉 to play";
            case TouchpadId.ExplosiveAttraction: return "Touch and move 👉 to play";
            case TouchpadId.Orbit: return "Touch and move 👉 to play";
            case TouchpadId.RacingStripes: return "Touch and move 👉 to play";
            case TouchpadId.RainColor: return "Touch and move 👉 to play";
            case TouchpadId.SpinningDots: return "Touch and move 👉 to play";
            case TouchpadId.Muscular: return "Touch and move 👉 to play";
            default: throw new Error("Invalid touchpadId: " + touchpadId);
        }
    } else {
        switch (touchpadId) {
            case TouchpadId.Smoke: return "Click and drag your 🖱️ mouse to play";
            case TouchpadId.Cones: return "Move your 🖱️ mouse to play";
            case TouchpadId.LittleBubbles: return "Move your 🖱️ mouse to play";
            case TouchpadId.Squiggles: return "Click or move your 🖱️ mouse to play";
            case TouchpadId.Bubbles: return "Click or long-hold your 🖱️ mouse to play";
            case TouchpadId.GreenFluid: return "Click and drag your 🖱️ mouse to play";
            case TouchpadId.ColorMix: return "Move your 🖱️ mouse to play";
            case TouchpadId.MoonDust: return "Click and drag your 🖱️ mouse to play";
            case TouchpadId.ExplosiveAttraction: return "Move your 🖱️ mouse to play";
            case TouchpadId.Orbit: return "Move your 🖱️ mouse to play";
            case TouchpadId.RacingStripes: return "Move your 🖱️ mouse to play";
            case TouchpadId.RainColor: return "Move your 🖱️ mouse to play";
            case TouchpadId.SpinningDots: return "Move your 🖱️ mouse to play";
            case TouchpadId.Muscular: return "Click and drag your 🖱️ mouse to play";
            default: throw new Error("Invalid touchpadId: " + touchpadId);
        }
    }
}

export function getTouchpadTitle(touchpadId: TouchpadId): string {
    switch (touchpadId) {
        case TouchpadId.Smoke:
            return "Smoke";
        case TouchpadId.Bubbles:
            return "Bubbles";
        case TouchpadId.Cones:
            return "Cones";
        case TouchpadId.Squiggles:
            return "Squiggles";
        case TouchpadId.LittleBubbles:
            return "Little Bubbles";
        case TouchpadId.GreenFluid:
            return "Green Fluid";
        case TouchpadId.ColorMix:
            return "Color Mix";
        case TouchpadId.MoonDust:
            return "Moon Dust";
        case TouchpadId.ExplosiveAttraction:
            return "Explosive Attraction";
        case TouchpadId.Orbit:
            return "Orbit";
        case TouchpadId.RacingStripes:
            return "Racing Stripes";
        case TouchpadId.RainColor:
            return "Rain Color";
        case TouchpadId.SpinningDots:
            return "Spinning Dots";
        case TouchpadId.PaintSpill:
            return "Paint Spill";
        case TouchpadId.Muscular:
            return "Muscular";
        default: throw new Error("Invalid touchpadId: " + touchpadId);
    }
}

export enum PopupTimeoutValue {
    Touchpad = 5000
}


export function getSoundscapeIdsForMood(moodId: MoodId): SoundscapeId[] {
    const config = ZensoryModule.getExperienceConfig().getMoodZenseConfig(moodId, SenseId.Sound, SubSenseId.Sound);
    if (isNullOrEmpty(config)) {
        throw Error(`getSoundscapeIdsForMood() Not configured for moodId=${moodId}`);
    }
    const soundConfig = config as MoodZenseSoundConfig;
    const arr: SoundscapeId[] = [];
    const soundscapeIds = getEnumValues(SoundscapeId);
    for (const soundscapeId of soundscapeIds) {
        // if all soundscapes are enabled or the specific soundscape is enabled, add it to the list
        if (soundConfig.isAllSoundscapes || soundConfig.soundscapeIds[soundscapeId]) {
            arr.push(soundscapeId);
        }
    }
    return arr;
}

export function getSoundAudioDurationSecsForMood(moodId: MoodId): AudioDurationSecs[] {
    const config = ZensoryModule.getExperienceConfig().getMoodZenseConfig(moodId, SenseId.Sound, SubSenseId.Sound);
    if (isNullOrEmpty(config)) {
        throw Error(`getSoundAudioDurationSecsForMood() Not configured for moodId=${moodId}`);
    }
    const audioDurations = config!!.audioDurations;
    const arr: AudioDurationSecs[] = [];
    const AUDIO_DURATION_SECS = getEnumValues(AudioDurationSecs);
    for (const audioDurationSecs of AUDIO_DURATION_SECS) {
        // if the audio duration is enabled, add it to the list
        if (audioDurations[audioDurationSecs]) {
            arr.push(audioDurationSecs);
        }
    }
    return arr;
}

export const getTouchAudioDurationSecsForMood = (moodId: MoodId) => {
    const audioDurations = ZensoryModule.getExperienceConfig().getMoodZenseConfig(moodId, SenseId.Touch, SubSenseId.Touch)!!.audioDurations;
    const arr: AudioDurationSecs[] = [];
    const AUDIO_DURATION_SECS = getEnumValues(AudioDurationSecs);
    for (const audioDurationSecs of AUDIO_DURATION_SECS) {
        // if the audio duration is enabled, add it to the list
        if (audioDurations[audioDurationSecs]) {
            arr.push(audioDurationSecs);
        }
    }
    return arr;
}

export const getBreathAudioDurationSecsForMood = (moodId: MoodId, subSenseId: SubSenseId) => {
    const audioDurations = ZensoryModule.getExperienceConfig().getMoodZenseConfig(moodId, SenseId.Breath, subSenseId)!!.audioDurations;
    const arr: AudioDurationSecs[] = [];
    const AUDIO_DURATION_SECS = getEnumValues(AudioDurationSecs);
    for (const audioDurationSecs of AUDIO_DURATION_SECS) {
        // if the audio duration is enabled, add it to the list
        if (audioDurations[audioDurationSecs]) {
            arr.push(audioDurationSecs);
        }
    }
    return arr;
}

export enum NotifPrefWeekDay {
    Monday = "mon",
    Tuesday = "tue",
    Wednesday = "wed",
    Thursday = "thu",
    Friday = "fri",
    Saturday = "sat",
    Sunday = "sun",
}

export enum MoodColors {
    PostiveStart = "#F6722B",
    PositiveEnd = "#F0D105",
    RelaxStart = "#296EDF",
    RelaxEnd = "#79D0E7",
    FocusedStart = "#FF9666",
    FocusedEnd = "#D43D5C",
    CreativeStart = "#3B9374",
    CreativeEnd = "#B1D28B"
}

export enum LinkId {
    PrivacyPolicy = "privacyPolicy",
    Terms = "terms",
    PlayStore = "playStore",
    AppStore = "appStore"
}

export function isLinkId(s: string): boolean {
    return isValidEnumValue(LinkId, s);
}

export function getConfigLink(linkId: string, fallback: string) {
    if (!isLinkId(linkId)) {
        return fallback;
    }

    return getConfiguration().links[linkId as LinkId];
}

export const ConfigurationDefault: Configuration = {
    configId: "",
    version: getBuildVersionString(),
    deploymentType: DeploymentType.Web,
    customerId: CustomerId.TheZensory,
    envId: EnvId.DEV,
    authProvider: AuthProvider.None,
    authMethods: {
        [AuthMethod.Anonymous]: false,
        [AuthMethod.EmailPassword]: false,
    },
    isWorkEmailOnly: false,
    databaseType: DatabaseType.Local,
    analyticMethods: {
        [AnalyticsMethod.Scorm]: false,
        [AnalyticsMethod.Firebase]: false,
        [AnalyticsMethod.Mixpanel]: false,
    },
    crashTrackingMethods: {
        [CrashTrackingMethod.Sentry]: false,
        [CrashTrackingMethod.Firebase]: false,
    },
    authorisedDomains: [],
    logging: {
        isEnabled: true,
        logLevel: LogLevel.WARN,
    },
    isExperienceDeepLinkOnly: false,
    contentConfig: {},
    endExperienceConfig: {
        type: EndExperienceType.None,
        pageTitleText: "",
        titleText: "",
        subTitleText: "",
        isSingleExperience: false,
        appDownloadConfig: {
            isShow: false
        }
    },
    devSettings: {
        sendEmailPassword: ""
    },
    links: {
        privacyPolicy: "https://www.thezensory.com/privacy-policy",
        terms: "https://www.thezensory.com/terms-and-conditions",
        playStore: "https://play.google.com/store/apps/details?id=com.thezensory.app",
        appStore: "https://apps.apple.com/us/app/the-zensory/id1582845981"
    },
    autoRedeemCode: null,
    platformOverride: Platform.Web
}

export enum LicenseId {
    MonthlyStandard = "monthlyStandard",
    AnnualStandard = "annualStandard",
    CorporateStandard = "corporateStandard",
}

export enum RevenueCatLicenseParentType {
    MonthlyStandardDev = "$rc_monthly",
    AnnualStandardDev = "$rc_annual",

    MonthlyStandardProd = "$rc_monthly",
    AnnualStandardProd = "$rc_annual"
}

export enum RevenueCatLicenseType {
    MonthlyStandardAndroidDev = "zensory_dev_monthly",
    AnnualStandardAndroidDev = "zensory_dev_annual",
    MonthlyStandardIosDev = "dev_zensory_monthly",
    AnnualStandardIosDev = "dev_zensory_annual",

    MonthlyStandardAndroidProd = "zensory_monthly",
    MonthlyStandardIosProd = "BasePlan",
    AnnualStandardAndroidProd = "zensory_annual",
    AnnualStandardIosProd = "zensory_annual_subscription"
}