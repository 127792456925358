import cross from "../assets/icons/close.svg"
import SpinnerLoader from "./SpinnerLoader";
import { isSubmitKey, preventDefault, stopPropagation } from './UI/KeyPressHelper';
import {  useRef, useState } from "react";

const ConfirmCancelPopup = (props) => {

    const modalRef = useRef();

    const[loading, setLoading]=useState();
    const closePopup = (e) => {
        preventDefault(e);
        props.setShowAuthErrorPopup(false);
    }

    // handle return key presses on the close popup icon
    const _handleKeyDownClose = (e) => {
        stopPropagation(e);
        if (isSubmitKey(e)) {
            closePopup(e);
        }
    }

    return (
        <div className="popup-container-cancel" ref={modalRef}>
            <div className='popup-header'>
            <img 
                id="btn-auth-error-popup-close"
                src={cross} 
                className="popup-close-icon" 
                onClick={(e) => closePopup(e)} 
                alt="Close popup"
                tabIndex="0" 
                onKeyDown={(e) => _handleKeyDownClose(e)}
            />
            </div>
            <div className='popup-instruction-close'>
            Are you sure you want to cancel your subscription?
            </div>
            {loading? <div style={{position:"relative", marginTop:"20px"}}> <SpinnerLoader /></div> :
            <div style={{display:"flex", columnGap:"10px"}}>
            <button
                id="btn-auth-error-popup-okay"
                tabIndex="0"
                className="okay-button"
                onClick={(e) => {e.preventDefault(); setLoading(true); props.processCancel(props.license)}}>
                Yes
            </button>
            <button
                id="btn-auth-error-popup-okay"
                tabIndex="0"
                className="okay-button"
                onClick={(e) => closePopup(e)}>
                No
            </button>
            </div>
            }

        </div>
    );
};

export default ConfirmCancelPopup;