import { AudioDurationSecs, ExperienceId, MoodId, SenseId, SoundscapeId, SubSenseId, TouchpadId } from "../../Data/constants";
import { ExperienceConfigApi } from "../../API/ExperienceConfigApi";
import { getConfiguration } from "../../Util/ConfigHelper";
import { isNullOrEmpty } from "../../Data/Util/util";
import { EndExperienceConfig, EndExperienceType, ExperienceMoodConfig, ExperienceSubZenseConfig, ExperienceZenseConfig, getContactEndExperienceConfig, getNoneEndExperienceConfig, getSurveyEndExperienceConfig, MoodZenseSoundConfig, MoodZenseTouchConfig } from "../../Data/Models/configuration";
import { LOGGER, LogLevel } from "../../Util/Logger";

export class ExperienceConfigFileImpl implements ExperienceConfigApi {

    getMoods(): MoodId[] {
        const map = getConfiguration().contentConfig;
        const moods: MoodId[] = [];
        const moodIds = Object.keys(map) as MoodId[];
        for (const moodId of moodIds) {
            if (map[moodId]?.isShow) {
                moods.push(moodId);
            }
        }
        LOGGER.log(LogLevel.INFO, `moods=${moods}`);
        return moods;
    }

    getMood(moodId: MoodId): ExperienceMoodConfig | undefined {
        const map = getConfiguration().contentConfig;
        return map[moodId];
    }

    getZensesForMood(
        moodId: MoodId,
        options: {
            isOrder?: boolean
        } = {
            isOrder: false
        }
    ): SenseId[] {
        const mood = this.getMood(moodId);
        if (isNullOrEmpty(mood)) {
            throw new Error(`Not configured moodId=${moodId}`)
        }

        const zenses: SenseId[] = [];

        const zenseIds = Object.keys(mood!!.zenses) as SenseId[];
        var isOrdered = true;

        if (options.isOrder) {
            const zensesOrderMap: {[order: number]: SenseId} = {};
            for (const zenseId of zenseIds) {
                const zenseConfig = mood!!.zenses[zenseId];
                if (!zenseConfig?.isShow) {
                    continue;
                }

                if (zenseConfig?.order !== null && typeof zenseConfig?.order !== 'undefined') {
                    zensesOrderMap[zenseConfig!!.order!!] = zenseId;
                } else {
                    LOGGER.log(LogLevel.WARN, `getZensesForMood() Unable to order Zenses without each element having an 'order' field`);
                    isOrdered = false;
                    break;
                }
            }

            if (isOrdered) {
                const keys = Object.keys(zensesOrderMap).sort();
                for (const key of keys) {
                    zenses.push(zensesOrderMap[parseInt(key)]);
                }
            }
        }

        if (!isOrdered) {
            for (const zenseId of zenseIds) {
                if (mood!!.zenses[zenseId]?.isShow) {
                    zenses.push(zenseId);
                }
            }
        }

        LOGGER.log(LogLevel.INFO, `getZensesForMood() zenses=${JSON.stringify(zenses)}`);
        return zenses;
    }

    getMoodZense(moodId: MoodId, zenseId: SenseId): ExperienceZenseConfig | undefined {
        const map = getConfiguration().contentConfig;
        return map[moodId]?.zenses[zenseId];
    }
    
    getSubZenses(moodId: MoodId, zenseId: SenseId): SubSenseId[] {
        const mood = this.getMood(moodId);
        if (isNullOrEmpty(mood)) {
            throw new Error(`Not configured moodId=${moodId}`)
        }

        const zense = this.getMoodZense(moodId, zenseId);
        if (isNullOrEmpty(zense)) {
            throw new Error(`Not configured moodId=${moodId}, zenseId=${zenseId}`);
        }

        const subZenses: SubSenseId[] = [];
        const subZenseIds = Object.keys(zense!!.subZenses) as SubSenseId[];
        for (const subZenseId of subZenseIds) {
            if (zense?.subZenses[subZenseId]?.isShow) {
                subZenses.push(subZenseId);
            }
        }
        LOGGER.log(LogLevel.INFO, `getSubZenses() subZenses=${JSON.stringify(subZenses)}`);
        return subZenses;
    }

    getMoodZenseSubZense(moodId: MoodId, zenseId: SenseId, subZenseId: SubSenseId): ExperienceSubZenseConfig | undefined {
        const map = getConfiguration().contentConfig;
        return map[moodId]?.zenses[zenseId]?.subZenses[subZenseId];
    }
    
    getMoodZenseConfig(moodId: MoodId, zenseId: SenseId, subSenseId: SubSenseId): ExperienceSubZenseConfig {
        const config = this.getMoodZenseSubZense(moodId, zenseId, subSenseId);
        if (isNullOrEmpty(config)) {
            throw new Error(`Not configured moodId=${moodId}, zenseId=${zenseId}, subSenseId=${subSenseId}`);
        }
        LOGGER.log(LogLevel.INFO, `getMoodZenseConfig() config=${JSON.stringify(config)}`);
        return config!!;
    }

    getEndExperienceType(): EndExperienceType {
        return getConfiguration().endExperienceConfig.type;
    }
 
    getEndExperienceConfig(): EndExperienceConfig {

        const endExperienceType = this.getEndExperienceType();

        switch (endExperienceType) {
            case EndExperienceType.ContactForm:
                return getContactEndExperienceConfig();
    
            case EndExperienceType.Survey:
                return getSurveyEndExperienceConfig();
    
            case EndExperienceType.None:
                return getNoneEndExperienceConfig();
            default:
                throw new Error(`Invalid: ${endExperienceType}`);;
        }
    }

    isConfigured(moodId: MoodId | null, zenseId: SenseId | null, subZenseId: SubSenseId | null, experienceId: ExperienceId | null, audioDurationSecs: string | null): boolean {
        if (!isNullOrEmpty(moodId)) {    
            const mood = this.getMood(moodId!!);
            if (isNullOrEmpty(mood) || !mood?.isShow) {
                LOGGER.log(LogLevel.WARN, `isConfigured() Not configured, moodId=${moodId}`);
                return false;
            }

            if (!isNullOrEmpty(zenseId)) {
                const zense = this.getMoodZense(moodId!!, zenseId!!);
                if (isNullOrEmpty(zense) || !zense?.isShow) {
                    LOGGER.log(LogLevel.WARN, `isConfigured() Not configured, zenseId=${zenseId}`);
                    return false;
                }

                if (!isNullOrEmpty(subZenseId)) {
                    var subZense = this.getMoodZenseSubZense(moodId!!, zenseId!!, subZenseId!!);
                    if (isNullOrEmpty(subZense) || !subZense?.isShow) {
                        LOGGER.log(LogLevel.WARN, `isConfigured() Not configured, subZenseId=${subZenseId}`);
                        return false;
                    }

                    // if the audio duration is not configured
                    if (!isNullOrEmpty(audioDurationSecs) && isNullOrEmpty(subZense.audioDurations[parseInt(audioDurationSecs!!) as AudioDurationSecs])) {
                        LOGGER.log(LogLevel.WARN, `isConfigured() Not configured, audioDurationSecs=${audioDurationSecs}`);
                        return false;
                    }

                    if (!isNullOrEmpty(experienceId)) {
                        if (subZenseId === SubSenseId.Sound) {
                            const soundConfig = subZense as MoodZenseSoundConfig;
                            if (!soundConfig.isAllSoundscapes) {
                                const soundscape = soundConfig.soundscapeIds[experienceId as SoundscapeId];
                                if (isNullOrEmpty(soundscape) || !soundscape) {
                                    LOGGER.log(LogLevel.WARN, `isConfigured() Not configured, soundscapeId=${experienceId}`);
                                    return false;
                                }
                            }
                        } else if (subZenseId === SubSenseId.Touch) {
                            const touchConfig = (subZense as MoodZenseTouchConfig);
                            if (!isNullOrEmpty(touchConfig.touchpadIds) && isNullOrEmpty(touchConfig.touchpadIds!![experienceId as TouchpadId])) {
                                LOGGER.log(LogLevel.WARN, `isConfigured() Not configured, touchpadId=${experienceId}`);
                                return false;
                            }
                        }
                    }
                }
            }
        }

        return true;
    }

}