import { RouteId } from "../../../Routes";
import { ZensoryModule } from "../../../ZensoryModule";
import { AnalyticMainEventName } from "../../API/AnalyticsApi";
import { User, UserLicense, UserLicenseFull } from "../../Data/Models/app";
import { isNullOrEmpty } from "../../Data/Util/util";
import { Platform } from "../../Data/constants";
import { getConfiguration } from "../../Util/ConfigHelper";
import { LOGGER, LogLevel } from "../../Util/Logger";

export async function checkUserLicenses(
    user: User,
    licenses: UserLicense[]
) {
    if (isNullOrEmpty(licenses) || licenses.length === 0) {
        licenses = await ZensoryModule.getLicenses().getUserLicense(
            user.userId
        );
    }

    if (licenses && licenses.length === 0) {
        // if the user does not have a License but we have an autoRedeemCode, then redeem it
        const config = getConfiguration();
        const autoRedeemCode = config.autoRedeemCode;
        if (!isNullOrEmpty(autoRedeemCode)) {
            try {
                const licenses = await ZensoryModule.getLicenses().redeemCode(
                    user.userId,
                    autoRedeemCode!!,
                    config.platformOverride || Platform.Web
                );

                // track the event
                const license: UserLicenseFull = licenses[0] as UserLicenseFull; 
                ZensoryModule.getAnalytics().trackEvent(
                    AnalyticMainEventName.OnAutoRedeemCode,
                    {
                        subscriptionId: license.licenseId,
                        licenseId: license.licenseId,
                        companyId: license.companyId,
                        companyLicenseId: license.companyLicenseId,
                    }
                );
                LOGGER.log(LogLevel.DEBUG, `autoRedeemCode success, licenses=${JSON.stringify(licenses)}`);

                // call this function again to check if the user now has a license
                checkUserLicenses(user, licenses);
                return;

            } catch (error) {
                LOGGER.log(LogLevel.ERROR, `Error with autoRedeemCode, error=${error}`);

                // fall through to navigation to the subscribe page
            }
        }

        if (config.useLicenses) {
            // else navigate to the subscribe page for the user to purchase a license themselves
            ZensoryModule.getNavigationHandler().navigate(
                window,
                RouteId.Subscribe
            );
        }
    }
}