import { isNullOrEmpty } from "../Data/Util/util";
import { preventDefault, stopPropagation } from "../UI/KeyPressHelper";

export function handleTabKey(e, modalRef, focusableElements) {
  stopPropagation(e);
        
  const focusableModalElements = modalRef?.current?.querySelectorAll(
    focusableElements
  );

  if (isNullOrEmpty(e) || isNullOrEmpty(focusableModalElements) || focusableModalElements?.length === 0) {
    return;
  }

  const firstElement = focusableModalElements[0];
  const lastElement = focusableModalElements[focusableModalElements?.length - 1];
    
  if (e.shiftKey && document.activeElement === firstElement) {
    preventDefault(e);
    lastElement.focus();

  } else if (!e.shiftKey && document.activeElement === lastElement) {
    preventDefault(e);
    firstElement.focus();
  }

};