import React, { useEffect, useState } from "react";
import "../../styles/profile.css";
import { withScorm } from "react-scorm-provider";
import { ZensoryModule } from "../../ZensoryModule";
import { RouteId } from "../../Routes";
import { Interweave } from "interweave";
import { preventDefault, stopPropagation } from "../UI/KeyPressHelper";
import { getConfiguration } from "../Util/ConfigHelper";
import { GlobalAppState } from "../AppState";
import moment from "moment";
import { ProfileBox } from "../ProfileBox";
import { WeeklyStreak } from "../WeeklyStreak";
import { MoodInsights } from "../MoodInsights";
import { SensoryInsights } from "../SensoryInsights";
import { PastExperiences } from "../PastExperiences";
import { TextId, getText } from "../Util/TextHelper";
import { AnalyticsScreenName } from "../API/AnalyticsApi";
import AuthErrorPopup from "../AuthErrorPopup";
import logOutIcon from "../../assets/icons/logoutIcon.png";
import logo from "../../assets/icons/zensory_logo.svg";
import { ManageSubscriptionBox } from "../ManageSubcriptionBox";
import back from "../../assets/icons/arrow_back.svg"

const ProfilePage = () => {
  ZensoryModule.getAnalytics().trackScreen(AnalyticsScreenName.ScreenProfile);

  useEffect(() => {
    async function getUserStats() {
      const res = await ZensoryModule.getData().getUserStats();
      setUserStats(JSON.parse(res));
    }
    async function getHistory() {
      //double check with Sam on when to fetch data from
      const res = await ZensoryModule.getData().getPastExperiences(
        0,
        moment().unix() * 1000,
        30
      );
      setPastExperiences(res);
    }

    getUserStats();
    getHistory();
  }, []);

  const [userStats, setUserStats] = useState();
  const [pastExperiences, setPastExperiences] = useState();
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [logoutErrorMessage, setLogoutErrorMessage] = useState();

  const isShowLogoutButton = ZensoryModule.getFeatureUi().isShowLogoutButton();

  const appUser = GlobalAppState.getUser();

  const isShowManageSubscription = ZensoryModule.getFeatureUi().isShowManageSubscription();

  // navigate back to home page on back click
  const backNagivate = async (e) => {
    preventDefault(e);
    ZensoryModule.getNavigationHandler().navigate(window, RouteId.Root);
  };

  // when the version text is clicked
  const onVersionClicked = async (e) => {
    preventDefault(e);
    stopPropagation(e);
    await ZensoryModule.getErrorHandler().sendProblemReport();
  };

  const getTopNav = (showLogout) => {
    return (
      <div className="header-row-profile">
        <div className="btn-back-profile" onClick={(e) => backNagivate(e)}>
        <img src={back} alt="Back button" height={22} />
          {getText(TextId.profileBackText)}
        </div>
        <img src={logo} className="logoProfile" height={50} alt="Zensory logo" />

        {isShowLogoutButton && showLogout ? (
          <div onClick={(e) => logOut(e)} className="logoutButtonContainer">
            <img src={logOutIcon} height={20} alt="Logout" />
            <div className="btn-back-profile" onClick={(e) => backNagivate(e)}>
              Logout
            </div>
          </div>
        ) : <div style={{cursor:"no-drop"}} className="logoutButtonContainer">
        <img src={logOutIcon} height={20} alt="Logout" />
        <div className="btn-back-profile" onClick={(e) => {}}>
              Logout
            </div>
        </div>}
      </div>
    );
  };

  // show loading till data is populated
  if (!userStats || !pastExperiences) {
    return (
      <div className="profile-content-container">
        {getTopNav()}
        <div className="loading-container">
          <h3 style={{ color: "white", fontFamily: "Poppins" }}>
            {getText(TextId.loadingText)}
          </h3>
        </div>
      </div>
    );
  }

  const logOut = async (e) => {
    preventDefault(e);
    await ZensoryModule.getAuth()
      .logout()
      .then(() => {
        ZensoryModule.getNavigationHandler().navigate(window, RouteId.Login);
      })
      .catch((error) => {
        setLogoutErrorMessage(error.message);
        setShowLogoutPopup(true);
      });
  };

  return (
    <>
      {showLogoutPopup ? (
        <AuthErrorPopup
          setShowAuthErrorPopup={setShowLogoutPopup}
          errorMessage={logoutErrorMessage}
        />
      ) : null}
      <div className="profile-content-container">
        {getTopNav(true)}
        <div className="profile-container">
          {/* First column */}
          
          <div className="profile-left-content">
            <ProfileBox
              currentStreak={userStats.currentStreak}
              numStreakDaysRemaining={userStats.numStreakDaysRemaining}
              user={appUser}
            />
            {isShowManageSubscription && 
              <ManageSubscriptionBox/>
            }
          </div>
          
          {/* Center column */}

          <div className="profile-main-content">
            <WeeklyStreak streakStats={userStats.weekStreak} />
            <MoodInsights moodStats={userStats.moodInsights} />
            <SensoryInsights sensoryStats={userStats.sensoryInsights} />
          </div>

          {/* Right column */}

          <div className="profile-right-content">
            <PastExperiences exp={pastExperiences} />
          </div>
        </div>

        <div
          className="web-version"
          id="version-welcome"
          onClick={(e) => onVersionClicked(e)}
        >
          <Interweave content={`Version: ${getConfiguration().version}`} />
        </div>
      </div>
    </>
  );
};

const Profile = withScorm()(ProfilePage);

export default Profile;
