import { useEffect, useState } from "react";
import "../styles/breathwork.css"
import "../styles/boxBreathing2.css"
import { AudioTriggerId, SenseId } from "./Data/constants";
import { ZensoryModule } from "../ZensoryModule";
import { getScreenOrientation } from "./Util/ScreenUtil";
import { LOGGER, LogLevel } from "./Util/Logger";
import { AudioType, getNewAudioPlayer } from "./API/AudioPlayerAPI";
import { audioObjMapToArray, getAudioObjLevel } from "./Util/AudioHelper";

const VisualGuidedThreePartRelax = (props) => {

    let isPlayAudio = true;

    const [audioPlayer, setAudioPlayer] = useState(null);
    const [audioClips, setaudioClips] = useState({});

    const container = document.querySelector('.breath-container');
    const text = document.querySelector('#text');
    const topText = document.querySelector('#top-text');
    const starting = document.querySelector('#start-message');

    // Change values here and in container.grow and container.shrink
    let inhaleTimeSec = 4;      // seconds
    let exhaleTimeSecs = 6;     // seconds
    let holdTimeSecs = 4;       // seconds

    let COUNT = 1250;           // each count should be 1.25 seconds
    let inhaleTime = inhaleTimeSec * COUNT;   // milli-seconds
    let exhaleTime = exhaleTimeSecs * COUNT;   // milli-seconds
    let holdTime = holdTimeSecs * COUNT;     // milli-seconds
    let totalTime = inhaleTime + exhaleTime + (2 * holdTime);

    let inhaleCount = -1;
    const inhaleTexts = ['to your into your lower tummy', 'into your lower tummy and then into your chest', 'into your lower tummy, then your chest and finally into your shoulders'];
    const maxInhaleCount = inhaleTexts.length;
    const exhaleText = 'relax ';

    let iterationCount = 0;
    let intervalId;

    const restText = 'Rest<span>return to normal breath</span>';

    // defaults
    let countdownTime = 5;
    let numIterations = 999;
    let restIntervalSecs = 0;

    let hasStartedLocal = false;

    useEffect(() => {
        setaudioClips(audioClips => {
            LOGGER.log(LogLevel.DEBUG, `setaudioClips() started`);
            const audioMap = ZensoryModule.getAudioLoader().getBreathAudioTriggerMap();
            const audioTriggers = {};
            Object.keys(audioMap).forEach(key => {
                audioTriggers[key] = {
                id: key,
                type: AudioType.VisualBreathTrigger,
                uri: audioMap[key],
                isUnlimited: false,
                volume: getAudioObjLevel(props.zense, props.experience, AudioType.VisualBreathTrigger)
                };
            });
            return audioTriggers;
        });
    }, []);

    useEffect(() => {
        const audios = audioObjMapToArray(audioClips);
        if (audios.length > 0) {
            const setAudioPlayerAndLoad = async () => {
                LOGGER.log(LogLevel.DEBUG, `VisualGuidedThreePartRelax useEffect([audioClips]) Starting Breath trigger load for audios.len=${audios.length}`);
                const audioPlayerLocal = getNewAudioPlayer(
                    audios,
                    // on load
                    () => {
                        LOGGER.log(LogLevel.DEBUG, `VisualGuidedThreePartRelax useEffect([audioClips]) Breath trigger audios loaded`);
                        setAudioPlayer(audioPlayerLocal);
                        props.setVisualBreathTriggerVolume(getAudioObjLevel(SenseId.Breath, null, AudioType.VisualBreathTrigger));
                        props.setHasLoadedVisualBreath(true);
                    },
                    // on error
                    (error) => {
                        LOGGER.log(LogLevel.ERROR, `VisualGuidedThreePartRelax useEffect([audioClips]) audio player error=${error}`);
                        // TODO: set Experience error prop
                    }
                );
                await audioPlayerLocal.load();
            };
            setAudioPlayerAndLoad();
        }
    }, [audioClips]);

    useEffect(() => {
        if (starting && !hasStartedLocal && props.hasStarted) {
            startCountdown(countdownTime, starting, startBreathing);
        }
    }, [props.isPlaying, starting, props.hasStarted]);

    const pauseAudioTriggers = () => {
        LOGGER.log(LogLevel.DEBUG, `pauseAudioTriggers() started`);
        
        if (audioPlayer !== null) {
            audioPlayer.pause();
            audioPlayer.destroy();
        }
    }

    useEffect(() => {
        if (props.expired) {
            pauseBreathing();
            pauseAudioTriggers();
        }
    }, [props.expired]);

    useEffect(() => {
        if (audioPlayer !== null) {
          audioPlayer.setVisualBreathTriggerVolume(props.visualBreathTriggerVolume);
        }
    }, [props.visualBreathTriggerVolume]);

    const playAudio = (id) => {
        if (props.expired || !isPlayAudio || audioPlayer === null) {
            return;
        }

        audioPlayer.playAudioObj(audioClips[id]);
    }

    // Start the breathing

    const startCountdown = (numSecs, textView, nextFunction) => {
        if (numSecs <= 0) {
            textView.innerHTML = '';
            nextFunction();
            return;
        }

        textView.innerHTML = `Starting in ${numSecs}`;

        setTimeout(() => {
            startCountdown(numSecs - 1, textView, nextFunction);
        }, COUNT);
    }

    const breathAnimation = () => {
        // if the number of iterations is done, go to a rest time
        if (iterationCount >= numIterations) {
            pauseBreathing();

            text.innerHTML = restText;
            topText.innerHTML = '';

            startBreathingCountdown(restIntervalSecs);

            setTimeout(() => {
                iterationCount = 0;
                startBreathing();
            }, restIntervalSecs * COUNT);

            return;
        }

        // Inhale
        inhaleCount++;

        if (inhaleCount >= maxInhaleCount) {
            inhaleCount = 0;
        }

        startBreathingCountdown(inhaleTimeSec, AudioTriggerId.In1);
        text.innerHTML = `Inhale<span>${inhaleTexts[inhaleCount]}</span>`;
        container.className = 'breath-container grow'; // add a growth class to the container to enlarge the effect

        // Trigger after inhaleTime
        setTimeout(() => {
            if (holdTime > 0) {
                text.innerHTML = 'Hold';
                startBreathingCountdown(holdTimeSecs, AudioTriggerId.Hold1);
            }

            // Trigger after hold
            setTimeout(() => {
                text.innerHTML = `Exhale<span>${exhaleText}</span>`;
                startBreathingCountdown(exhaleTimeSecs, AudioTriggerId.Out1);
                container.className = 'breath-container shrink'; // add a shrink class to the container to reduce the size

                // Trigger after exhaleTime
                setTimeout(() => {
                    if (holdTime > 0) {
                        text.innerHTML = 'Hold';
                        startBreathingCountdown(holdTimeSecs, AudioTriggerId.Hold1);
                    }

                    // only increment the iteration after doing 1 full loop of all the inhales
                    if (inhaleCount === maxInhaleCount - 1) {
                        iterationCount++;
                    }

                }, exhaleTime);

            }, holdTime);

        }, inhaleTime);
    }

    const startBreathing = () => {
        breathAnimation();
        intervalId = setInterval(breathAnimation, totalTime); // repeat
    }

    const pauseBreathing = () => {
        clearInterval(intervalId);
    }

    const startBreathingCountdown = (maxCount, type = null) => {
        startBreathingCountdownAux(1, maxCount, type);
    }

    const startBreathingCountdownAux = (startCount, maxCount, audioTriggerId = null) => {
        topText.innerHTML = ` ${startCount} `;

        if (audioTriggerId !== null) {
            playAudio(audioTriggerId);
        } else {
            playAudio("num" + startCount);
        }

        if (maxCount > startCount) {
            setTimeout(() => {
                startBreathingCountdownAux(startCount + 1, maxCount, null);
            }, COUNT);
        }
    }

    const img = ZensoryModule.getImageLoader().getBreathExperienceImage(props.mood, props.subZense, getScreenOrientation());

    return (
        <div style={{
            background: `url(${img.highRes}) no-repeat center center /cover`,
            height: "100vh",
            width: "100vw",
            fontFamily: "Helvetica, sans-serif",
            color: "#fff",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <div className="breath-container">
                <div className="circle"></div>
                <div id="starting">
                    <div id="start-message"></div>
                </div>
                <div id="top-text"></div>
                <div id="text" className="texty"></div>
                <div className="pointer-container">
                    <div className="pointer"></div>
                </div>
                <div className="gradient-circle-relax">
                </div>
            </div>
        </div>
    )
};

export default VisualGuidedThreePartRelax;