import { AnalyticsApi } from "../API/AnalyticsApi";
import { AudioLoaderApi } from "../API/AudioLoaderApi";
import { AuthApi } from "../API/AuthApi";
import { DataApi } from "../API/DataApi";
import { ErrorHandlerApi } from "../API/ErrorHandlerApi";
import { ExperienceConfigApi } from "../API/ExperienceConfigApi";
import { FeatureUiApi } from "../API/FeatureUiApi";
import { ImageLoaderApi } from "../API/ImageLoaderApi";
import { LicenseApi } from "../API/LicenseApi";
import { NavigationHandlerApi } from "../API/NavigationHandlerApi";
import { SessionHandlerApi } from "../API/SessionHandlerApi";
import { ProviderApi } from "./ProviderApi";

/**
 * This class stores Singleton instances of each Interface used by the platform
 */
export class Module {

    private imageLoaderApi: ImageLoaderApi;
    private audioLoaderApi: AudioLoaderApi;
    private authApi: AuthApi;
    private analyticsApi: AnalyticsApi;
    private dataApi: DataApi;
    private sessionHandlerApi: SessionHandlerApi;
    private navigationHandlerApi: NavigationHandlerApi;
    private featureUiApi: FeatureUiApi;
    private experienceConfigApi: ExperienceConfigApi;
    private errorHandlerApi: ErrorHandlerApi;
    private licensesApi: LicenseApi;

    constructor(provider: ProviderApi) {
        this.imageLoaderApi = provider.provideImageLoader();
        this.audioLoaderApi = provider.provideAudioLoader();
        this.authApi = provider.provideAuth();
        this.analyticsApi = provider.provideAnalytics();
        this.dataApi = provider.provideData();
        this.sessionHandlerApi = provider.provideSessionHandler();
        this.navigationHandlerApi = provider.provideNavigationHandler();
        this.featureUiApi = provider.provideFeatureUi();
        this.experienceConfigApi = provider.provideExperienceConfig();
        this.errorHandlerApi = provider.provideErrorHandler();
        this.licensesApi = provider.provideLicenses();
    }

    getImageLoader(): ImageLoaderApi {
        return this.imageLoaderApi;
    }
    
    getAudioLoader(): AudioLoaderApi {
        return this.audioLoaderApi;
    }

    getAuth(): AuthApi {
        return this.authApi;
    }

    getAnalytics(): AnalyticsApi {
        return this.analyticsApi;
    }

    getData(): DataApi {
        return this.dataApi;
    }

    getSessionHandler(): SessionHandlerApi {
        return this.sessionHandlerApi;
    }

    getNavigationHandler(): NavigationHandlerApi {
        return this.navigationHandlerApi;
    }

    getFeatureUi(): FeatureUiApi {
        return this.featureUiApi;
    }

    getExperienceConfig(): ExperienceConfigApi {
        return this.experienceConfigApi;
    }

    getErrorHandler(): ErrorHandlerApi {
        return this.errorHandlerApi;
    }

    getLicenses(): LicenseApi {
        return this.licensesApi;
    }
}

