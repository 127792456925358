import React, { useEffect } from "react";
import "../styles/explosiveAttraction.css"

const ExplosiveAttraction = (props) => {
  const url = `explosiveAttraction.js`;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;

    document.body.appendChild(script);
    const startDiv = document.getElementById('startAttraction');
    startDiv.click();
    const stopDiv = document.getElementById('stopAttraction');
    
    return () => {
      stopDiv.click();
      document.body.removeChild(script);
    }
  }, [url]);
  
  return (
    <>
      <div id="stopAttraction"></div>
      <div id="startAttraction"></div>
      <canvas id="explosive"></canvas>
    </>
  );
}

export default ExplosiveAttraction;