import { useEffect, useState } from "react";
import { preventDefault } from "./UI/KeyPressHelper";
import { ZensoryModule } from "../ZensoryModule";

const LoadExperienceOverlay = (props) => {

    const [viewStartFocus, setStartFocusView] = useState(null);

    useEffect(() => {
        if (viewStartFocus != null) {
            viewStartFocus.focus();
        }
    }, [viewStartFocus]);

    // when the title text is clicked trigger a problem report
    const onLoadingClicked = async (e) => {
        preventDefault(e);
        await ZensoryModule.getErrorHandler().sendProblemReport();
    }

    return (
        <div className="start-exp-overlay-container">
            <h1 className="overlay-heading"
                id="div-loading-popup"
                role="alert"
                aria-busy="true"
                aria-label="Loading Experience"
                aria-live="assertive"
                tabIndex={0}
                onClick={(e) => onLoadingClicked(e)}
                ref={(view) => { setStartFocusView(view); }}
            >
            Loading...
            </h1>
        </div>
)
};

export default LoadExperienceOverlay;