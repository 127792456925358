import { AudioDurationSecs, ColourId, ContentSetupId, EnvId, ExperienceCategory, ExperienceCustomiseOptionId, ExperienceExpertId, ZensoryExperienceId, ExpertId, InfoPageId, MenuOptionId, MoodId, Platform, QuickStartExperienceId, SenseContentClickAction, SenseId, SoundscapeId, TextOptionsId, TouchpadId, UserActivityType, NotifPrefWeekDay, StatsTimeFrame, LicenseId, RevenueCatLicenseParentType, RevenueCatLicenseType } from "../constants";

export interface BaseModel {
  createdAt: number;
  updatedAt: number;
}

export interface User extends BaseModel {
  userId: string;
  name?: string | null;
  email?: string | null;
  language: string;
  country: string;
  devices?: { [deviceId: string]: UserDevice };
  installReferrer?: string | null;
  flags: UserFlags | null | undefined;
  lastInteractionMillis: number | null | undefined;
  notifTopics?: { [topic: string]: boolean };
  statsSummary: UserStats | null | undefined;
  isPowerUser?: boolean;
  companyId?: string | null | undefined;
  timezone?: string | null | undefined;
  deletedAt?: number | null | undefined;
}

export interface Company {
  companyId: string;
  name: string;
  website: string;
  domains: string[];
  logoUrl: string;
  numUsers?: number;
}

export interface UserDevice {
  deviceId: string;
  os: Platform;
  manufacturer?: string | null | undefined;
  model?: string | null | undefined;
  token?: string | null | undefined;
}

export interface UserFlags {
  isEmailRegistered: boolean | null | undefined;
  welcomeEmail: boolean | null | undefined;
  surveyEmail01: boolean | null | undefined;
  inactive5Days: boolean | null | undefined;
  powerUser2021_01_07?: boolean | null | undefined;
  updateEmailValues2022_02_16?: boolean | null | undefined;
}

export const DEFAULT_USER_FLAGS: UserFlags = {
  isEmailRegistered: false,
  welcomeEmail: false,
  surveyEmail01: false,
  inactive5Days: false,
  powerUser2021_01_07: false,
  updateEmailValues2022_02_16: false,
};

export interface Experience {
  experienceId: ZensoryExperienceId;
  enabled: boolean;
  labels: ExperienceLabels;
  properties: ExperienceProperties;
  affirmations: string[];
  binaurals: { [key in AudioDurationSecs]?: Binaural };
  music: { [key in AudioDurationSecs]?: Music };
  senses: ExperienceSense[];
  experts: ExperienceExpert[];
  urlQueryArgs: {};
}

export interface ExperienceLabels {
  name: string;
  adjective: string;
  noun: string;
  endQuestionTitle: string;
  questionTitleHtml: string;
  shortDesc: string;
}

export interface UserExperienceRecord {
  experience: Experience;
  title: string | null | undefined;
  experienceId: ZensoryExperienceId | null | undefined;
  moodId?: MoodId | null | undefined;
  startedAt: number | null | undefined;
  endedAt: number | null | undefined;
  intention: string;
  notes: string;
  didLoop?: boolean | null | undefined;
  durationSecs: number;
  didComplete: boolean;
  percentageTimeCompleted: number;
  userRatingBefore: number | null | undefined;
  userRatingAfter: number;
  options: { string: object };
}

export interface ExperienceProperties {
  categoryId: ExperienceCategory;
  orderNum: number;
  colourId: ColourId;
  iconFileUrlOverride: string | null | undefined;
  isZoneIn: boolean;
  moodImageUrl: string;
}

export interface BaseAudioModel {
  audioUrl: string;
  lengthSecs: number;
}

export interface Binaural extends BaseAudioModel {
  binauralId: string;
  enabled: boolean;
  experienceId: string;
}

export interface Music extends BaseAudioModel {
  musicId: string;
  enabled: boolean;
  experienceId: ZensoryExperienceId;
}

export interface Soundscape {
  soundscapeId: SoundscapeId;
  name: string;
  selectImageUrl: string;
  backgroundImageUrl: string;
  backgroundLandscapeImageUrl: string;
  audios: { [key in AudioDurationSecs]?: string };
  animatedBackgroundImageUrl: string | null | undefined;
  index?: number;
}

export interface Whitenoise extends BaseAudioModel {
  whitenoiseId: string;
  enabled: boolean;
  experienceId: ZensoryExperienceId;
}

export interface Touchpad {
  touchpadId: TouchpadId;
  name: string;
  imageUrl: string;
  screenPrompt: string;
  backgroundTransparency: number;
}

export interface EnvImageMap {
  map: { [envId in EnvId]: { [soundscapeId in SoundscapeId]: string } };
}

export interface InspirationalQuote {
  text: string;
}

export interface Colour {
  colourId: string;
  mainHex: string;
  shade1Hex: string;
  shade2Hex: string;
  shade3Hex: string;
  shade4Hex: string;
}

export interface Sense {
  senseId: SenseId;
  title: string;
  subTitle: string | null | undefined;
  iconUrl: string;
  hideInSenses: boolean;
  contentAndSetup: { [contentSetupId in ContentSetupId]?: SenseContentSetup };
}

export interface SenseContentSetup {
  contentSetupId: ContentSetupId;
  content: SenseContent[];
  toggleButtonText: string | null | undefined;
  hideMoodSelector: boolean;
  addAllInMoodSelector: boolean;
  globalContentTitle: string | null | undefined;
  globalContentSubTitle: string | null | undefined;
  infoPageId: InfoPageId | null | undefined;
}

export interface SenseContent {
  contentId: string;
  moods: { [moodId in MoodId]: boolean };
  title: string | null | undefined;
  subTitle: string | null | undefined;
  contentImageUrl: string | null | undefined;
  backgroundImageUrl: string | null | undefined;
  description: string | null | undefined;
  longDescription: string | null | undefined;
  onContentClickAction: SenseContentClickAction | null | undefined;
  buttonText: string | null | undefined;
  breathingCodepenUrl: string | null | undefined;
  breathingDurationSecs: number | null | undefined;
  videoUrl: string | null | undefined;
  hideBgImgOnMoreInfoPage: boolean;
  moreInfoUrl: string | null | undefined;
  moreInfoButtonText: string | null | undefined;
  audioUrl: string | null | undefined;
  infoPageId: InfoPageId | null | undefined;
}

export interface Mood {
  moodId: MoodId;
  title: string;
  iconUrl: string;
  iconWithColorUrl: string;
}

export interface InfoPage {
  infoPageId: InfoPageId;
  title: string;
  titleIconUrl: string;
  section1Title: string;
  section1Body: string;
  section1IconUrl: string;
  section2Title: string | null | undefined;
  section2Body: string | null | undefined;
  section2IconUrl: string | null | undefined;
}

export interface ExperienceConfig {
  experienceId: ZensoryExperienceId;
  experienceSenseId: SenseId | null | undefined;
  experienceExpertId: ExperienceExpertId | null | undefined;
  soundscapeId: SoundscapeId | null | undefined;
  lengthSecs?: AudioDurationSecs | null | undefined;
  affirmationsEnabled: boolean;
  autoStart?: boolean | null | undefined;
  loop?: boolean | null | undefined;
}

export interface QuickStartExperience {
  quickStartExperienceId: QuickStartExperienceId | string;
  experienceConfig: ExperienceConfig;
  title: string;
  subTitle: string;
  imageUrl: string;
  isZoneIn: boolean;
  moodTitle: string;
  moodColourHex: string;
  orderMorning: number;
  orderAfternoon: number;
  orderEvening: number;
  orderV2: number;
  iconUrls: string[];
  canLoop: boolean;
  durations: AudioDurationSecs[];
}

export interface TextOptions {
  textOptionsId: TextOptionsId;
  textOptions: string[];
}

export interface UserActivity {
  activityId?: string | null | undefined;
  type: UserActivityType;
  timestampMillis: number;
  experienceId?: ZensoryExperienceId | null | undefined;
  naturescapeId?: SoundscapeId | null | undefined;
  durationSecs?: number | null | undefined;
  timeSpentSecs?: number | null | undefined;
  experienceOptions?: { [optionId: string]: boolean } | null | undefined;
  experienceRatingBefore?: number | null | undefined;
  experienceRatingAfter?: number | null | undefined;
  experienceSource?: string | null | undefined;
  senseId?: SenseId | null | undefined;
  senseContentId?: string | null | undefined;
  contentId?: string | null | undefined;
  senseFilterMoodId?: string | null | undefined;
  senses?: SenseId[] | null | undefined;
  focusGoal?: string | null | undefined;
  numFocusSessions?: number | null | undefined;
  focusNaturescapes?: SoundscapeId[] | null | undefined;
  focusMoodRatings?: number[] | null | undefined;
  relaxSenseIds?: SenseId[] | null | undefined;
  didLoop?: boolean | null | undefined;
}

export interface UserStats {
  statsId: string;
  timestampMillis: number;
  numByType: { [type in UserActivityType]?: number };
  isStreak: boolean;
  numExperiencesByMood: { [moodId in MoodId]: number };
  numExperiencesByNature: { [soundscapeId in SoundscapeId]: number };
  numExperiencesBySense: { [senseId in SenseId]: number };
  score: number;
  ratingChangesByMood: { [moodId: string]: number };
  totalDurationTargetSecs: number;
  totalTimeSpentSecs: number;
  focusTimeTargetSecs?: number;
  focusTimeSpendSecs?: number;
  longestStreak?: number;
}

export const DEFAULT_USER_STATS: UserStats = {
  statsId: "",
  timestampMillis: 0,
  numByType: {},
  isStreak: false,
  numExperiencesByMood: {
    creative: 0,
    focused: 0,
    relax: 0,
    positive: 0,
    all: 0
  },
  numExperiencesByNature: {
    none: 0,
    beach: 0,
    tropicalBeach: 0,
    fireplace: 0,
    forest: 0,
    meadow: 0,
    rainforest: 0,
    garden: 0,
    rain: 0,
    whiteNoise: 0,
    noNature: 0,
    brownNoise: 0,
    babblingBrook: 0,
    desert: 0,
    lakeAtNight: 0,
    mountain: 0,
    ocean: 0,
    train: 0
  },
  numExperiencesBySense: {
    taste: 0,
    smell: 0,
    touch: 0,
    breath: 0,
    pressure: 0,
    movement: 0,
    sound: 0,
    tapping: 0,
    noNature: 0
  },
  score: 0,
  ratingChangesByMood: {},
  totalDurationTargetSecs: 0,
  totalTimeSpentSecs: 0
};

export interface UserStatsSummary extends UserStats {
  topStatTitleText: string;
  topStatSubTitleText: string;
  topStatIconUrl: string;
}

export interface ExperienceLoading {
  topTextHtml: string;
  bottomTextHtml: string;
  bottomIconUrl: string;
  bottomNoAudioTextHtml: string;
  bottomNoAudioIconUrl: string;
}

export interface ExperienceSense {
  senseId: SenseId;
  contentSetupId: ContentSetupId;
  subTitle: string;
  durations: AudioDurationSecs[];
  loading: ExperienceLoading;
  customiseDefaults: { [optionId in ExperienceCustomiseOptionId]: any };
  customiseShowOptions: { [optionId in ExperienceCustomiseOptionId]: boolean };
  menuOptions: { [menuOptionId in MenuOptionId]: boolean };
  detailsPage: DetailsPage | null | undefined;
  videos: { [durationSecs in AudioDurationSecs]?: string } | null | undefined;
  breathings: { [durationSecs in AudioDurationSecs]?: string } | null | undefined;
  breathingTimingsSecs: { [durationSecs in AudioDurationSecs]?: number } | null | undefined;
  breathingBackgroundImg: string | null | undefined;
  contentItems: ExperienceContentItem[] | null | undefined;
  canLoop: boolean;
}

export interface ExperienceContentItem {
  contentId: string;
  title: string | null | undefined;
  description: string | null | undefined;
  imageUrl: string | null | undefined;
  durationSecs: number;
}

export interface DetailsPage {
  pageId: string;
  title: string;
  description: string | null | undefined;
  imageUrl: string;
  infoPageId: string | null | undefined;
  footerText?: string;
}

export interface ExperienceExpert {
  experienceExpertId: ExperienceExpertId;
  expertId: string;
  senseId: string;
  title: string;
  subTitle: string;
  expertImageUrl: string;
  durationSecs: number;
  videoUrl: string | null | undefined;
  audioUrl: string | null | undefined;
  contentItem: ExperienceContentItem | null | undefined;
  audioBackgroundImageUrl: string | null | undefined;
  customiseDefaults: { [optionId in ExperienceCustomiseOptionId]: any };
  customiseShowOptions: { [optionId in ExperienceCustomiseOptionId]: boolean };
  menuOptions: { [menuOptionId in MenuOptionId]: boolean };
  loading: ExperienceLoading;
}

export interface Expert {
  expertId: ExpertId;
  name: string;
  title: string;
  profileImageUrl: string;
  moods: string[];
  senseIds: string[];
}

export interface AudioLevels {
  binnaural: number;
  music: number;
  soundscape: number;
  breathTrigger: number;
  audioBreath: number;
  visualBreathTrigger: number;
  binnauralWhiteBrownNoise: number;
  soundscapeWhiteBrownNoise: number;
}

export interface MultiResolutionImgHolder {
  highRes: string;
  lowRes: string;
}


export interface UserProfileStats {
  currentStreak: number;
  numStreakDaysRemaining: number;
  weekStreak: { [day in NotifPrefWeekDay]: boolean | null };
  moodInsights: { [timeFrame in StatsTimeFrame]: { [moodId in MoodId]?: number } };
  sensoryInsights: { [senseId in SenseId]?: number };
}

export interface License {
  licenseId: LicenseId;
  name: string;
  description: string;
  revenueCatIdentifier: RevenueCatLicenseParentType | null | undefined;
  revenueCatIdentifierAndroid: RevenueCatLicenseType | null | undefined;
  revenueCatIdentifierIos: RevenueCatLicenseType | null | undefined;
  displayTitle: string;
  featuresList: string[];
}

export interface UserLicense {
  userLicenseId: string;
  userId: string;
  licenseId: LicenseId;
  platform: Platform;
  companyLicenseId: string;
  createdAt: number;
  expiresAt: number;
  cancelledAt: number;
}

export interface UserLicenseFull extends UserLicense {
  license: License;
  companyId: string | null | undefined;
}
