import { SessionHandlerApi } from "../API/SessionHandlerApi";
import { GlobalAppState, setAppStateToStart } from "../AppState";

export class SimpleSessionHandler implements SessionHandlerApi {

    onExperienceReviewFinish(window: Window & typeof globalThis, scorm: any | null, isExit: boolean): void {
        // go back to the start 
        setAppStateToStart(GlobalAppState);
    }

    isCompleted(): boolean {
        return true;
    }

}