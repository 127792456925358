import React, { useEffect } from "react";

const WigglySquiggly = () => {
    const url = "wigglysquiggly.js"

    useEffect(() => {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
  
      document.body.appendChild(script);
      const startDiv = document.getElementById('startSquiggles');
      startDiv.click();
      const stopDiv = document.getElementById('stopSquiggles');
      
      return () => {
        stopDiv.click();
        document.body.removeChild(script);
      }
    }, [url]);

    return (
      <>
        <div id="stopSquiggles"></div>
        <div id="startSquiggles"></div>
        <div id="wigglysquiggly">
          <svg id="stage" width="200" height="200" xmlns="http://www.w3.org/2000/svg"></svg>
        </div>
      </>
    );
};

export default WigglySquiggly;
