import { ZensoryModule } from "../../../ZensoryModule";
import { AnalyticsApi, AnalyticsSummary } from "../../API/AnalyticsApi";
import { SessionHandlerApi } from "../../API/SessionHandlerApi";
import { GlobalAppState, setAppStateToStart } from "../../AppState";
import { LOGGER, LogLevel } from "../../Util/Logger";

export class ScormSessionHandler implements SessionHandlerApi {

    analyticsApi: AnalyticsApi;
    hasSetId0: boolean = false;

    constructor(analyticsApi: AnalyticsApi) {
        this.analyticsApi = analyticsApi;
    }

    async onExperienceReviewFinish(window: Window & typeof globalThis, scorm: any | null, isExit: boolean): Promise<void> {
        // track all the scorm values

        const statsSummary: AnalyticsSummary = this.analyticsApi.getSessionSummaryJson();

        LOGGER.log(LogLevel.WARN, `statsSummary=${statsSummary}`, statsSummary);
        
        if (scorm) {
            if (!this.hasSetId0) {
                // set arbitrary objective id to 0
                try {
                    await scorm.set('cmi.objectives.0.id', 0);
                    this.hasSetId0 = true;
                    LOGGER.log(LogLevel.WARN, `Set objective.0.id to 0`);
                } catch (e) {
                    LOGGER.log(LogLevel.ERROR, `Error setting objective.0.id: ${e}`);
                    ZensoryModule.getErrorHandler().handleError(e);
                }
            }

            await scorm.setScore({
                value: statsSummary.score,
                min: 0,
                max: 100
            });
            const scoreScaledDecimal = Math.round(10 * (statsSummary.score / 100.0)) / 10.0;
            await scorm.set('cmi.objectives.0.score.raw', statsSummary.score);
            await scorm.set('cmi.objectives.0.score.min', 0);
            await scorm.set('cmi.objectives.0.score.max', 100);

            await scorm.set('cmi.score.raw', statsSummary.score);
            await scorm.set('cmi.score.min', 0);
            await scorm.set('cmi.score.max', 100);
            await scorm.set('cmi.score.scaled', scoreScaledDecimal);

            var completionStatus;
            var successStatus;
            var progressMeasure = statsSummary.score;
            if (progressMeasure > 100) {
                progressMeasure = 100;
            }
            if (statsSummary.score === 100) {
                completionStatus = 'completed';
                successStatus = 'passed';
            } else {
                completionStatus = 'incomplete';
                successStatus = 'unknown';
            }

            await scorm.set('cmi.objectives.0.completion_status', completionStatus);
            await scorm.set('cmi.completion_status', completionStatus);
            await scorm.set('cmi.objectives.0.success_status', successStatus);
            await scorm.set('cmi.success_status', successStatus);
            
            // convert progress measure from percentage to decimal
            await scorm.set("cmi.progress_measure", scoreScaledDecimal)  // set progress measure to minimum progress measure

            try {
                await scorm.set("cmi.suspend_data", JSON.stringify(statsSummary));
            } catch (e) {
                LOGGER.log(LogLevel.ERROR, `Error setting suspend data: ${e}`);
            }
            

            if (isExit) {
                await scorm.set("cmi.exit", "normal");      //exit normally
                await scorm.set("adl.nav.request", "exitAll");
            }
            await scorm.setStatus(completionStatus);
        }

        LOGGER.log(LogLevel.DEBUG, `SCORM score=${statsSummary.score}, completionStatus=${completionStatus}, successStatus=${successStatus}`);

        // take the user back to the start
        if (!isExit) {
            setAppStateToStart(GlobalAppState);
        } else {
            // try to close the window
            scorm?.close();
            window.close();
        }
    }

    isCompleted(): boolean {
        const summary = this.analyticsApi.getSessionSummaryJson();
        return summary.score === 100;
    }

}