import { AuthApi } from "../../API/AuthApi";
import { DataApi } from "../../API/DataApi";
import { DEFAULT_USER_FLAGS, DEFAULT_USER_STATS, Experience, Soundscape, Touchpad, User, UserProfileStats } from "../../Data/Models/app";
import { EnvId, getSoundscapeIdsForMood, MoodId, SoundscapeId } from "../../Data/constants";
import { getFocusedExperience, getRelaxExperience, getSoundscapes, getSoundscapesMap, getTouchpads } from "../../Data/Setup/data_setup";
import { getCurrentMillis } from "../../Data/Util/util";

export class LocalDataImpl implements DataApi {

    envId: EnvId;
    authApi: AuthApi;

    constructor(envId: EnvId, authApi: AuthApi) {
        this.envId = envId;
        this.authApi = authApi;
    }
     
    getPastExperiences(): Promise<any> {
        throw new Error("Not supported in Local deployment");
    }

    getUserStats(): Promise<UserProfileStats> {
        throw new Error("Not supported in Local deployment");
    }

    getCurrentUser(): User | null {
        const userId = this.authApi.getCurrentUser();
        if (userId === null) {
            return null;
        } else {
            return {
                userId: userId,
                language: "enUK",
                country: "uk",
                flags: DEFAULT_USER_FLAGS,
                lastInteractionMillis: getCurrentMillis(),
                statsSummary: DEFAULT_USER_STATS,
                createdAt: getCurrentMillis(),
                updatedAt: getCurrentMillis()
            }
        }
    }

    getSoundscapes(moodId: MoodId): Soundscape[] {
        return getSoundscapes(this.envId, getSoundscapeIdsForMood(moodId));
    }

    getSoundscape(soundscapeId: SoundscapeId): Soundscape {
        return getSoundscapesMap(this.envId)[soundscapeId]!!;
    }

    getExperience(moodId: MoodId): Experience {
        if (moodId === MoodId.Focused) {
            return getFocusedExperience(this.envId);
        } else if (moodId === MoodId.Relax) {
            return getRelaxExperience(this.envId);
        } else {
            throw new Error("MoodId not supported=" + moodId);
        }
    }

    getTouchpads(): Touchpad[] {
        return getTouchpads(this.envId);
    }

    async addUserDevice(): Promise<void> { };

}