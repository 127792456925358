import { Module } from "./components/Inject/Module";
import { ProviderScorm } from "./components/Inject/ProviderScorm";
import { ProviderWeb } from "./components/Inject/ProviderWeb";
import { DeploymentType } from "./components/Data/Models/configuration";
import { getConfiguration } from "./components/Util/ConfigHelper";

export const ZensoryModule = initializeModule();

function initializeModule() {
  const config = getConfiguration();

  var providerApi;
  if (config.deploymentType === DeploymentType.Scorm) {
    providerApi = new ProviderScorm(config.envId);
  } else if (config.deploymentType === DeploymentType.Web) {
    providerApi = new ProviderWeb(config.envId);
  } else {
    throw new Error(`Invalid config deploymentType=${JSON.stringify(config)}`);
  }
  
  return new Module(providerApi);
}