import React, { useEffect } from "react";
// import "../styles/orbit.css"

const Orbit = (props) => {
  const url = `orbit.js`;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;

    document.body.appendChild(script);
    const startDiv = document.getElementById('startOrbit');
    startDiv.click();
    const stopDiv = document.getElementById('stopOrbit');
    
    return () => {
      stopDiv.click();
      document.body.removeChild(script);
    }
  }, [url]);

  return (
    <>
      <div id="stopOrbit"></div>
      <div id="startOrbit"></div>
      <canvas id="canvas-orbit" width={"100vw"} height={"100vh"}></canvas>
    </>
  );
}

export default Orbit;