import { ExperienceSense, ExperienceContentItem, Sense, SenseContent, SenseContentSetup, ExperienceLoading, AudioLevels } from "../Models/app";
import { ALL_AUDIO_DURATIONS, AudioDurationSecs, ContentSetupId, EnvId, MoodId, SenseId } from "../constants";
import { getSenseContentItemsForMoodAndSense, getSensesMap } from "./data_setup";
import { isNullOrEmpty, shuffleArray } from "../Util/util";
import { getBreathingBackgroundImg, getBreathingExericeDurations, getBreathingExericeDurationSecsActual, getBreathingExericeUrl } from "./breathing_experiences";

export function getExperienceForSense(envId: EnvId, moodId: MoodId, senseId: SenseId, senses?: {[senseId in SenseId]: Sense} | null): ExperienceSense {
    if (isNullOrEmpty(senses)) {
        senses = getSensesMap(envId);
    }

    if (senseId === SenseId.Sound) {
        return {
            senseId: SenseId.Sound,
            contentSetupId: ContentSetupId.NatureBasic,
            durations: ALL_AUDIO_DURATIONS,
            subTitle: "with Sound",
            customiseDefaults: {
                affirmations: false,
                sound: true,
                intentions: "",
                soundscapeId: null,
                videoAndAudio: null,
                levels: getExperienceAudioLevelDefaults(),
            },
            customiseShowOptions: {
                affirmations: false,
                sound: false,
                intentions: true,
                soundscapeId: true,
                videoAndAudio: false,
                levels: false,
            },
            menuOptions: {
                muteUnmute: false,
                playPause: true,
                levels: true,
                touchpads: false,
                notes: false,
                randomiseTouchpad: false,
            },
            loading: {
                topTextHtml: "Get ready for the <b>experience</b>",
                bottomTextHtml: "Let the immersive sounds elevate your mind and body to just where they need to be",
                bottomIconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2FexperienceLoading%2Fic_exp_loading_headphones.png?alt=media&token=d2cfbafc-00ed-44fc-b5c2-db726c83a62a",
                bottomNoAudioTextHtml: "No audio will play for this experience",
                bottomNoAudioIconUrl: "",
            },
            detailsPage: null,
            videos: null,
            breathings: null,
            breathingTimingsSecs: null,
            breathingBackgroundImg: null,
            contentItems: null,
            canLoop: canSenseBeUnlimited(SenseId.Sound),
        } as ExperienceSense;
    } else if (senseId === SenseId.Touch) { 
        return {
            senseId: SenseId.Touch,
            contentSetupId: ContentSetupId.Touchpads,
            subTitle: "with Sound & Touchpads",
            durations: ALL_AUDIO_DURATIONS,
            customiseDefaults: {
                affirmations: false,
                sound: true,
                intentions: "",
                soundscapeId: null,
                videoAndAudio: null,
                levels: getExperienceAudioLevelDefaults(),
            },
            customiseShowOptions: {
                affirmations: false,
                sound: true,
                intentions: true,
                soundscapeId: true,
                videoAndAudio: false,
                levels: false,
            },
            menuOptions: {
                muteUnmute: false,
                playPause: true,
                levels: true,
                touchpads: envId === EnvId.PROD,
                notes: false,
                randomiseTouchpad: true,
            },
            loading: {
                topTextHtml: "Get ready for the <b>experience</b>",
                bottomTextHtml: "It's time to release your inner child and have a play on our touchpads",
                bottomIconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2FexperienceLoading%2Fic_exp_loading_headphones.png?alt=media&token=d2cfbafc-00ed-44fc-b5c2-db726c83a62a",
                bottomNoAudioTextHtml: "No audio will play for this experience",
                bottomNoAudioIconUrl: "",
            },
            detailsPage: null,
            videos: null,
            breathings: null,
            breathingTimingsSecs: null,
            breathingBackgroundImg: null,
            contentItems: null,
            canLoop: canSenseBeUnlimited(SenseId.Touch),
        } as ExperienceSense;
    } else if (senseId === SenseId.Breath) {
        const breathMoodContent = getSenseContentItemsForMoodAndSense(senses!!, moodId, SenseId.Breath, ContentSetupId.BreathingExercises)[0];
        return {
            senseId: SenseId.Breath,
            contentSetupId: ContentSetupId.BreathingExercises,
            subTitle: "with Sound",
            durations: getBreathingExericeDurations(moodId),
            customiseDefaults: {
                affirmations: false,
                sound: true,
                intentions: "",
                soundscapeId: breathMoodContent.audioUrl,
                videoAndAudio: null,
                levels: getExperienceAudioLevelDefaults(),
            },
            customiseShowOptions: {
                affirmations: false,
                sound: true,
                intentions: false,
                soundscapeId: false,
                videoAndAudio: false,
                levels: false,
            },
            menuOptions: {
                muteUnmute: false,
                playPause: false,
                levels: false,
                touchpads: false,
                notes: false,
                randomiseTouchpad: false,
            },
            detailsPage: {
                pageId: senses!!.breath.senseId,
                title: breathMoodContent.title!!,
                description: breathMoodContent.description!!,
                imageUrl: breathMoodContent.backgroundImageUrl!!,
                infoPageId: breathMoodContent.infoPageId,
                footerText: "Breathing experiences are only available in 3 and 10 minutes. To loop it, once finished, select it again from the Home page."
            },
            loading: {
                topTextHtml: "Take a deep breath and get ready to be taken on a journey.",
                bottomTextHtml: "Take a deep breath and prepare to be taken on a journey",
                bottomIconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2FexperienceLoading%2Fic_exp_loading_headphones.png?alt=media&token=d2cfbafc-00ed-44fc-b5c2-db726c83a62a",
                bottomNoAudioTextHtml: "No audio will play for this experience",
                bottomNoAudioIconUrl: "",
            },
            videos: null,
            breathings: {
                180: getBreathingExericeUrl(moodId, AudioDurationSecs.THREE_3_MINS),
                600: getBreathingExericeUrl(moodId, AudioDurationSecs.TEN_10_MINS),
            },
            breathingTimingsSecs: {
                180: getBreathingExericeDurationSecsActual(moodId, AudioDurationSecs.THREE_3_MINS),
                600: getBreathingExericeDurationSecsActual(moodId, AudioDurationSecs.TEN_10_MINS),
            },
            breathingBackgroundImg: getBreathingBackgroundImg(moodId),
            contentItems: null,
            canLoop: canSenseBeUnlimited(SenseId.Breath),
        } as ExperienceSense;
    } else if (senseId === SenseId.Movement) {
        const loading = {
            topTextHtml: "Take a deep breath and get ready to be taken on a journey.",
            bottomTextHtml: "Put on your headphones now for a fully immersive experience",
            bottomIconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2FexperienceLoading%2Fic_exp_loading_headphones.png?alt=media&token=d2cfbafc-00ed-44fc-b5c2-db726c83a62a",
            bottomNoAudioTextHtml: "No audio will play for this experience",
            bottomNoAudioIconUrl: "",
        };
    
        loading.bottomTextHtml = "It's time to move into an elevated state of mind";
        return getSenseWithContent(
            envId, 
            moodId, 
            senses!![SenseId.Movement], 
            ContentSetupId.Movements, 
            "with Sound & Nature", 
            canSenseBeUnlimited(SenseId.Movement), 
            loading,
            ALL_AUDIO_DURATIONS
        );

    } else if (senseId === SenseId.Pressure) {
        const loading = {
            topTextHtml: "Take a deep breath and get ready to be taken on a journey.",
            bottomTextHtml: "Put on your headphones now for a fully immersive experience",
            bottomIconUrl: "https://firebasestorage.googleapis.com/v0/b/thezensory.appspot.com/o/app%2FexperienceLoading%2Fic_exp_loading_headphones.png?alt=media&token=d2cfbafc-00ed-44fc-b5c2-db726c83a62a",
            bottomNoAudioTextHtml: "No audio will play for this experience",
            bottomNoAudioIconUrl: "",
        };
        loading.bottomTextHtml = "Let's tap into your ideal mood state using the power of pressure points";
        return getSenseWithContent(
            envId,
            moodId,
            senses!![SenseId.Pressure],
            ContentSetupId.PressurePoints,
            "with Sound & Nature",
            canSenseBeUnlimited(SenseId.Pressure),
            loading,
            [ AudioDurationSecs.THREE_3_MINS, AudioDurationSecs.TEN_10_MINS, AudioDurationSecs.TWENTY_20_MINS ]
            );    
    }

    throw new Error(`Sense not supported, senseId=${senseId}`);
}

export function getExperienceSense(envId: EnvId, moodId: MoodId): ExperienceSense[] {
    var senses = getSensesMap(envId);

    const senseNature: ExperienceSense = getExperienceForSense(envId, moodId, SenseId.Sound, senses);
    const senseTouchpads: ExperienceSense = getExperienceForSense(envId, moodId, SenseId.Touch, senses);
    const senseBreath: ExperienceSense = getExperienceForSense(envId, moodId, SenseId.Breath, senses);
    const senseMovements: ExperienceSense = getExperienceForSense(envId, moodId, SenseId.Movement, senses);
    const sensePressurePoints: ExperienceSense = getExperienceForSense(envId, moodId, SenseId.Pressure, senses);

    return [ senseNature, senseTouchpads, senseBreath, senseMovements, sensePressurePoints ];
}

export function getExperienceSensesMap(envId: EnvId, moodId: MoodId): {[key in SenseId]?: ExperienceSense} {
    const sensesArr = getExperienceSense(envId, moodId);
    const map: {[key in SenseId]?: ExperienceSense} = {};
    for (const sense of sensesArr) {
        map[sense.senseId] = sense;
    }
    return map;
}

export function getSenseWithContent(envId: EnvId, 
    moodId: MoodId, 
    sense: Sense, 
    contentSetupId: ContentSetupId, 
    subTitle: string, 
    canLoop: boolean,
    loading: ExperienceLoading,
    audioDurationSecs: AudioDurationSecs[]): ExperienceSense {
    const senseContentSetup: SenseContentSetup = sense.contentAndSetup[contentSetupId]!!;
    const senseContent = senseContentSetup.content;
    const senseContentForMood: SenseContent[] = [];
    // filter for only the sense content that should be shown for this mood
    for (var content of senseContent) {
        if (content.moods[moodId]) {
            senseContentForMood.push(content);
        }
    }

    // put the content in a random order
    shuffleArray(senseContentForMood);

    // add it to a ExperienceSenseItem array
    const senseItems: ExperienceContentItem[] = [];
    for (var i = 0, len = senseContentForMood.length; i < len; i++) {
        const senseForMood = senseContentForMood[i];

        // add the rest
        if (senseItems.length > 0) {
            senseItems.push(getExperienceSenseItemRest(senseForMood.title!!, 15));
        }

        senseItems.push({
            contentId: senseForMood.contentId,
            title: senseForMood.title!!,
            description: senseForMood.description,
            imageUrl: senseForMood.contentImageUrl,
            durationSecs: 30
        });
    }
  
    return {
        senseId: sense.senseId,
        contentSetupId: contentSetupId,
        subTitle: subTitle,
        durations: audioDurationSecs,
        customiseDefaults: {
            affirmations: false,
            sound: true,
            intentions: "",
            soundscapeId: null,
            videoAndAudio: null,
            levels: getExperienceAudioLevelDefaults(),
        },
        customiseShowOptions: {
            affirmations: false,
            sound: true,
            intentions: envId === EnvId.PROD,
            soundscapeId: true,
            videoAndAudio: false,
            levels: false,
        },
        menuOptions: {
            muteUnmute: false,
            playPause: true,
            levels: true,
            touchpads: sense.senseId === SenseId.Touch && envId === EnvId.PROD,
            notes: envId === EnvId.PROD,
            randomiseTouchpad: sense.senseId === SenseId.Touch,
        },
        loading: loading,
        detailsPage: null,
        videos: null,
        breathings: null,
        breathingTimingsSecs: null,
        breathingBackgroundImg: null,
        contentItems: senseItems,
        canLoop: canLoop,
    };
}

function getExperienceSenseItemRest(next: string, durationSecs = 10): ExperienceContentItem {
    return {
        contentId: "rest",
        title: "Rest",
        description: `Up next: ${next}`,
        imageUrl: null,
        durationSecs: durationSecs
    }
}

export function canSenseBeUnlimited(senseId: SenseId): boolean {
    return SenseId.Sound === senseId 
        || SenseId.Touch === senseId;
}

export function getExperienceAudioLevelDefaults(): AudioLevels {
    return {
        music: 100,
        breathTrigger: 40,
        soundscape: 18,
        binnaural: 60,
        binnauralWhiteBrownNoise: 20,
        soundscapeWhiteBrownNoise: 60,
        audioBreath: 100,
        visualBreathTrigger: 70
    }
}
