import { AuthApi } from "../../API/AuthApi";
import { DataApi } from "../../API/DataApi";
import {
  Experience,
  Soundscape,
  Touchpad,
  User,
  UserProfileStats,
} from "../../Data/Models/app";
import {
  EnvId,
  getSoundscapeIdsForMood,
  MoodId,
  SoundscapeId,
} from "../../Data/constants";
import {
  getFocusedExperience,
  getRelaxExperience,
  getSoundscapes,
  getSoundscapesMap,
  getTouchpads,
} from "../../Data/Setup/data_setup";
import { getDeviceDetailsFirebase } from "../../Util/DeviceUtil";
import { getFirebaseHelper } from "../../Util/FirebaseHelper";
import { setDoc, doc } from "firebase/firestore";
import { LOGGER, LogLevel } from "../../Util/Logger";
import {
  GetUserExperienceHistoryResponse,
  GetUserStatsResponse,
  ZensoryCloudFunctionNames,
} from "../../Data/Models/zensory_api";

interface GetUserStatsRequest {
  userId: string;
}

interface GetUserHistoryRequest {
  userId: string;
  from: number;
  to: number;
  limit: number;
}

export class FirebaseDataImpl implements DataApi {
  envId: EnvId;
  authApi: AuthApi;

  constructor(envId: EnvId, authApi: AuthApi) {
    this.envId = envId;
    this.authApi = authApi;
  }

  async getPastExperiences(
    from: number,
    to: number,
    limit: number
  ): Promise<Experience[]> {
    const userId = this.authApi.getCurrentUser();
    if (userId === null) {
      throw new Error(
        "Method should only be called when user is authenticated"
      );
    }

    const payload: GetUserHistoryRequest = {
      userId,
      from,
      to,
      limit,
    };

    try {
      const response = (await getFirebaseHelper().callCloudFunction(
        ZensoryCloudFunctionNames.GetUserExperienceHistory,
        payload
      )) as GetUserExperienceHistoryResponse;

      if (!response) throw new Error("Error");

      return response.result.history;
    } catch (error) {
      LOGGER.log(LogLevel.ERROR, "GetUserExperienceHistory error " + error);
      throw new Error("GetUserExperienceHistory error " + error);
    }
  }

  async getUserStats(): Promise<UserProfileStats> {
    const userId = this.authApi.getCurrentUser();
    if (userId === null) {
      throw new Error(
        "Method should only be called when user is authenticated"
      );
    }

    const payload: GetUserStatsRequest = {
      userId: userId,
    };
    try {
      const response = (await getFirebaseHelper().callCloudFunction(
        ZensoryCloudFunctionNames.GetUserProfileStats,
        payload
      )) as GetUserStatsResponse;

      LOGGER.log(
        LogLevel.DEBUG,
        `GetUserStats response=${JSON.stringify(response)}`
      );

      if (!response.result.success) throw new Error(response.result.message);

      return response.result.stats;
    } catch (error) {
      LOGGER.log(LogLevel.ERROR, "GetUserStats error " + error);
      throw new Error("GetUserStats error " + error);
    }
  }

  getCurrentUser(): User | null {
    const userId = this.authApi.getCurrentUser();
    if (userId === null) {
      return null;
    } else {
      // TODO: change to return User from the database
      return null;
    }
  }

  getSoundscapes(moodId: MoodId): Soundscape[] {
    return getSoundscapes(this.envId, getSoundscapeIdsForMood(moodId));
  }

  getSoundscape(soundscapeId: SoundscapeId): Soundscape {
    return getSoundscapesMap(this.envId)[soundscapeId]!!;
  }

  getExperience(moodId: MoodId): Experience {
    if (moodId === MoodId.Focused) {
      return getFocusedExperience(this.envId);
    } else if (moodId === MoodId.Relax) {
      return getRelaxExperience(this.envId);
    } else {
      throw new Error("MoodId not supported=" + moodId);
    }
  }

  getTouchpads(): Touchpad[] {
    return getTouchpads(this.envId);
  }

  async addUserDevice(): Promise<void> {
    const device = getDeviceDetailsFirebase();
    const firebaseHelper = getFirebaseHelper();
    const auth = firebaseHelper.getAuth();
    const db = firebaseHelper.getFirestore();

    LOGGER.log(LogLevel.DEBUG, `auth.currentUser ${auth.currentUser}`);

    if (auth.currentUser?.uid) {
      const updates: any = {};
      updates[`devices.${device.deviceId}`] = device;
      try {
        const newUser = doc(db, "users", auth.currentUser?.uid);
        await setDoc(newUser, updates, { merge: true })
          .then(() => {
            LOGGER.log(LogLevel.DEBUG, "Added/updated user device");
          })
          .catch((e) => {
            LOGGER.log(LogLevel.ERROR, `Error adding user device: ${e}`);
          });
      } catch (e) {
        LOGGER.log(LogLevel.ERROR, `Error adding user device: ${e}`);
      }
    }
  }
}

export enum CollectionNames {
  Users = "users",
  Experiences = "experience",
  Soundscapes = "soundscape",
  InspirationalQuotes = "inspirationalQuotes",
  Colours = "colours",
  Sense = "sense",
  Mood = "mood",
  InfoPage = "infoPage",
  QuickStartExperience = "quickStartExperience",
  TouchpadsV1 = "touchpads",
  TextOptions = "textOptions",
  ScheduledNotifications = "scheduledNotifs",
  NotifPreferences = "notifPrefs",
  Global = "global",
  Companies = "company",
  DeletedUsers = "deletedUsers",
  Jobs = "jobs",
  UserHomeContentUnlocked = "userHomeContentUnlocked",
}

export enum SubCollectionNames {
  Devices = "devices",
  ExperienceRecords = "experienceRecords",
  UserActivity = "activity",
  StatsByDay = "statsByDay",
  StatsByWeek = "statsByWeek",
  StatsByMonth = "statsByMonth",
  ScheduledNotifications_Notifs = "notifs",
  FocusSessions = "focusSessions",
  JobEntries = "entries",
  UserHomeMoodContent = "moodContent",
}

export enum DatabaseField {
  Devices = "devices",
  Devices_x_deviceId = "deviceId",
  Devices_x_os = "os",
  Devices_x_manufacturer = "manufacturer",
  Devices_x_model = "model",
  Devices_x_token = "token",
  Preferences = "preferences",
  Preferences_releases = "releases",
  Preferences_additions = "additions",
  NotifPreference_userId = "userId",
  NotifPreference_type = "type",
  NotifPreference_daysEnabled = "daysEnabled",
  NotifPreference_timeOfDayStart = "timeOfDayStart",
  NotifPreference_timeOfDayEnd = "timeOfDayEnd",
  NotifPreference_nextSendFromDateTime = "nextSendFromDateTime",
  ScheduledNotif_isSent = "isSent",
  ScheduledNotif_sendTime = "sendTime",
  User_userId = "userId",
  User_email = "email",
  User_flags = "flags",
  User_flag_isEmailRegistered = "isEmailRegistered",
  User_flag_welcomeEmail = "welcomeEmail",
  User_flag_inactive5Days = "inactive5Days",
  User_flag_surveyEmail01 = "surveyEmail01",
  User_flag_powerUser2021_01_07 = "powerUser2021_01_07",
  User_flag_updateEmailValues2022_02_16 = "updateEmailValues2022_02_16",
  User_lastInteractionMillis = "lastInteractionMillis",
  User_isPowerUser = "isPowerUser",
  User_companyId = "companyId",
  UserActivity_timestampMillis = "timestampMillis",
  ExperienceRecord_endedAt = "endedAt",
  ExperienceRecord_startedAt = "startedAt",
  UserFocusSession_startedAt = "startedAt",
  Company_companyId = "companyId",
  Company_name = "name",
  Company_logoUrl = "logoUrl",
  Company_domains = "domains",
  Company_website = "website",
  Company_numUsers = "numUsers",
}

export enum GlobalAppDocIds {
  PomodoroFocusSession = "pomodoroFocusSession",
}
