import React, { useEffect } from "react";
import "../styles/particle-shapes.css"

const ParticleShapes = () => {

    const url = "particle-shapes.js"

    useEffect(() => {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
  
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      }
    }, [url]);
    return (
        <canvas id="canvas"></canvas>
    );
}

export default ParticleShapes;