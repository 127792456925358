import { AnalyticEventName, AnalyticsApi, AnalyticsScreenName, AnalyticsSummary, BaseAnalyticsEvent, EndExperienceAnalyticsEvent, EndExperienceWithRatingAnalyticsEvent, StartExperienceAnalyticsEvent } from "../../API/AnalyticsApi";
import { UserActivity } from "../../Data/Models/app";
import { Configuration } from "../../Data/Models/configuration";
import { getCurrentMillis } from "../../Data/Util/util";
import { getConfiguration } from "../../Util/ConfigHelper";
import { LOGGER, LogLevel } from "../../Util/Logger";

// 3 minutes is the shortest duration the platform supports
const COMPLETION_TIME_SECS = 3 * 60;

export class AllAnalyticsImpl implements AnalyticsApi {

    analyticsApis: AnalyticsApi[];
    lastScreenName: AnalyticsScreenName | null = null;

    private percentageTimeSpent: number = 0;
    private analyticsHistory: BaseAnalyticsEvent[] = [];

    constructor(analyticsApis: AnalyticsApi[]) {
        this.analyticsApis = analyticsApis;
    }

    async identify(userId: string): Promise<void> {
        const promises: Promise<any>[] = [];
        for (const api of this.analyticsApis) {
            promises.push(api.identify(userId));
        }
        await Promise.all(promises);

        const config: Configuration = getConfiguration();
        if (config.analyticsProps) {
            const promises: Promise<any>[] = [];
            promises.push(this.updateUserProps(config.analyticsProps));
            await Promise.all(promises);
        }
    }

    async updateUserProps(
        props: { [key: string]: any; }
    ): Promise<void> {
        for (const api of this.analyticsApis) {
            api.updateUserProps(props);
        }
    }

    async trackEvent(eventName: AnalyticEventName, props: { [key: string]: any; }): Promise<void> {
        this.analyticsHistory.push({
            name: eventName,
            timestampMillis: getCurrentMillis(),
            props: props
        });

        for (const api of this.analyticsApis) {
            api.trackEvent(eventName, props);
        }
    }

    async trackScreen(screenName: AnalyticsScreenName): Promise<void> {
        // avoid double tracking
        if (screenName === this.lastScreenName) {
            return;
        }
        this.lastScreenName = screenName;

        this.analyticsHistory.push({
            name: screenName,
            timestampMillis: getCurrentMillis(),
            props: {}
        });

        for (const api of this.analyticsApis) {
            api.trackScreen(screenName);
        }
    }

    async trackStartExperience(event: StartExperienceAnalyticsEvent): Promise<void> {
        this.analyticsHistory.push(event);

        for (const api of this.analyticsApis) {
            api.trackStartExperience(event);
        }
    }

    async trackEndExperience(event: EndExperienceAnalyticsEvent): Promise<void> {
        this.analyticsHistory.push(event);

        /*  Calculate and store the percentage time the user spent of the completion time 
            - Max=100 because experiences can be longer than the completion time)
        
            Note: return the MAX if they have already done an Experience to use the bigger of the scores
        */
        const percentageRaw = 100.0 * (event.timeSpentSecs / COMPLETION_TIME_SECS);
        var percentage = Math.min(Math.round(percentageRaw), 100);
        const percentageTimeSpentLocal = Math.max(percentage, this.percentageTimeSpent);
        if (percentageTimeSpentLocal > this.percentageTimeSpent) {
            this.percentageTimeSpent = percentageTimeSpentLocal;
        }
        LOGGER.log(LogLevel.DEBUG, 
            `trackEndExperience() 
            event.timeSpentSecs=${event.timeSpentSecs}, 
            percentageRaw=${percentageRaw}, 
            percentage=${percentage}, 
            percentageTimeSpent=${percentageTimeSpentLocal}`);

        for (const api of this.analyticsApis) {
            api.trackEndExperience(event);
        }
    }

    async trackEndExperienceWithRating(event: EndExperienceWithRatingAnalyticsEvent): Promise<void> {
        this.analyticsHistory.push(event);

        for (const api of this.analyticsApis) {
            api.trackEndExperienceWithRating(event);
        }
    }

    async trackUserActivity(userId: string, activity: UserActivity): Promise<any> {
        for (const api of this.analyticsApis) {
            api.trackUserActivity(userId, activity);
        }
    }

    async onUserAuthed(userId: string, email: string): Promise<void> {
        const promises: Promise<any>[] = [];
        for (const api of this.analyticsApis) {
            promises.push(api.onUserAuthed(userId, email));
        }
        await Promise.all(promises);
    }

    getSessionSummaryJson(): AnalyticsSummary {
        const config: Configuration = getConfiguration();
        return {
            customerId: config.customerId,
            version: config.version,
            timestampMillis: getCurrentMillis(),
            score: this.getScore(),
            events: this.analyticsHistory
        };
    }

    getScore(): number {
        LOGGER.log(LogLevel.DEBUG, `getScore percentageTimeSpent=${this.percentageTimeSpent}`);
        return this.percentageTimeSpent;
    }

}