import { Interweave } from "interweave";
import { TextId, getText } from "./Util/TextHelper";

export const ManageSubscriptionBox = () => {
  return (
    <div className="profile-box-user">
    <div className="manage-subs">
    <h3 className="streak-current">
            {getText(TextId.zensoryPremium)}
          </h3>
          <h3 className="manage-link">
          <Interweave 
            content={getText(TextId.manageSubscription)}/>
          </h3>
          </div>
    </div>
  );
};
